import { IconLogout, IconRefresh, PLCLProvider } from "@powerledger/ui-component-lib";
import { Box, Button, Flex, Heading, Link, Text } from "theme-ui";

import container from "@/app/container";
import { tracexTheme } from "@/app/container/theme-override";
import { getSupportEmail } from "@/app/lib/get-support-email";
import i18n from "@/app/lib/i18n";

import { GlobalErrorBoundaryViewProps } from "./global-error-boundary.types";

const reloadPage = () => window.location.reload();

const supportEmail = getSupportEmail();

export default function GlobalErrorBoundaryView({
  children,
  hasError,
  error = "",
  isLoggedIn,
}: GlobalErrorBoundaryViewProps) {
  if (!hasError) {
    return <>{children}</>;
  }
  return (
    <PLCLProvider themeOverwrites={tracexTheme}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          fontFamily: "Arial, Helvetica, sans-serif",
          position: "fixed",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          backgroundImage: "url(/assets/loginBackground.svg)",
          backgroundPositionY: "bottom",
          backgroundPositionX: "right",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundColor: "background",
          textAlign: "center",
        }}
      >
        <Flex
          sx={{
            gap: 3,
            alignItems: "center",
            flexDirection: "column",
            maxWidth: ["100%", "75%", "60%"],
            color: "text",
            overflow: "auto",
          }}
        >
          <Heading sx={{ fontSize: 4, lineHeight: "1.35", mr: 3, fontFamily: "inherit" }}>
            <span>{i18n.t("Unfortunately an error occurred on our end.")}</span>
            <br />
            {i18n.t("The system may be down for maintenance.")}
          </Heading>

          {error && (
            <Heading
              sx={{
                fontSize: 2,
                lineHeight: "1.35",
                fontFamily: "inherit",
                wordWrap: "break-word",
                maxWidth: "100%",
              }}
            >
              {i18n.t(`Error Details: {{error}}`, { error })}
            </Heading>
          )}

          <Text
            sx={{
              pt: 1,
              fontSize: 2,
              mb: 4,
            }}
          >
            {i18n.t("Please reload the page and try again.")}
          </Text>
          <Flex sx={{ gap: 3 }}>
            {isLoggedIn && (
              <Button
                type="button"
                variant="pillInverted.compact"
                sx={{
                  fontSize: 1,
                }}
                onClick={async () => {
                  container.oktaAuth.clearStorage();
                  await container.apolloClient.clearStore();
                  //To Clear Custom Session Storage eg: Sidebar State
                  sessionStorage.clear();
                  reloadPage();
                }}
              >
                <Flex
                  sx={{
                    alignItems: "center",
                    display: "inline-flex",
                    div: {
                      display: "flex",
                      alignItems: "center",
                    },
                  }}
                >
                  <IconLogout
                    size={15}
                    sx={{
                      mr: 2,
                    }}
                    noSvgPositioning
                    color="primary"
                  />
                  <Text>{i18n.t("Log out")}</Text>
                </Flex>
              </Button>
            )}
            <Button type="button" onClick={reloadPage}>
              <Flex
                sx={{
                  alignItems: "center",
                  display: "inline-flex",
                  div: {
                    display: "flex",
                    alignItems: "center",
                  },
                }}
              >
                <IconRefresh
                  size={15}
                  sx={{
                    mr: 2,
                  }}
                  noSvgPositioning
                  color="background"
                />
                <Text>{i18n.t("Reload")}</Text>
              </Flex>
            </Button>
          </Flex>
        </Flex>
        <Flex
          sx={{
            color: "primary",
            fontSize: 2,
            filter: "brightness(0.9)",
            mt: 3,
            flexDirection: ["column", "row"],
          }}
        >
          <Text pr={1}>{i18n.t("You can contact us at")}</Text>
          <Link
            href={`mailto:${supportEmail}`}
            sx={{
              color: "primary",
              filter: "brightness(1.2)",
              textDecoration: "none",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {supportEmail}
          </Link>
        </Flex>
        <Box sx={{ color: "textDarker", fontSize: 0, mt: 2 }}>
          {`©${new Date().getFullYear()} Powerledger. All rights reserved`}
        </Box>
      </Box>
    </PLCLProvider>
  );
}
