import {
  Box,
  Flex,
  IconBell,
  IconButton,
  IconFastForward,
  IconLinkIcon,
  IconWarning,
  Link,
  Menu,
  Sider,
  Text,
} from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getSupportEmail } from "@/app/lib/get-support-email";
import { TraceCCLogo } from "@/assets/logos/components/tracecc-logo";
import { TraceCCLogoSmall } from "@/assets/logos/components/tracecc-logo-small";

import { RouteButton } from "../route-button";
import { LayoutSiderProps, NotificationBadgeProps, RegistryBadgeProps } from "./layout-sider.types";

const RegistryBadge: FC<RegistryBadgeProps> = ({ showBadge, sx, size = 6 }) =>
  showBadge ? <IconWarning color="yellow" size={size} sx={{ right: -3, ...sx }} /> : null;

const NotificationBadge: FC<NotificationBadgeProps> = ({ unreadMessageCount }) =>
  unreadMessageCount ? (
    <Box
      sx={{
        bg: "secondary",
        borderRadius: 12,
        color: "white",
        px: 2,
        ml: 2,
      }}
    >
      {unreadMessageCount > 9 ? "9+" : unreadMessageCount}
    </Box>
  ) : null;

export const LayoutSider: FC<LayoutSiderProps> = ({
  sider,
  sidebarRoutes,
  sidebarOpen,
  userInitials,
  unreadMessageCount,
  hasLinkedRegistries,
  updateSidebarState,
  linkedRegistriesLoading,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const supportEmail = getSupportEmail();

  return (
    sider || (
      <Sider>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box>
            <Flex
              sx={{
                flexDirection: sidebarOpen ? "row" : "column",
                height: sidebarOpen ? 80 : 100,
                alignItems: "center",
                textAlign: "center",
                px: sidebarOpen ? 4 : 0,
                pt: 2,
                ...(sidebarOpen ? {} : { justifyContent: "space-between" }),
              }}
            >
              <IconButton
                sx={{
                  width: "100%",
                  margin: "auto",
                  ...(sidebarOpen
                    ? {
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }
                    : { svg: { ml: "4px", scale: "1.3" } }),
                }}
                onClick={() => navigate("/market")}
              >
                {sidebarOpen && (
                  <Box sx={{ width: 5 }}>
                    <TraceCCLogo />
                  </Box>
                )}

                {!sidebarOpen && (
                  <Flex
                    sx={{
                      flexDirection: "column",
                    }}
                  >
                    <TraceCCLogoSmall width={32} />
                    <Box
                      sx={{
                        fontSize: "0.5em",
                        color: "text",
                        fontWeight: 500,
                      }}
                    >
                      {t("TraceCC")}
                    </Box>
                  </Flex>
                )}
              </IconButton>
              <IconButton
                sx={{
                  flexShrink: 0,
                  ...(sidebarOpen ? {} : { width: "100%" }),
                }}
                onClick={() => updateSidebarState()}
              >
                <IconFastForward
                  size={5}
                  color="text"
                  sx={{
                    transition: "base",
                    ...(sidebarOpen ? { transform: "rotate(180deg)" } : {}),
                  }}
                />
              </IconButton>
            </Flex>
            <Box sx={{ mt: 4 }}>
              <Menu>{sidebarRoutes}</Menu>
            </Box>
          </Box>
          {!sidebarOpen && (
            <Box
              sx={{
                fontSize: 0,
                color: "textDarker",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <RouteButton
                to="/settings"
                sx={{
                  width: "30px",
                  height: "30px",
                  margin: "auto",
                }}
              >
                <Text sx={{ fontSize: "10px" }}>{userInitials}</Text>
              </RouteButton>
              <RouteButton
                to="/notifications"
                sx={{
                  width: "30px",
                  height: "30px",
                  margin: "auto",
                  mt: "2",
                }}
                badge={<NotificationBadge unreadMessageCount={unreadMessageCount} />}
              >
                <IconBell size={5} />
              </RouteButton>
              <RouteButton
                sx={{
                  width: "30px",
                  height: "30px",
                  margin: "auto",
                  mt: "2",
                }}
                to="/settings?tab=registry"
                badge={
                  <RegistryBadge
                    showBadge={!hasLinkedRegistries}
                    size={5}
                    sx={{
                      right: -1,
                      bottom: 1,
                    }}
                  />
                }
              >
                <IconLinkIcon size={5} />
              </RouteButton>
              <Box mt="2">{t(`©{{date}}`, { date: new Date().getFullYear() })}</Box>
            </Box>
          )}
          {sidebarOpen && (
            <Box sx={{ p: 2, mt: 3 }}>
              <Flex sx={{ alignItems: "center", px: 4 }}>
                <RouteButton to="/settings">{userInitials}</RouteButton>
                <RouteButton
                  to="/notifications"
                  sx={{ ml: 3 }}
                  badge={<NotificationBadge unreadMessageCount={unreadMessageCount} />}
                >
                  <IconBell size={5} />
                </RouteButton>
                <RouteButton
                  to="/settings?tab=registry"
                  sx={{ ml: 3 }}
                  badge={<RegistryBadge showBadge={!linkedRegistriesLoading && !hasLinkedRegistries} />}
                >
                  <IconLinkIcon size={5} />
                </RouteButton>
              </Flex>
              <Box sx={{ fontSize: 0, color: "text", mt: 4 }}>
                <Link
                  href={`mailto:${supportEmail}`}
                  target="_blank"
                  sx={{
                    color: "text",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  {t("Contact Us")}
                </Link>
                <Box>{t(`©{{date}} Powerledger. All rights reserved`, { date: new Date().getFullYear() })}</Box>
              </Box>
            </Box>
          )}
        </Flex>
      </Sider>
    )
  );
};
