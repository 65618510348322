import { addDays } from "date-fns";

export const getMaxVintageFromDate = (vintage: number): Date => {
  return new Date(vintage, 11, 31);
};

export const getMinVintageToDate = (vintage: number, vintageFrom: string): Date => {
  const firstOfVintage = new Date(vintage, 0, 1);
  const vintageFromYear = new Date(vintageFrom).getFullYear();
  if (vintageFromYear < vintage) {
    return firstOfVintage;
  }
  return addDays(new Date(vintageFrom), 1);
};
