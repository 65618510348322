import { Box, Button } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { SellFormValues } from "./sell-order-modal/sell-order-modal.types";
import { SellOrderSummaryTable } from "./sell-order-summary-table";

export const Confirmation = ({
  text = "",
  failedOrders,
  action,
  currencySymbol,
}: {
  currencySymbol: string;
  failedOrders?: SellFormValues["values"];
  text?: string;
  action: { content: React.ReactNode; onClick: () => void };
}) => {
  const { t } = useTranslation();
  const renderFailedOrders = useMemo(
    () =>
      !!failedOrders?.length && (
        <>
          <Box sx={{ fontSize: 3, fontWeight: "bold" }}>{t("Failed Orders")}</Box>
          <SellOrderSummaryTable data={failedOrders} currencySymbol={currencySymbol} />
        </>
      ),
    [failedOrders, currencySymbol, t],
  );
  return (
    <>
      <Box sx={{ fontSize: 4, fontWeight: "bold" }}>{text}</Box>
      {renderFailedOrders}
      <Button sx={{ mt: 4 }} variant="pill" onClick={action.onClick}>
        {action.content}
      </Button>
    </>
  );
};

export default Confirmation;
