import { Flex, IconLoadingOutlined, IconWarning } from "@powerledger/ui-component-lib";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";

import { fallbackLng } from "@/app/lib/i18n";

import { hostDocs } from "./host-docs";

export const WrappedDynamicFile = ({
  lng,
  tenantName = "US",
  translationKey,
}: {
  lng: string;
  tenantName?: string;
  translationKey: string;
}) => {
  const File = useDocSelector(lng, translationKey, tenantName);
  return <Suspense fallback={<IconLoadingOutlined />}>{File ? <File /> : <MessageMissingFile />}</Suspense>;
};

export default WrappedDynamicFile;

const useDocSelector = (lng: string, key: string, tenantName: string) => {
  if (hostDocs[lng] && hostDocs[lng][`${tenantName}.${key}`]) {
    return hostDocs[lng][`${tenantName}.${key}`];
  }

  if (hostDocs[fallbackLng] && hostDocs[fallbackLng][`${tenantName}.${key}`]) {
    return hostDocs[fallbackLng][`${tenantName}.${key}`];
  }

  if (hostDocs.default[`${tenantName}.${key}`]) {
    return hostDocs.default[`${tenantName}.${key}`];
  }

  if (hostDocs[lng] && hostDocs[lng][`default.${key}`]) {
    return hostDocs[lng][`default.${key}`];
  }

  if (hostDocs[fallbackLng] && hostDocs[fallbackLng][`default.${key}`]) {
    return hostDocs[fallbackLng][`default.${key}`];
  }

  if (hostDocs.default[`default.${key}`]) {
    return hostDocs.default[`default.${key}`];
  }

  return null;
};

const MessageMissingFile = () => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <IconWarning size="48px" />
      <Flex>{t("File could not be loaded")}</Flex>
    </Flex>
  );
};
