import { Box, Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications-handler";

import { ConfirmRemoveUserModalProps } from "./confirm-remove-user-modal.types";

export const ConfirmRemoveUserModal: React.FC<ConfirmRemoveUserModalProps> = ({ visible, closeModal, onConfirm }) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const confirmSubmit = async () => {
    try {
      setSubmitting(true);
      await onConfirm();
      closeModal();
    } catch (e) {
      e instanceof Error &&
        notifications.error({
          description: e?.message,
        });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      visible={visible}
      hideCloseButton
      sx={{
        p: 3,
        fontSize: 2,
      }}
    >
      <Box>{t("Are you sure you want to remove this user?")}</Box>

      <Flex
        sx={{
          mt: 3,
          gap: 2,
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            bg: "transparent",
            borderRadius: 0,
            py: 2,
            "&:enabled:hover, &:enabled:focus": {
              bg: "transparent",
              borderBottom: "1px solid white",
            },
            "&:disabled": {
              bg: "transparent",
            },
            color: "text",
          }}
          onClick={closeModal}
          disabled={submitting}
        >
          {t("Cancel")}
        </Button>
        <Button
          variant="pill.compact"
          onClick={confirmSubmit}
          disabled={submitting}
          sx={{
            mr: 2,
          }}
        >
          {t("Confirm")}
        </Button>
        {submitting && <IconLoading size="small" />}
      </Flex>
    </Modal>
  );
};
