import { useOktaAuth } from "@okta/okta-react";

import { LoadingOverlayIndicator } from "@/app/components";
import { FeatureToggleKey, useFeatureToggle } from "@/app/hooks";
import { useOktaRoles } from "@/app/hooks/use-okta-roles";
import { UserRoles } from "@/app/lib/role-based-access-control";

import { UserResourcesView } from "./user-resources-view";

export const UserResources = () => {
  const { authState } = useOktaAuth();
  const { userRoles } = useOktaRoles();

  const { value: multipleUserV2Enabled, loading } = useFeatureToggle({
    featureKey: FeatureToggleKey.MultipleUsersV2,
  });

  const isAdmin = multipleUserV2Enabled ? userRoles.includes(UserRoles.RoleAdmin) : true;

  if (loading) return <LoadingOverlayIndicator />;
  return <UserResourcesView isAuthenticated={authState?.isAuthenticated} isAdmin={isAdmin} />;
};

export default UserResources;
