import currency from "currency.js";
import { FormikProps } from "formik";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useCurrencySymbol } from "@/app/hooks/use-currency-symbol";
import {
  CcAssetAttributes,
  CcHoldingsDocument,
  OrderHistoryV2Document,
  OrderPosition,
  OrderType,
  useCcHoldingsQuery,
  useCreateCcOrderMutation,
} from "@/app/types/generated/graphql";

import { SellFormValues, SellOrderLifeCycle, UseSellOrderModalFn } from "./sell-order-modal.types";

export const useSellOrderModal: UseSellOrderModalFn = ({ selectedCCIds, closeModal }) => {
  const formRef = useRef<FormikProps<SellFormValues> | null>(null);

  const [currentStep, setCurrentStep] = useState(SellOrderLifeCycle.Form);

  const [savedData, setSavedData] = useState<SellFormValues>();

  const [failedOrders, setFailedOrders] = useState<SellFormValues["values"]>([]);

  const { loading: holdingsQueryLoading, data: holdingsData } = useCcHoldingsQuery({
    variables: {
      where: {
        ids: selectedCCIds,
      },
    },
  });

  const [createCCOrder] = useCreateCcOrderMutation({
    refetchQueries: [OrderHistoryV2Document, CcHoldingsDocument],
  });

  const { currencySymbol, currencyCode, loading: currencySymbolLoading } = useCurrencySymbol();

  const handleSubmit = useCallback((values: SellFormValues) => {
    setCurrentStep(SellOrderLifeCycle.Summary);
    setSavedData(values);
  }, []);

  const confirmSale = useCallback(async () => {
    const responses = await Promise.allSettled(
      savedData?.values?.map(async (value) => {
        const { __typename, country, ...relevantAttributes } = value.attributes;
        await createCCOrder({
          variables: {
            order: {
              position: OrderPosition.Ask,
              price: {
                amount: currency(value.unitPrice).intValue.toString(),
                currencyCode: currencyCode,
              },
              type: OrderType.Limit,
              volume: Number(value.volume),
            },
            attributes: {
              ...relevantAttributes,
              country: [country],
            },
          },
        });
      }) || [],
    );
    const failedMutations: SellFormValues["values"] = [];
    for (let i = 0; i < responses.length; i++) {
      const response = responses[i];
      if (response.status === "rejected") {
        if (savedData?.values[i])
          failedMutations.push({
            ...savedData.values[i],
            reason: response.reason.message,
          });
      }
    }
    setFailedOrders(failedMutations);
    setCurrentStep(SellOrderLifeCycle.Success);
  }, [savedData?.values, currencyCode, createCCOrder]);

  const initialValues: SellFormValues = useMemo(
    () =>
      savedData || {
        commonPrice: 0,
        values:
          holdingsData?.ccHoldings.map((data) => {
            return {
              ...data,
              attributes: data.attributes as CcAssetAttributes,
              unitPrice: 0,
              volume: 0,
              totalPrice: 0,
            };
          }) || [],
      },
    [holdingsData, savedData],
  );

  useEffect(() => {
    formRef.current?.resetForm({
      values: initialValues,
    });
    formRef.current?.validateForm(initialValues);
    initialValues.values.forEach((_, index) =>
      formRef.current?.setFieldTouched(`values.${index}.totalPrice`, true, true),
    );
  }, [initialValues]);

  const goToSellForm = useCallback(() => {
    setCurrentStep(SellOrderLifeCycle.Form);
  }, []);

  return {
    handleSubmit,
    formRef,
    tableLoading: currencySymbolLoading || holdingsQueryLoading,
    tableData: holdingsData?.ccHoldings || [],
    initialValues,
    currencySymbol,
    currentStep,
    failedOrders,
    goToSellForm,
    confirmSale,
    savedData,
    closeModal,
  };
};
