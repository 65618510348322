import { Box, Flex, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { useTranslation } from "react-i18next";

import { currencyFormatter } from "@/app/lib/currency-formatter";
import { calculateOrderAmounts } from "@/app/lib/order-amounts-calculator";

export default function BuyOrderSummary(props: {
  currencySymbol: any;
  title: string;
  unitPrice: number;
  volume: number;
  calculatedAmount: ReturnType<typeof calculateOrderAmounts>;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Box sx={{ my: 3, fontFamily: "MintGroteskV08", fontWeight: "bold", fontSize: 3 }}>{t(props.title)}</Box>
      <Box sx={{ fontSize: 1 }}>
        <Flex sx={{ justifyContent: "space-between", mt: 1 }}>
          <Text>{t("Product Quantity")}</Text>
          <Text>
            {currency(props.volume, { separator: ",", precision: 0, symbol: "", useVedic: false }).format() || "0"}
          </Text>
        </Flex>
        <Flex sx={{ justifyContent: "space-between", mt: 1 }}>
          <Text>{t("Bid Price per Unit")}</Text>
          <Text>{currencyFormatter(props.currencySymbol, props.unitPrice, { fromCents: false })}</Text>
        </Flex>
        <Flex sx={{ justifyContent: "space-between", mt: 1 }}>
          <Text>{t("Bid Order Value")}</Text>
          <Text>{currencyFormatter(props.currencySymbol, props.calculatedAmount.getTradeValue())}</Text>
        </Flex>
        <Flex sx={{ justifyContent: "space-between", mt: 1 }}>
          <Text>{t("GST (Bid Order Value)")}</Text>
          <Text>{currencyFormatter(props.currencySymbol, props.calculatedAmount.getGST("tradeValue"))}</Text>
        </Flex>
        <Flex
          sx={{
            mt: 1,
            justifyContent: "space-between",
          }}
        >
          <Text>{t("Transaction Fee")}</Text>
          <Text>+ {currencyFormatter(props.currencySymbol, props.calculatedAmount.getFee())}</Text>
        </Flex>
        <Flex
          sx={{
            justifyContent: "space-between",
            mt: 1,
            pb: 3,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: "textDarker",
          }}
        >
          <Text>{t(`GST (Transaction Fee)`)}</Text>
          <Text> + {currencyFormatter(props.currencySymbol, props.calculatedAmount.getGST("fee"))}</Text>
        </Flex>
        <Flex
          sx={{
            justifyContent: "space-between",
            pt: 3,
          }}
        >
          <Text>{t("Total Order Value")}</Text>
          <Text>{currencyFormatter(props.currencySymbol, props.calculatedAmount.getTotal())}</Text>
        </Flex>
      </Box>
    </>
  );
}
