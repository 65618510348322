import { Modal } from "@powerledger/ui-component-lib";

import { GenericTooltip } from "../../tooltips";
import { SellForm } from "./sell-form";
import { SellOrderLifeCycle, SellOrderModalProps } from "./sell-order-modal.types";
import { useSellOrderModal } from "./use-sell-order-modal";

export const SellOrderModal: React.FC<SellOrderModalProps> = ({ selectedCCIds, visible, closeModal }) => {
  const logic = useSellOrderModal({ selectedCCIds, closeModal });
  const isCurrentSellStepSuccess = logic.currentStep === SellOrderLifeCycle.Success;
  return (
    <Modal
      visible={visible}
      hideCard={isCurrentSellStepSuccess}
      modalMaxWidth={isCurrentSellStepSuccess ? "600px" : ""}
      onCancel={closeModal}
      sx={{ maxHeight: "inherit", mt: 1 }}
    >
      <SellForm {...logic} />
      <GenericTooltip />
    </Modal>
  );
};

export default SellOrderModal;
