import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useOktaRoles } from "@/app/hooks/use-okta-roles";
import { UserRoles } from "@/app/lib/role-based-access-control";
import { MembershipType, useAccountQuery } from "@/app/types/generated/graphql";

import { FeatureToggleKey, useFeatureToggle } from "../../hooks/use-feature-toggle";
import { SettingsTabs, UseSettingsFn } from "./settings.types";

export const useSettings: UseSettingsFn = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(SettingsTabs.details);

  const { userRoles } = useOktaRoles();

  const { value: multipleUserV2Enabled, loading } = useFeatureToggle({
    featureKey: FeatureToggleKey.MultipleUsersV2,
  });

  const {
    data: accountData,
    loading: accountLoading,
    error: accountError,
  } = useAccountQuery({
    variables: {
      where: {
        membershipTypes: [MembershipType.Proprietor, MembershipType.Trader],
      },
    },
  });

  const [receivedRedirectUri, setReceivedRedirectUri] = useState("");

  useEffect(() => {
    const { search } = location;
    const tab = new URLSearchParams(search).get("tab") as unknown as SettingsTabs;
    const redirectUri = new URLSearchParams(search).get("oauth_state_id");
    if (redirectUri) {
      setReceivedRedirectUri(window.location.href);
    } else setReceivedRedirectUri("");
    setCurrentTab(tab || SettingsTabs.details);
  }, [location]);

  const switchTab = useCallback(
    (tab: string) => {
      const params = new URLSearchParams({ tab });
      navigate(
        { pathname: location.pathname, search: params.toString() },
        {
          replace: true,
        },
      );
    },
    [navigate, location.pathname],
  );
  const showAdminTabs = multipleUserV2Enabled ? userRoles.includes(UserRoles.RoleAdmin) : true;
  const member = accountData?.account?.members?.[0];

  return {
    switchTab,
    currentTab,
    showAdminTabs,
    loading: loading || accountLoading,
    member,
    error:
      accountError?.message || !member
        ? "Failed to load account details, please refresh the page and try again. If issue persists, please contact support."
        : "",
  };
};
