import { ConfirmImportModalCustomProps } from "../components/confirm-import-modal/confirm-import-modal.types";
import { ConfirmRemoveUserModalCustomProps } from "../components/confirm-remove-user-modal.tsx/confirm-remove-user-modal.types";
import { OrderCancelModalCustomProps } from "../components/order-cancel-modal/order-cancel-modal.types";
import { BuyFormPrefilledAsset } from "../components/order-modal/buy-order-modal/buy-order-modal.types";

export interface ModalHandler {
  showBuyOrderModal: (prefilledAsset?: BuyFormPrefilledAsset) => void;
  showSellOrderModal: (selectedRecs: string[]) => void;
  showOnboardingCompletedModal: () => void;
  showContactSupportModal: () => void;
  showConfirmRemoveUserModal: (args: ConfirmRemoveUserModalCustomProps) => void;
  showConfirmImportModal: (args: ConfirmImportModalCustomProps) => void;
  showOrderCancelModal: (args: OrderCancelModalCustomProps) => void;
}

export enum ModalEnums {
  ContactSupportModal = "ContactSupportModal",
  OrderModal = "OrderModal",
  OnboardingCompletedModal = "OnboardingCompletedModal",
  BuyOrderModal = "BuyOrderModal",
  BuyOrderModalOld = "BuyOrderModalOld",
  SellOrderModal = "SellOrderModal",
  ConfirmRemoveUserModal = "ConfirmRemoveUserModal",
  ConfirmImport = "ConfirmImport",
  OrderCancel = "OrderCancel",
}

export type ModalState =
  | {
      type: ModalEnums.ContactSupportModal | ModalEnums.OnboardingCompletedModal;
      customProps: undefined;
    }
  | {
      type: ModalEnums.SellOrderModal;
      customProps: {
        selectedCCIds: string[];
      };
    }
  | {
      type: ModalEnums.BuyOrderModal;
      customProps: {
        defaultAsset?: BuyFormPrefilledAsset;
      };
    }
  | {
      type: ModalEnums.ConfirmRemoveUserModal;
      customProps: ConfirmRemoveUserModalCustomProps;
    }
  | {
      type: ModalEnums.ConfirmImport;
      customProps: ConfirmImportModalCustomProps;
    }
  | {
      type: ModalEnums.OrderCancel;
      customProps: OrderCancelModalCustomProps;
    };

export type FormModalProps<T, S> = {
  initialValues?: T;
  onSave: (args: S) => void;
};

export type TradeModalTab = "buy" | "sell";
