import { Button, ThemeUIStyleObject } from "@powerledger/ui-component-lib";
import { FC, ReactElement } from "react";

export const FiltersButton: FC<{
  active?: boolean;
  children: ReactElement;
  onClick: () => void;
  disabled?: boolean;
  sx?: ThemeUIStyleObject;
}> = ({ active, children, onClick, disabled, sx }) => (
  <Button
    onClick={onClick}
    variant="pill.compact"
    disabled={disabled}
    sx={{
      ...(!active ? { bg: "transparent", color: "text" } : {}),
      ...sx,
    }}
  >
    {children}
  </Button>
);
