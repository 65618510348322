import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { useCallback, useMemo, useState } from "react";

import { useCurrencySymbol } from "@/app/hooks/use-currency-symbol";
import { formatDate } from "@/app/lib/format-date";
import { modifyOrderAttributes } from "@/app/lib/order-attributes-modifier";

import {
  AssetType,
  CcOrderAttributes,
  MarketOrdersV2Query,
  OrderBookSortOrderInput,
  OrderPosition,
  useMarketOrdersV2Query,
} from "../../types/generated/graphql";
import { defaultMarketFilterState, MarketFilterState, MarketTableData, MarketViewProps } from "./market.types";

export const getMarketTableData = (data?: MarketOrdersV2Query) => {
  const marketTableData: MarketTableData[] = [];
  if (!data?.marketOrdersV2?.orders) return marketTableData;
  for (const order of data.marketOrdersV2.orders) {
    if (!order) continue;
    const attributes = order.attributes as CcOrderAttributes;
    const defaultedAttributes = modifyOrderAttributes(attributes, order.position);

    marketTableData.push({
      ...order,
      attributes: { ...attributes, sdgCount: attributes.sdgGoals?.length ?? 0 },
      expandedData: {
        "Project ID": defaultedAttributes.projectId,
        "Project Type": defaultedAttributes.projectTypes,
        "Vintage From": attributes.vintageFrom
          ? formatDate(attributes.vintageFrom, {
              noTZ: true,
            })
          : defaultedAttributes.vintageFrom,
        "Vintage To": attributes.vintageTo
          ? formatDate(attributes.vintageTo, {
              noTZ: true,
            })
          : defaultedAttributes.vintageTo,
        "Sectoral Scope": defaultedAttributes.sectoralScopes,
        "Sustainable Development Goals (SDGs)": defaultedAttributes.sdgGoals,
        "Project Information Link": defaultedAttributes.projectLink,
      },
    });
  }

  return marketTableData;
};

export const useMarket = (): MarketViewProps => {
  const [typeFilter, setTypeFilter] = useState<OrderPosition | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<MarketFilterState>(defaultMarketFilterState);

  const { currencySymbol, loading: currencySymbolLoading } = useCurrencySymbol();

  const { pageInfo, offset, fetchData, sort, resetPage, setTotalItems } = useTablePaginationManager<
    MarketTableData,
    OrderBookSortOrderInput[]
  >({});

  const { data, loading, refetch, previousData } = useMarketOrdersV2Query({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        offSetPaginationInfo: {
          limit: pageInfo.pageSize,
          offset,
        },
        sortOrderInputs: sort,
        where: {
          ccAttributes: {
            projectId: selectedFilters.projectId ? selectedFilters.projectId[0] : undefined,
            vintage: +selectedFilters.vintage || undefined,
            country: selectedFilters.location.length ? selectedFilters.location : undefined,
            registryName: selectedFilters.registry.length ? selectedFilters.registry : undefined,
            coBenefits: selectedFilters.coBenefits.length ? selectedFilters.coBenefits : undefined,
            sectoralScopes: selectedFilters.sectoralScopes.length ? selectedFilters.sectoralScopes : undefined,
            sdgGoals: selectedFilters.sdgGoals.length ? selectedFilters.sdgGoals : undefined,
            projectTypes: selectedFilters.projectTypes.length ? selectedFilters.projectTypes : undefined,
          },
          assetType: AssetType.Cc,
          position: typeFilter,
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.marketOrdersV2?.offsetInfo?.total ?? 0);
    },
  });

  const handleChangeTypeFilter = useCallback(
    (position: OrderPosition | null) => {
      setTypeFilter(position);
      setSelectedFilters(defaultMarketFilterState);
      resetPage();
    },
    [resetPage],
  );

  const currentData = data ?? previousData;

  const tableData = useMemo(() => getMarketTableData(currentData), [currentData]);

  const applyFilters = useCallback(
    (editingFilters: MarketFilterState) => {
      setSelectedFilters(editingFilters);
    },
    [setSelectedFilters],
  );
  const resetSelectedFilters = useCallback(() => {
    setSelectedFilters(defaultMarketFilterState);
  }, []);

  const hideBuyButton = useMemo(() => typeFilter === OrderPosition.Bid, [typeFilter]);
  const hideSellButton = useMemo(() => typeFilter === OrderPosition.Ask, [typeFilter]);

  const resetFilterState = useMemo(
    () => Object.values(selectedFilters).every((value) => !value.length),
    [selectedFilters],
  );

  const assetCounts = useMemo(
    () => ({
      bid: currentData?.marketOrdersV2?.data?.totalBids ?? 0,
      ask: currentData?.marketOrdersV2?.data?.totalAsks ?? 0,
    }),
    [currentData?.marketOrdersV2?.data],
  );

  const reloadMarketData = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    typeFilter,
    handleChangeTypeFilter,
    fetchData,
    reloadMarketData,
    pageInfo,
    tableData,
    selectedFilters,
    loading: loading || currencySymbolLoading,
    applyFilters,
    resetSelectedFilters,
    hideSellButton,
    assetCounts,
    hideBuyButton,
    resetFilterState,
    currencySymbol,
  };
};
