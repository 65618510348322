import * as Yup from "yup";

import { validPostCodes } from "../../post-code-validator";

export const physicalAddressSchema = Yup.object().shape({
  physicalAddress: Yup.object().shape({
    countryCode: Yup.string().nullable().required("Required"),
    state: Yup.string().nullable().required("Required"),
    city: Yup.string().nullable().required("Required"),
    line1: Yup.string().nullable().required("Required"),
    line2: Yup.string().nullable(),
    postalCode: Yup.string()
      .nullable()
      .required("Required")
      .when("countryCode", (countryCode, schema) => {
        return schema.matches(
          validPostCodes[countryCode.toLowerCase()],
          `Invalid Postcode for country code - ${countryCode}.`,
        );
      }),
  }),
});
