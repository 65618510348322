import {
  Box,
  Button,
  Flex,
  IconChevronDown,
  IconCircle,
  IconCircleSelected,
  IconCrossOutlined,
  IconInfo,
  IconSearch,
  LegacyCheckbox,
  ThemeUIStyleObject,
} from "@powerledger/ui-component-lib";
import { isEqual } from "lodash";
import { ChangeEvent, FC, memo, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Input } from "@/app/components/input";
import { MultiSelectActions } from "@/app/components/local-selects/helpers";
import { useProjectIdsSearch } from "@/app/components/project-ids-search/use-project-ids-search";
import { useDebouncedInput } from "@/app/hooks";

import { FiltersButton } from "./filter-button";
import { ColumnType } from "./market-filter.types";
import { singleAttributeSelections } from "./use-market-filter";

export const FilterColumn: FC<{
  column: ColumnType;
  sx?: ThemeUIStyleObject;
  loading?: boolean;
}> = ({
  loading,
  column: {
    title,
    tooltipContent,
    options,
    selectedOptions,
    toggleFilter,
    clearFilters,
    disabled,
    disableOptions,
    extraSelector,
    withSearch,
  },
  sx,
}) => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState("");
  const { localState: localFilterValue, handleFieldChange } = useDebouncedInput(
    {
      value: filter,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
      },
      onBlur: () => {},
      name: "",
      shouldDebounce: true,
    },
    500,
  );

  const { cachedData, onCustomSearch, loading: queryLoading } = useProjectIdsSearch({ pageSize: Number.MAX_VALUE });

  useEffect(() => {
    title === "Project ID" && onCustomSearch(localFilterValue);
  }, [localFilterValue, title, onCustomSearch]);

  useEffect(() => {
    if (!selectedOptions?.length) {
      setFilter("");
    }
  }, [selectedOptions?.length]);

  const displayOptions: ColumnType["options"] = useMemo(() => {
    /**
     * Just temporary
     * Soon we must change the filter design
     */
    if (title === "Project ID") {
      return cachedData;
    }
    return options[0]?.options
      ? options.map((opt) => ({
          ...opt,
          options: filter
            ? opt.options?.filter((subOpt) => subOpt.label.toLowerCase()?.includes(filter.toLowerCase()))
            : opt.options,
        }))
      : withSearch
      ? options.filter((opt) => opt.label.toLowerCase() === filter.toLowerCase())
      : options;
  }, [filter, withSearch, cachedData, title, options]);

  const isLoading = loading || queryLoading;

  return (
    <Flex
      sx={{
        borderRightWidth: 1,
        borderRightStyle: "solid",
        borderRightColor: "foregroundLight",
        flexDirection: "column",
        ...sx,
      }}
    >
      <Flex
        sx={{
          py: 3,
          height: 7,
          justifyContent: "center",
          width: "100%",
          alignItems: "center",
          letterSpacing: 2,
          textTransform: "uppercase",
          color: "white",
          fontWeight: "bold",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: "foregroundLight",
        }}
      >
        <Box sx={{ fontSize: 0 }}>{t(title)}</Box>
        {tooltipContent && <IconInfo size={5} data-tip={tooltipContent} sx={{ ml: 2 }} />}
      </Flex>
      <Flex
        sx={{
          py: 3,
          justifyContent: "center",
          width: "100%",
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: "foregroundLight",
        }}
      >
        <FiltersButton
          sx={{ display: "flex", justifyContent: "center" }}
          active={!selectedOptions?.length}
          disabled={disabled}
          onClick={clearFilters}
        >
          <>{t("Any")}</>
        </FiltersButton>
      </Flex>

      <Box
        sx={{
          px: 2,
          py: 3,
          height: "100%",
          overflowY: "scroll",
          listStyle: "none",
          "&::-webkit-scrollbar": {
            width: 20,
          },
          "&::-webkit-scrollbar-thumb": {
            border: "8px solid rgba(0, 0, 0, 0)",
            backgroundClip: "padding-box",
            borderRadius: 999,
            backgroundColor: "#68687E",
          },
        }}
      >
        {/* Temporary Check only for now until filter design gets changed */}
        {title === "Project ID" && (
          <Flex sx={{ mb: 2, flexWrap: "wrap", gap: 1 }}>
            {selectedOptions.map((opt) => (
              <Button key={opt} as="div" variant="pillInverted.compact" sx={{ fontSize: "0.8em", py: 0, px: 2 }}>
                {opt}
                <Button
                  sx={{ ml: 2, p: 0, bg: "transparent" }}
                  onClick={() => {
                    toggleFilter(opt);
                  }}
                >
                  <IconCrossOutlined size={3} />
                </Button>
              </Button>
            ))}
          </Flex>
        )}
        {(Boolean(options[0]?.options?.length) || withSearch) && (
          <Flex
            sx={{
              position: "relative",
              alignItems: "center",
            }}
          >
            <Input
              disabled={disabled}
              aria-label={t(`search {{attributes}}`, { attributes: title })}
              containerSx={{
                flex: 1,
              }}
              sx={{
                bg: "transparent",
                borderWidth: 0,
                height: "30px",
                width: "100%",
                borderBottom: "1px solid white",
                borderRadius: 0,
                py: 0,
              }}
              placeholder={t("Search")}
              onChange={(e) => {
                handleFieldChange(e);
              }}
              value={localFilterValue}
            />
            <Box
              sx={{
                position: "absolute",
                right: 0,
              }}
            >
              <IconSearch size={6} color="white" />
            </Box>
          </Flex>
        )}

        {isLoading ? (
          <Button
            sx={{
              fontSize: 1,
              gap: 1,
              fontFamily: "MintGroteskV08",
              textAlign: "left",
              color: "textDarker",
              bg: "transparent",
              py: 2,
              px: 0,
              display: "flex",
              width: "100%",
            }}
            as="div"
          >
            {t("Loading...")}
          </Button>
        ) : (
          displayOptions.map((option, index) => {
            const showSingleSelector =
              singleAttributeSelections.some((attrs) => attrs.label.toLowerCase() === title.toLowerCase()) ||
              (option.checkedIcon && option.uncheckedIcon);
            return (
              <OptionList
                key={option.label + option.value}
                option={option}
                index={index}
                selectedOptions={selectedOptions}
                disableOptions={disableOptions}
                disabled={disabled}
                showSingleSelector={showSingleSelector}
                toggleFilter={toggleFilter}
                extraSelector={extraSelector}
                filtered={Boolean(filter.length)}
              />
            );
          })
        )}
      </Box>
    </Flex>
  );
};

const OptionList: React.FC<{
  toggleFilter: ColumnType["toggleFilter"];
  index: number;
  option: ColumnType["options"]["0"];
  disableOptions: ColumnType["disableOptions"];
  selectedOptions: ColumnType["selectedOptions"];
  extraSelector: ColumnType["extraSelector"];
  showSingleSelector?: boolean;
  filtered?: boolean;
  disabled?: boolean;
}> = memo(
  function Options({
    option,
    index,
    showSingleSelector = false,
    extraSelector,
    toggleFilter,
    filtered,
    disableOptions,
    disabled,
    selectedOptions,
  }) {
    const [openedSubOptions, setOpenedSubOptions] = useState<Record<string, boolean> | null>(null);

    const checkOptionDisabled = disableOptions ? disableOptions(option.name) : Boolean(disabled);
    const selectedOption = selectedOptions?.includes(option.value || "");

    /** Open all sub options when filtered is true */
    useEffect(() => {
      if (checkOptionDisabled) {
        setOpenedSubOptions(null);
      } else if (filtered) {
        setOpenedSubOptions((prev) => ({
          ...prev,
          [option.label]: true,
        }));
      }
    }, [filtered, checkOptionDisabled, option.label]);

    const allSelected = option.options?.every((option) => selectedOptions?.includes(option.value));

    return Array.isArray(option.options) ? (
      <Box sx={{ flexShrink: 0 }}>
        <Button
          sx={{
            fontSize: 1,
            gap: 1,
            fontFamily: "MintGroteskV08",
            textAlign: "left",
            color: "text",
            alignItems: "center",
            justifyContent: "space-between",
            bg: "transparent",
            py: 2,
            px: 0,
            display: "flex",
            width: "100%",
            "&:enabled:hover, &:enabled:focus": { bg: "transparent" },
            "&:disabled": {
              bg: "transparent",
            },
          }}
          disabled={checkOptionDisabled}
          onClick={() =>
            setOpenedSubOptions((prev) => ({
              ...prev,
              [option.label]: !prev?.[option.label],
            }))
          }
        >
          {option.label}
          <Box
            sx={{
              svg: {
                transform: openedSubOptions?.[option.label] ? "rotate(180deg)" : "",
              },
            }}
          >
            <IconChevronDown size={5} color="text" />
          </Box>
        </Button>

        {openedSubOptions?.[option.label] &&
          [
            ...(filtered
              ? []
              : [
                  {
                    name: option.label,
                    label: "All",
                    allSelected,
                    value: allSelected ? MultiSelectActions.AllDeselected : MultiSelectActions.AllSelected,
                  },
                ]),
            ...option.options,
          ].map((option, jindex) => {
            return (
              <OptionList
                key={option.value}
                option={option}
                index={jindex}
                selectedOptions={selectedOptions}
                disabled={disabled}
                disableOptions={disableOptions}
                toggleFilter={toggleFilter}
                extraSelector={extraSelector}
              />
            );
          })}
      </Box>
    ) : (
      <Box sx={{ width: "100%", mt: 2 }}>
        <Button
          variant="text"
          sx={{
            mt: index ? 2 : 0,
            width: "100%",
            justifyContent: "flex-start",
            opacity: checkOptionDisabled ? "50%" : "100%",
            alignItems: "center",
            "&:enabled:hover, &:enabled:focus": { textDecoration: "none" },
          }}
          disabled={checkOptionDisabled}
          onClick={() => toggleFilter(option.value || "", option.name)}
        >
          <Box sx={{ flexShrink: 0 }}>
            {showSingleSelector ? (
              <Flex sx={{ flexShrink: 0 }}>
                {selectedOption
                  ? option.checkedIcon || <IconCircleSelected size={5} color="primary" />
                  : option.uncheckedIcon || <IconCircle size={5} color="transparent" />}
              </Flex>
            ) : (
              <LegacyCheckbox
                disabled={checkOptionDisabled}
                sx={{
                  /** Input for checkboxes are positioned weirdly faar at the bottom
                    which causes overflow and scroll behavior withoutt any content
                */
                  input: {
                    display: "none",
                  },
                }}
                checked={Boolean(option.allSelected) || selectedOption}
                onChange={() => toggleFilter(option.value || "", option.name)}
              />
            )}
          </Box>
          <Flex
            sx={{
              fontSize: 1,
              gap: 1,
              fontFamily: "MintGroteskV08",
              color: "text",
              textAlign: "left",
              flex: 1,
            }}
          >
            {option.label}
          </Flex>
        </Button>
        {extraSelector?.(selectedOption)}
      </Box>
    );
  },
  (prev, next) => isEqual(prev, next),
);
