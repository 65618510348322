import { NavLink } from "react-router-dom";
import { Box, Button } from "theme-ui";

import { TableActionLinkButtonProps } from "./table-action-buttons.types";

export const TableActionLinkButton: React.FC<TableActionLinkButtonProps> = ({ text, linkProps, sx }) => {
  return (
    <Box
      sx={{
        a: {
          textDecoration: "none",
          "& :hover": {
            borderColor: "backgroundLight",
          },
        },
      }}
    >
      <NavLink {...linkProps}>
        <Button
          as="div"
          variant="pill.compactSecondary"
          sx={{
            width: 109,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 27,
            p: "5px, 16px, 5px, 16px",
            ...sx,
          }}
        >
          {text}
        </Button>
      </NavLink>
    </Box>
  );
};
