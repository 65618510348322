import { Box, Button, Flex, IconLoading, Modal } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { useModalHelpers } from "@/app/hooks/use-modal-helpers";
import {
  CcHoldingsDocument,
  OrderHistoryV2Document,
  useCancelOrderMutation,
  UserIcxAccountBalanceDocument,
} from "@/app/types/generated/graphql";

import { OrderCancelModalProps } from "./order-cancel-modal.types";

export const OrderCancelModal: React.FC<OrderCancelModalProps> = ({ visible, orderId, closeModal }) => {
  const { t } = useTranslation();
  const [cancelOrder] = useCancelOrderMutation({
    refetchQueries: [OrderHistoryV2Document, UserIcxAccountBalanceDocument, CcHoldingsDocument],
  });

  const { onConfirm, submitting } = useModalHelpers({
    onSubmit: async () => {
      await cancelOrder({ variables: { orderId } });
    },
    closeModal,
    messages: {
      successMessage: t("Order successfully withdrawn"),
    },
  });

  return (
    <Modal visible={visible} maskClosable hideCloseButton sx={{ maxHeight: "inherit", mt: 1 }} compact>
      <Box sx={{ p: 4 }}>
        <Box sx={{ fontSize: 3 }}>{t("Are you sure you want to withdraw this order?")}</Box>
        <Box sx={{ color: "textDarker", mt: 2 }}>
          {t(
            "Please Note, withdrawing a partially fulfilled order will remove its unfulfilled portion from the market.",
          )}
        </Box>
        <Flex sx={{ justifyContent: "flex-end", mt: 4, gap: 2 }}>
          <Button
            type="button"
            variant="warning"
            sx={{
              width: 9,
            }}
            disabled={submitting}
            onClick={closeModal}
          >
            {t("Cancel")}
          </Button>
          <Button variant="pill" sx={{ width: 9 }} disabled={submitting} onClick={onConfirm}>
            {t("Confirm")}
          </Button>
          {submitting && <IconLoading />}
        </Flex>
      </Box>
    </Modal>
  );
};

export default OrderCancelModal;
