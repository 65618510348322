import { Flex, IconError } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { AppDateFormats, formatDate } from "@/app/lib/format-date";

import { TradingHaltNotifierViewProps } from "./trading-halt-notifier.types";

export const TradingHaltNotifierView: FC<TradingHaltNotifierViewProps> = ({ tradingHaltEndDateTime }) => {
  const { t } = useTranslation();
  return (
    <Flex sx={{ alignItems: "center", fontSize: 1, bg: tradingHaltEndDateTime ? "foreground" : "", p: 3, flexGrow: 1 }}>
      {tradingHaltEndDateTime && (
        <>
          <IconError size={5} color="" sx={{ mr: 2 }} />{" "}
          {t("Trading is currently halted until {{endDateTime}}.", {
            endDateTime: formatDate(tradingHaltEndDateTime, {
              formatStyle: AppDateFormats.UniversalDateTimeFormatWithOffset,
            }),
          })}
        </>
      )}
    </Flex>
  );
};
