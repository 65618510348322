import { Box, Button } from "@powerledger/ui-component-lib";
import { ReactNode, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const RouteButton = ({
  children,
  onClick,
  to,
  secondary,
  sx,
  badge,
  ignoreSearchParam = "?tab=registry",
}: {
  children: any;
  onClick?: any;
  to?: string;
  secondary?: boolean;
  sx?: any;
  badge?: ReactNode;
  ignoreSearchParam?: string;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = useCallback(() => {
    if (to) {
      navigate(to);
    }
    if (onClick) onClick();
  }, [navigate, onClick, to]);

  const isPathnameMatching = location.pathname === to;
  const isTabRegistry = location.search !== ignoreSearchParam;
  const isFullPathMatching = location.pathname + location.search === to;
  const isLocationMatching = (isPathnameMatching && isTabRegistry) || isFullPathMatching;

  return (
    <Box sx={{ position: "relative" }}>
      <Button
        variant={secondary ? "avatarSecondary" : "avatar"}
        sx={{
          ...sx,
          ...(isLocationMatching
            ? {
                bg: "primary",
                color: "background",
                "div svg path": {
                  transition: "base",
                  color: "background",
                  fill: "background",
                },
              }
            : {}),
        }}
        onClick={handleOnClick}
      >
        {children}
      </Button>
      {badge && (
        <Box
          sx={{
            position: "absolute",
            top: 1,
            mt: -3,
            ml: 2,
            pointerEvents: "none",
            left: "50%",
            transform: "translate(-50%, 0)",
          }}
        >
          {badge}
        </Box>
      )}
    </Box>
  );
};
