import { Locale, setDefaultOptions } from "date-fns";
import { registerLocale } from "react-datepicker";

export const init = async (systemLocale: string): Promise<void> => {
  // date-fns default locale is en-US as of version v2.29.2
  if (systemLocale === "en-US") return;
  try {
    const locale = (await import(`../../../node_modules/date-fns/locale/${systemLocale}/index.js`)) as
      | Locale
      | undefined;
    setDefaultOptions({ locale });
    if (locale) registerLocale(systemLocale, locale);
  } catch (e) {
    throw new Error(`Failed to load date-fns locale info for ${systemLocale}`);
  }
};

export const i18nDate = {
  init,
};

export default i18nDate;
