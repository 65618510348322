import { Flex } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { AccountBalance } from "../../account-balance";
import { LayoutHeaderProps } from "./layout-header.types";

export const LayoutHeader: FC<LayoutHeaderProps> = ({ header }) => {
  return (
    header || (
      <Flex
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <AccountBalance />
      </Flex>
    )
  );
};
