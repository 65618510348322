import { LegacySelect, SelectType } from "@powerledger/ui-component-lib";

import { FormattedOption } from "@/app/lib/format-asset-options";

import { OptionComponent } from "./common-components";

export const LocalMultiSelect = <T extends FormattedOption["0"] = FormattedOption["0"], K extends boolean = true>(
  props: SelectType<T, K>,
) => (
  <LegacySelect
    components={{
      Option: OptionComponent,
    }}
    {...props}
    closeMenuOnScroll
  />
);
