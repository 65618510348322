import { OrderPosition } from "../types/generated/graphql";

export const getDefaultValue = (orderPosition: OrderPosition, value?: string | string[] | number | null) => {
  if (value && value.toString().length) {
    return value;
  }
  return orderPosition === OrderPosition.Bid ? "Any" : "-";
};

export const modifyOrderAttributes = <T extends object>(attributes: T, position: OrderPosition) => {
  return Object.entries(attributes).reduce((attributes, [key, value]) => {
    attributes[key as keyof T] = getDefaultValue(position, value);
    return attributes;
  }, {} as Record<keyof T, string | string[] | number>) as T;
};
