import { Box, Flex, Heading } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { LoadingErrorLayout } from "@/app/components/loading-error-layout";
import { SeparatorLayout } from "@/app/components/separator-layout";
import { BankType, useAccountBankDetailsQuery } from "@/app/types/generated/graphql";

export const PaymentServicesTab = () => {
  const { t } = useTranslation();

  const {
    data: accountBankData,
    loading: accountBankDataLoading,
    error: accountDataError,
  } = useAccountBankDetailsQuery();

  return (
    <LoadingErrorLayout
      loading={accountBankDataLoading}
      error={
        accountDataError || !accountBankData?.account?.banks
          ? "Failed to load payment details, please refresh the page and try again. If issue persists, please contact support."
          : ""
      }
    >
      <Flex sx={{ alignItems: "center" }}>
        <Heading as="h2">{t("Payment Details")}</Heading>
      </Flex>
      {accountBankData?.account?.banks?.map((bankDetail) => (
        <Flex sx={{ mt: 5, flexDirection: "column" }} key={bankDetail.id}>
          <Box>
            {t(
              `Bank Account Details: ${
                bankDetail.type === BankType.Settlement ? "Settlement Account" : "Primary Withdraw Account (Funds)"
              }`,
            )}
          </Box>
          <SeparatorLayout
            contentStyle={{
              p: "16px 16px 16px 5px",
              textAlign: "left",
              color: "white",
              width: ["100%", "22%"],
            }}
            wrapperStyle={{
              mt: 16,
              p: 20,
              borderRadius: 8,
              background: "secondaryDarker",
            }}
            descriptionStyle={{
              color: "white",
            }}
            data={{
              "Account No.": bankDetail?.accountNumber ?? "",
              "Bank Name": bankDetail?.bankName ?? "",
              "Branch Address": bankDetail?.branchAddress ?? "",
              "IFSC Code": bankDetail?.ifsCode ?? "",
            }}
            showSeparator={false}
          />
        </Flex>
      ))}
    </LoadingErrorLayout>
  );
};
