import { Member } from "@/app/types/generated/graphql";

export enum SettingsTabs {
  details = "details",
  paymentServices = "paymentServices",
  personalDetails = "personalDetails",
  registry = "registry",
}

export type AccountMemberDetailsProps = {
  loading: boolean;
  error: string;
  member?: Partial<Member>;
};

export type UseSettingsFn = () => {
  switchTab: (tab: string) => void;
  currentTab: SettingsTabs;
  loading: boolean;
  showAdminTabs: boolean;
} & AccountMemberDetailsProps;

export type SettingsViewProps = ReturnType<UseSettingsFn>;
