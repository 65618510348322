import {
  Box,
  Button,
  Flex,
  Heading,
  IconInfo,
  IconLoading,
  Table,
  Text,
  ThemeUIStyleObject,
} from "@powerledger/ui-component-lib";
import { Field, Formik, FormikErrors, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Column, Row } from "react-table";
import ReactTooltip from "react-tooltip";
import * as Yup from "yup";

import { Form, FormFieldLabel } from "@/app/components/form";
import { currencyFormatter } from "@/app/lib/currency-formatter";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { HoldingsTableData } from "@/app/pages/cc-holdings";
import { getHoldingsAvailableColumn } from "@/app/pages/cc-holdings/components";

import { CleaveInput } from "../../cleave-input";
import { AppCleaveTypes } from "../../cleave-input/get-cleave-options";
import { TableValueWrapper } from "../../table-value-wrapper";
import Confirmation from "../confirmation";
import { SellFormProps, SellFormValues, SellOrderLifeCycle } from "./sell-order-modal.types";
import SellOrderSummary from "./sell-order-summary";

const OrderFormInfo = () => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        p: 2,
        mb: 3,
        borderRadius: 4,
        width: "max-content",
        flexDirection: "column",
        color: "text",
        flexWrap: "wrap",
        gap: 2,
        fontSize: 0,
      }}
    >
      {[
        "Quantities cannot be less than 10 or more than available volume",
        "Unit Price should be at least $0.15",
        "Unit Price can only be in 5 cent multiples i.e. $0.15, $0.20, $1.25, but not $0.12 or $0.53",
      ].map((err) => (
        <Flex
          sx={{
            alignItems: "center",
          }}
          key={err}
        >
          <IconInfo size={4} />
          {t(err)}
        </Flex>
      ))}
    </Flex>
  );
};

const TransactionCalculatedValue = ({
  index = 0,
  containerSx,
  currencySymbol,
}: {
  index?: number;
  currencySymbol?: string;
  containerSx?: ThemeUIStyleObject;
}) => {
  const { values, errors, touched } = useFormikContext<SellFormValues>();
  const combinedValue = values.values[index]?.unitPrice * values.values[index]?.volume;
  const errorValueAtIndex = errors.values?.[index] as FormikErrors<SellFormValues["values"][0]>;

  const correspondingFieldErrors =
    (errorValueAtIndex?.volume && touched.values?.[index]?.volume) ||
    (errorValueAtIndex?.unitPrice && touched.values?.[index]?.unitPrice) ||
    !values.values?.[index]?.volume ||
    !values.values?.[index]?.unitPrice;

  const showValue = !correspondingFieldErrors
    ? currencyFormatter(currencySymbol, combinedValue, { fromCents: false })
    : `${currencySymbol} —`;

  return (
    <Box sx={{ width: "100%", px: 2, ...containerSx }}>
      <ReactTooltip id={`totalPriceToolTip-tradeValue`} place="top" effect="solid" />
      <Box data-tip={showValue} data-for={`totalPriceToolTip-tradeValue`}>
        <Field
          name={`values.${index}.tradeValue`}
          component={() => (
            <Box
              sx={{
                width: "inherit",
                textAlign: "right",
              }}
            >
              {showValue}
            </Box>
          )}
        />
      </Box>
    </Box>
  );
};

export const SellForm: React.FC<SellFormProps> = ({
  formRef,
  tableLoading,
  handleSubmit,
  currentStep,
  tableData,
  savedData,
  goToSellForm,
  confirmSale,
  currencySymbol,
  initialValues,
  closeModal,
  failedOrders,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tableColumns: Column<HoldingsTableData["0"]>[] = [
    getHoldingsAvailableColumn(t),
    {
      Header: <Flex sx={{ alignItems: "center" }}>{t("Quantity")}</Flex>,
      id: "quantity",
      Cell: ({ row }: { row: Row<HoldingsTableData> }) => (
        <Form.Item sx={{ width: "100px", mb: 0 }}>
          <Field
            name={`values.${row.index}.volume`}
            sx={{
              input: {
                fontSize: 2,
                bg: "foreground",
              },
            }}
            type={AppCleaveTypes.Quantity}
            component={CleaveInput}
            variant="input"
          />
        </Form.Item>
      ),
      disableSortBy: true,
    },
    {
      Header: <Flex sx={{ alignItems: "center" }}>{t("Unit Price")}</Flex>,
      id: "price",
      Cell: ({ row }: { row: Row<HoldingsTableData> }) => (
        <Form.Item sx={{ width: "100px", mb: 0 }}>
          <Field
            name={`values.${row.index}.unitPrice`}
            prefix={currencySymbol}
            sx={{
              input: {
                fontSize: 2,
                bg: "foreground",
              },
            }}
            type={AppCleaveTypes.Amount}
            component={CleaveInput}
            variant="input"
          />
        </Form.Item>
      ),
      disableSortBy: true,
    },
    {
      Header: (
        <Flex
          sx={{
            width: "100%",
            justifyContent: "center",
          }}
        >
          {t("Trade Value")}
        </Flex>
      ),
      id: "tradeValue",
      width: "100px",
      Cell: ({ row }: { row: Row<HoldingsTableData["0"]> }) => (
        <TransactionCalculatedValue index={row.index} currencySymbol={currencySymbol} />
      ),
      disableSortBy: true,
    },
    {
      Header: t("Client Code"),
      accessor: (row) => t(row.attributes.clientCode || ""),
      Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      disableSortBy: true,
    },
    {
      Header: t("Vintage"),
      accessor: (row) => t(row.attributes.vintage.toString()),
      Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      disableSortBy: true,
    },
    {
      Header: t("Registry"),
      accessor: (row) => t(row.attributes.registryName),
      Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      disableSortBy: true,
    },
    {
      Header: t("Project ID"),
      accessor: (row) => row.attributes.projectId,
      Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      disableSortBy: true,
    },
    {
      Header: t("Project Name"),
      accessor: (row) => row.attributes.projectName,
      Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      disableSortBy: true,
    },
  ];

  if (currentStep === SellOrderLifeCycle.Summary) {
    return (
      <SellOrderSummary
        onConfirm={confirmSale}
        goBack={goToSellForm}
        data={savedData?.values || []}
        currencySymbol={currencySymbol}
      />
    );
  }

  if (currentStep === SellOrderLifeCycle.Success) {
    return (
      <Confirmation
        currencySymbol={currencySymbol}
        text={t("The order requests have been submitted.")}
        failedOrders={failedOrders}
        action={{
          content: failedOrders?.length ? "View your successful orders here" : "View your orders here",
          onClick: () => {
            navigate("/orders?type=Ask");
            closeModal();
          },
        }}
      />
    );
  }

  return (
    <Box sx={{ minHeight: "200px" }}>
      <Formik
        initialValues={initialValues}
        innerRef={formRef}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        validationSchema={Yup.object().shape({
          commonPrice: Yup.number(),
          values: Yup.array().of(
            Yup.object().shape({
              unitPrice: Yup.number()
                .moreThan(0.1, t("Unit Price should be at least $0.15"))
                .hasTickSize(0.05, t("Minimum tick size of unit price should be 0.05")),
              volume: Yup.number()
                .required(t("Required"))
                .min(10, t("Quantities should be at least 10 and less than available carbon credits"))
                .max(
                  Yup.ref("availableBalance") || 0,
                  t("Quantities should be at least 10 and less than available carbon credits"),
                ),
            }),
          ),
        })}
        validateOnMount
      >
        {({ handleSubmit, values, setFieldTouched, isSubmitting, isValid, setValues }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Box
                sx={{
                  py: 4,
                  px: 5,
                }}
              >
                <Box
                  sx={{
                    mb: 3,
                  }}
                >
                  <Heading
                    sx={{
                      variant: "texts.tracexDefaultHeading",
                      mb: 2,
                    }}
                  >
                    {t("Sale Offer Details")}
                  </Heading>
                  <OrderFormInfo />
                  <Table
                    loading={tableLoading}
                    columns={tableColumns}
                    showScrollArrows
                    dataSource={tableData}
                    noHover
                    pagination={false}
                    translation={getTableTranslation(t, {
                      noDataText: t("You currently don't have any Carbon Credits to sell"),
                    })}
                  />
                  <Flex
                    sx={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                      mt: 4,
                    }}
                  >
                    <Flex>
                      <Text
                        sx={{
                          color: "textDarker",
                          fontSize: 0,
                          fontFamily: "MintGroteskV08",
                        }}
                      >
                        {t("Can't see the")} <Text>{t("CARBON CREDITS")}</Text> {t("you're after?")}
                      </Text>
                      <Button
                        variant="secondary"
                        sx={{ ml: 3 }}
                        onClick={() => {
                          navigate("/carbon-credits/holdings");
                          closeModal();
                        }}
                      >
                        {t("View all Holdings")}
                      </Button>
                    </Flex>
                  </Flex>
                </Box>

                <Flex
                  sx={{
                    justifyContent: "space-between",
                  }}
                >
                  <Flex
                    sx={{
                      pt: 3,
                      pb: 4,
                      width: "50%",
                      justifyContent: "space-between",
                      mr: 4,
                    }}
                  >
                    <Form.Item sx={{ width: "50%", mr: 2 }}>
                      <FormFieldLabel
                        small
                        name="commonPrice"
                        label={t("Update all prices")}
                        info={t("Press Enter to apply changes")}
                      >
                        <Field
                          name="commonPrice"
                          type={AppCleaveTypes.Amount}
                          component={CleaveInput}
                          prefix={currencySymbol}
                          disabled={!initialValues.values?.length}
                          variant="input"
                          onKeyDown={async (e: React.KeyboardEvent<HTMLInputElement & { rawValue: string }>) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              await setValues({
                                ...values,
                                values: values.values?.map((value) => ({
                                  ...value,
                                  unitPrice: +e.currentTarget.rawValue,
                                })),
                              });
                              /**
                               * Form Still remains invalid unless we unfocus from this input (when updating all price )
                               * We could also use validateForm(), but this seems like short solution
                               */
                              setFieldTouched("commonPrice", true, true);
                            }
                          }}
                        />
                      </FormFieldLabel>
                    </Form.Item>
                  </Flex>
                  <Flex
                    sx={{
                      justifyContent: "flex-end",
                      alignItems: "center",
                      mt: 2,
                      fontFamily: "MintGroteskV08",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="pill"
                      disabled={!isValid || isSubmitting || !initialValues.values?.length}
                      sx={{ px: 4 }}
                    >
                      {t("Review Order Details")}
                    </Button>
                    {isSubmitting && <IconLoading />}
                  </Flex>
                </Flex>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
