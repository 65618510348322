import { Box, Button, Flex, Heading, Table } from "@powerledger/ui-component-lib";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Column } from "react-table";

import { SeparatorLayout } from "@/app/components";
import { FilterDisplay } from "@/app/components/filter-display";
import { TableActionLinkButton } from "@/app/components/table-action-buttons";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import { CarbonCreditAuditStatus } from "@/app/types/generated/graphql";

import { CCDepositsViewProps } from "./cc-deposits.types";

export const CCDepositsView: React.FC<CCDepositsViewProps> = ({
  data,
  loading,
  filters,
  filterState,
  handleFiltersChange,
  refetch,
}) => {
  const { t } = useTranslation();

  const renderExpandedComponent = useCallback(
    (data: Record<string, string | number>) => (
      <SeparatorLayout
        data={data}
        contentStyle={{ color: "text", width: "18%" }}
        descriptionStyle={{ color: "text", width: "100%", wordBreak: "break-all" }}
      />
    ),
    [],
  );

  const tableColumns: Column<CCDepositsViewProps["data"]["0"]>[] = useMemo(
    () => [
      {
        Header: t("Project ID"),
        disableSortBy: true,
        accessor: (row) => <TableValueWrapper containerSx={{ width: "80px" }} value={row.carbonCredit.projectId} />,
      },
      {
        Header: t("Location"),
        accessor: (row) => row.carbonCredit.country,
        Cell: ({ value }: { value: string }) => <TableValueWrapper title="Locations" value={value} />,
      },
      {
        Header: t("Vintage"),
        accessor: (row) => row.carbonCredit.vintage,
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value?.toString()} />,
      },
      {
        Header: t("Registry"),
        accessor: (row) => row.carbonCredit.registryName,
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      },
      {
        Header: t("Additional Co-Benefits"),
        disableSortBy: true,
        accessor: (row) => <TableValueWrapper maxContent={2} value={row.carbonCredit?.coBenefits || []} />,
      },
      {
        Header: t("Volume"),
        accessor: (row) => row.carbonCredit?.volume,
        Cell: ({ value }: { value?: number }) => <TableValueWrapper value={volumeFormatter(value ?? 0)} />,
      },
      {
        Header: t("Status"),
        accessor: "status",
        disableSortBy: true,

        Cell: ({ row, value }) => {
          const bg =
            value === CarbonCreditAuditStatus.Rejected
              ? "warning"
              : value === CarbonCreditAuditStatus.Accepted
              ? "positive"
              : "secondaryDark";
          const color = value === CarbonCreditAuditStatus.Pending ? "text" : "background";
          return (
            <>
              <Flex sx={{ gap: 2, minWidth: 120 }}>
                <Flex
                  sx={{
                    background: bg,
                    borderRadius: 4,
                    px: 2,
                    textAlign: "center",
                    width: "min-content",
                    borderWidth: 1,
                    fontWeight: 400,
                    cursor: "text",
                    borderStyle: "solid",
                    borderColor: bg,
                    color: color,
                    alignItems: "center",
                  }}
                >
                  {value}
                </Flex>
                {value === CarbonCreditAuditStatus.Rejected && (
                  <TableActionLinkButton text={t("Resubmit")} linkProps={{ to: `deposit/${row.original.id}` }} />
                )}
              </Flex>
            </>
          );
        },
      },
    ],
    [t],
  );

  return (
    <>
      <Flex
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Heading sx={{ variant: "texts.tracexDefaultHeading" }}>{t("Carbon Credits - Deposit Requests")}</Heading>
        <NavLink to={"deposit/new"}>
          <Button
            sx={{
              px: 4,
              fontSize: 1,
            }}
            as="div"
          >
            {t("Deposit")}
          </Button>
        </NavLink>
      </Flex>
      <FilterDisplay
        filters={filters}
        activeFilters={filterState}
        setActiveFilters={handleFiltersChange}
        loading={loading}
        refetch={refetch}
      />
      <Box
        sx={{
          mt: 3,

          table: {
            "& td": {
              whiteSpace: "unset",
            },
          },
        }}
      >
        <Table
          translation={getTableTranslation(t)}
          columns={tableColumns}
          dataSource={data}
          pagination={false}
          noHover
          loading={loading}
          showScrollArrows
          expandable
          renderExpandedComponent={renderExpandedComponent}
        />
      </Box>
    </>
  );
};
