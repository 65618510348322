import { startCase } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import {
  getNewStateWithAllOrNoneForAKey,
  getOptionForType,
  MultiSelectActions,
} from "@/app/components/local-selects/helpers";
import { useCCOptions } from "@/app/hooks/use-cc-options";
import { RegistryType } from "@/app/lib/format-asset-options";

import { defaultMarketFilterState, MarketFilterKeys, MarketFilterState } from "../../market.types";
import { IClearFilterActions, IClearFilterData, useMarketFilterFn } from "./market-filter.types";

/**
 * If Only Verra is Selected Disable Project Types
 * If Only Gold Standard is Selected Disable Sectoral Scopes
 * @param editingFilters
 * @param value
 * @returns
 */
export const getDisabledFilterKeysBasedOnRegistry = (value: RegistryType): MarketFilterKeys => {
  if (value.includes(RegistryType.VERRA)) return "projectTypes";
  else return "sectoralScopes";
};

export const checkIsEmpty = (fieldObject: object) => {
  return Object.values(fieldObject).every((f) => f.length === 0);
};

export const clearFilter = (data: IClearFilterData, actions: IClearFilterActions) => {
  const { filterType, editingFilters } = data;
  const { resetFilters, setEditingFilters } = actions;
  const currentFilterState = { ...editingFilters };
  filterType?.forEach((type) => {
    currentFilterState[type as MarketFilterKeys] = [];
  });
  if (checkIsEmpty(currentFilterState)) {
    resetFilters();
  } else {
    setEditingFilters(currentFilterState);
  }
};

export const singleAttributeSelections = [
  {
    label: "Vintage",
    filter: "vintage",
  },
  {
    label: "Registry",
    filter: "registry",
  },
  {
    label: "Project Id",
    filter: "projectId",
  },
];

export const joinStrings = (str: string[]) => {
  return str.length > 0
    ? str.length > 1
      ? str.slice(0, -1).join(", ") + " and " + str.slice(-1)
      : str[0]
    : "No filters";
};

export const useMarketFilter: useMarketFilterFn = ({ resetSelectedFilters, applyFilters, resetFilterState }) => {
  const [editingFilters, setEditingFilters] = useState<MarketFilterState>(defaultMarketFilterState);

  const [filtersOpen, setFiltersOpen] = useState(false);

  const resetFilters = useCallback(() => {
    setEditingFilters(defaultMarketFilterState);
    resetSelectedFilters();
  }, [resetSelectedFilters]);

  useEffect(() => {
    resetFilterState && setEditingFilters(defaultMarketFilterState);
  }, [resetFilterState]);

  const clearTypeFilter = useCallback(
    (filterType: string[]) => {
      clearFilter({ filterType, editingFilters }, { resetFilters, setEditingFilters });
    },
    [editingFilters, setEditingFilters, resetFilters],
  );

  const { ccOptions: options } = useCCOptions();

  const handleFilterStateToggle = useMemo(
    () =>
      (filterType: MarketFilterKeys, filterValues?: string[], key = "") => {
        setEditingFilters((editingFilters) => {
          let newState = [...editingFilters[filterType]];
          /** Only Allow one value in singleAttributeSelector */
          if (singleAttributeSelections.some((attrs) => attrs.filter === filterType)) {
            /** If value is present, remove the value */
            if (newState[0] === filterValues?.[0]) {
              newState = [];
            } else newState = filterValues?.[0] ? [filterValues[0]] : [];
          } else {
            const optionForType = getOptionForType(filterType, options) || [];

            if (filterValues?.[0] === MultiSelectActions.AllSelected) {
              newState = getNewStateWithAllOrNoneForAKey(newState, optionForType, true, key);
            } else if (filterValues?.[0] === MultiSelectActions.AllDeselected) {
              newState = getNewStateWithAllOrNoneForAKey(newState, optionForType, false, key);
            } else {
              if (!filterValues?.length) {
                newState = [];
              } else {
                for (const value of filterValues) {
                  if (!newState.includes(value as string)) {
                    newState.push(value);
                  } else {
                    newState = newState.filter((state) => state !== value);
                  }
                }
              }
            }
          }
          return {
            ...editingFilters,
            [filterType]: newState,
          };
        });
      },
    [options],
  );
  const isFilterEmpty = useMemo(() => checkIsEmpty(editingFilters), [editingFilters]);

  useEffect(() => {
    isFilterEmpty && resetFilters();
  }, [isFilterEmpty, resetFilters]);

  const appliedFilters = useMemo(
    () =>
      joinStrings(
        Object.entries(editingFilters)
          .filter((entry) => entry[1].length)
          .map((entry) => startCase(entry[0])),
      ) + " ",
    [editingFilters],
  );

  const handleToggleFilter = useCallback(
    (toggleState?: boolean) => {
      const finalValue = typeof toggleState === "undefined" ? !filtersOpen : toggleState;
      setFiltersOpen(finalValue);
    },
    [filtersOpen],
  );

  return {
    handleToggleFilter,
    appliedFilters,
    filtersOpen,
    handleFilterStateToggle,
    clearTypeFilter,
    options,
    applyFilters,
    editingFilters,
    resetFilters,
    isFilterEmpty,
  };
};
