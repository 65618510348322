import { ApolloError } from "@apollo/client";
import { useState } from "react";

import { AssetAttributeOptions, formatAssetOptions } from "@/app/lib/format-asset-options";
import { useCcOrderAttributesOptionsQuery, useClientCodesQuery } from "@/app/types/generated/graphql";

export const useCCOptions = () => {
  const [formattedCCOptions, setFormattedCCOptions] = useState<AssetAttributeOptions>();

  const { data: clientCodes, loading: clientCodesLoading } = useClientCodesQuery();

  const [accumulatedErrors, setAccumulatedErrors] = useState<ApolloError | Error | undefined>();
  // TODO Skip Query when the account status is something
  const { loading: assetsLoading } = useCcOrderAttributesOptionsQuery({
    skip: !clientCodes?.account?.tenantId,
    variables: {
      tenantId: clientCodes?.account?.tenantId,
    },
    onCompleted: (data) => {
      try {
        const formattedOptions = formatAssetOptions(data?.assets);
        setFormattedCCOptions({
          ...formattedOptions,
          clientCodes:
            clientCodes?.account?.members?.map((member) => ({
              label: member.clientCode || "",
              value: member.clientCode,
            })) || [],
        });
      } catch (error) {
        if (error instanceof Error) {
          setAccumulatedErrors(error);
        }
      }
    },
    onError: (error) => {
      setAccumulatedErrors(error);
    },
  });

  if (accumulatedErrors) {
    throw accumulatedErrors;
  }

  return {
    ccOptions: formattedCCOptions,
    loading: assetsLoading || clientCodesLoading,
    error: accumulatedErrors,
  };
};
