import { Box, Heading } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { ResourceGrid } from "./components";
import { getResources } from "./helpers";
import { UserResourcesProps } from "./user-resources.types";

export const UserResourcesView: React.FC<UserResourcesProps> = ({ isAuthenticated = false, isAdmin }) => {
  const { t } = useTranslation();
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Heading sx={{ variant: "texts.tracexDefaultHeading" }}>{t("User Resources")}</Heading>
      </Box>
      <ResourceGrid
        data={getResources({
          authenticated: isAuthenticated,
          isAdmin,
        })}
      />
    </>
  );
};
