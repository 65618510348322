import { Box, Flex, Heading, Image, Link, LoadingOverlay, Modal, Paragraph } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications-handler";
import { FactorProvider, FactorType, useEnrollMutation } from "@/app/types/generated/graphql";

import ActivateFactorForm from "./activate-factor-form";

const ANDROID_GOOGLE_AUTHENTICATOR_APP =
  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2";
const IOS_GOOGLE_AUTHENTICATOR_APP = "https://apps.apple.com/au/app/google-authenticator/id388497605";

/**
 * TODO: This activate factor modal should change based on the FactorType and FactorProvider
 * currently it is hard coded to only to work for the software TOTP FactorType
 */
export const SetUpFactor = ({
  factorProvider,
  factorType,
  visible,
  name,
  closeModal,
}: {
  name: string;
  factorProvider: FactorProvider;
  factorType: FactorType;
  visible: boolean;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();
  const [enrollFactorMutation, { data, loading }] = useEnrollMutation({
    refetchQueries: ["AccountEnrolledFactor"],
  });

  useMemo(
    async () =>
      enrollFactorMutation({
        variables: {
          enroll: {
            factorType,
            factorProvider,
          },
        },
      }),
    [enrollFactorMutation, factorProvider, factorType],
  );

  return (
    <Modal
      modalMaxWidth={"800px"}
      visible={visible}
      onCancel={() => closeModal()}
      title={t("Set up MFA on {{name}}", { name })}
      subtitle={t("Multi Factor Authentication (MFA) helps protect your account from unauthorized use.")}
    >
      <Box sx={{ p: 3 }}>
        <LoadingOverlay loading={loading} />
        {!loading && (
          <Flex sx={{ flexDirection: ["column-reverse", "row"] }}>
            <Box
              sx={{
                width: "50%",
                margin: "auto",
              }}
            >
              <Box
                sx={{
                  mb: 3,
                  pb: 3,
                }}
              >
                <Heading as="h3" sx={{ mb: 2 }}>
                  {t("Step 1")}
                </Heading>
                <Flex>
                  <Paragraph>
                    {t("Download and install the Google Authenticator app on your ")}
                    <Link sx={{ color: "textLight" }} href={IOS_GOOGLE_AUTHENTICATOR_APP}>
                      {t("iOS")}
                    </Link>
                    {t(" or ")}
                    <Link sx={{ color: "textLight" }} href={ANDROID_GOOGLE_AUTHENTICATOR_APP}>
                      {t("Android")}
                    </Link>
                    {t(" device.")}
                  </Paragraph>
                </Flex>
              </Box>

              <Box
                sx={{
                  mt: 2,
                  mb: 3,
                  pb: 3,
                }}
              >
                <Heading as="h3" sx={{ mb: 2 }}>
                  {t("Step 2")}
                </Heading>

                <Paragraph>
                  {t("Using the app, scan the QR code on this screen, or create a time based key using the key below.")}
                </Paragraph>
              </Box>

              <Box sx={{ mt: 2 }}>
                <Heading as="h3" sx={{ mb: 2 }}>
                  {t("Step 3")}
                </Heading>
                <ActivateFactorForm
                  factorProvider={factorProvider}
                  factorType={factorType}
                  onSubmit={() => {
                    notifications.success({
                      description: t("You have successfully set up Google Authenticator"),
                    });
                    closeModal();
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: "50%",
                margin: "auto",
              }}
            >
              <Flex sx={{ justifyContent: "center", pt: [4, 4, 0] }}>
                <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
                  <Image
                    sx={{
                      width: ["100%", null, "14rem"],
                      height: ["100%", null, "14rem"],
                    }}
                    src={data && data.enrollFactor.qrCodeHref ? data.enrollFactor.qrCodeHref : undefined}
                  />
                  <Flex
                    sx={{
                      flexDirection: "column",
                      mb: 2,
                      alignItems: "center",
                    }}
                  >
                    <div>{t("Set up key: ")}</div>
                    <div>{data && data.enrollFactor.sharedSecret ? data.enrollFactor.sharedSecret : "Unknown"}</div>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Flex>
        )}
      </Box>
    </Modal>
  );
};

export default SetUpFactor;
