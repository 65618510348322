import { useTablePaginationManager } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { emptyCCFilterState, useCCFilters } from "@/app/hooks/use-cc-filters";
import { useCurrencySymbol } from "@/app/hooks/use-currency-symbol";
import { formatDate } from "@/app/lib/format-date";
import { modifyOrderAttributes } from "@/app/lib/order-attributes-modifier";
import { ModalContext } from "@/app/modal-provider/modal-context";

import { useDemoData } from "../../hooks/use-demo-data";
import { useMountIndicator } from "../../hooks/use-mount-indicator";
import {
  AssetType,
  CcOrderAttributes,
  OrderBookSortOrderInput,
  OrderHistoryV2Query,
  OrderPosition,
  OrderStatus,
  useOrderHistoryV2Query,
} from "../../types/generated/graphql";
import { OrderHistoryFilterState, OrderHistoryProps, OrderHistoryTableData } from "./order-history.types";

export const getOrderIsCancellable = (order: OrderHistoryTableData): boolean => {
  switch (order.status) {
    case OrderStatus.Partial:
    case OrderStatus.Open:
    case OrderStatus.PendingPartial:
      return true;
    default:
      return false;
  }
};

export const getOrderHistoryTableData = (data?: OrderHistoryV2Query) => {
  const orderHistoryTableData: OrderHistoryTableData[] = [];
  if (!data?.orderHistoryV2.orders) return orderHistoryTableData;
  for (const order of data.orderHistoryV2.orders) {
    const attributes = order.attributes as CcOrderAttributes;
    const defaultedAttributes = modifyOrderAttributes(attributes, order.position);
    orderHistoryTableData.push({
      ...order,
      attributes: defaultedAttributes,
      expandedData: {
        "Project Name": defaultedAttributes.projectName,
        "Project Type": defaultedAttributes.projectTypes,
        "Vintage From": attributes.vintageFrom
          ? formatDate(attributes.vintageFrom, {
              noTZ: true,
            })
          : defaultedAttributes.vintageFrom,
        "Vintage To": attributes.vintageTo
          ? formatDate(attributes.vintageTo, {
              noTZ: true,
            })
          : defaultedAttributes.vintageTo,
        "Sectoral Scope": defaultedAttributes.sectoralScopes,
        "Sustainable Development Goals (SDGs)": defaultedAttributes.sdgGoals,
      },
    });
  }
  return orderHistoryTableData;
};

export const useOrderHistory = (): OrderHistoryProps => {
  const { mounted } = useMountIndicator();
  const navigate = useNavigate();
  const location = useLocation();

  const [activeFilters, setActiveFilters] = useState<OrderHistoryFilterState>({
    projectId: emptyCCFilterState("Project ID"),
    vintage: emptyCCFilterState("Vintage"),
    location: emptyCCFilterState("Location"),
    registry: emptyCCFilterState("Registry"),
    coBenefits: emptyCCFilterState("Additional Co-Benefits"),
    status: emptyCCFilterState("Status"),
  });

  const { currencySymbol, loading: currencySymbolLoading } = useCurrencySymbol();

  const { showOrderCancelModal } = useContext(ModalContext);

  const [typeFilter, setTypeFilter] = useState(OrderPosition.Bid);

  const { filters: baseFilters, loading: filtersLoading } = useCCFilters();
  const filters = [
    ...(baseFilters.filter((filter) => filter.key !== "clientCode") || []),
    {
      key: "status",
      label: "Status",
      options: [
        emptyCCFilterState("Status"),
        ...Object.values(OrderStatus).map((value) => ({
          value,
          label: startCase(value.toLowerCase()),
        })),
      ],
    },
  ] as OrderHistoryProps["filters"];

  const typeFilterChanged = (value: boolean) => {
    updateTypeFilter(value, "type", setTypeFilter);
    resetPage();
  };

  const search = useMemo(() => new URLSearchParams(location.search), [location]);

  useEffect(() => {
    const searchType = search.get("type");
    if (searchType) setTypeFilter(OrderPosition[searchType as keyof typeof OrderPosition]);
  }, [search, mounted]);

  const { pageInfo, offset, fetchData, sort, resetPage, setTotalItems } = useTablePaginationManager<
    OrderHistoryTableData,
    OrderBookSortOrderInput[]
  >({});

  const isUsingDemoData = useDemoData();
  const { data, loading, refetch, previousData } = useOrderHistoryV2Query({
    fetchPolicy: isUsingDemoData ? "cache-only" : "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      input: {
        offSetPaginationInfo: {
          offset,
          limit: pageInfo.pageSize,
        },
        sortOrderInputs: sort,
        where: {
          assetType: AssetType.Cc,
          position: typeFilter,
          statuses: activeFilters.status ? [activeFilters.status?.value as OrderStatus] : null,
          ccAttributes: {
            projectId: activeFilters.projectId.value || undefined,
            vintage: activeFilters.vintage.value ? Number(activeFilters.vintage.value) : undefined,
            country: activeFilters.location.value || undefined,
            registryName: activeFilters.registry.value ? [activeFilters.registry.value] : undefined,
            coBenefits: activeFilters.coBenefits.value ? [activeFilters.coBenefits.value] : undefined,
          },
        },
      },
    },
    onCompleted(data) {
      setTotalItems(data?.orderHistoryV2?.offsetInfo?.total ?? 0);
    },
  });

  const refetchOrderHistory = () => {
    refetch();
  };

  const tableData = getOrderHistoryTableData(data ?? previousData);

  const initOrderCancellation = useCallback(
    (orderId: string) => {
      showOrderCancelModal({
        orderId,
      });
    },
    [showOrderCancelModal],
  );

  const updateTypeFilter = (value: boolean, filter: string, setState: (stateValue: any) => void) => {
    const result = value ? "Ask" : "Bid";

    search.delete(filter);
    search.append(filter, result);

    navigate({
      pathname: location.pathname,
      search: search.toString(),
    });

    return setState(OrderPosition[result as keyof typeof OrderPosition]);
  };

  const handleFiltersChange: OrderHistoryProps["handleFiltersChange"] = useCallback(
    (args) => {
      setActiveFilters((prev) => ({
        ...prev,
        ...args,
      }));
      resetPage();
    },
    [resetPage],
  );

  return {
    tableData,
    loading: loading || currencySymbolLoading || filtersLoading,
    refetchOrderHistory,
    fetchData,
    handleFiltersChange,
    pageInfo,
    currencySymbol,
    getOrderIsCancellable,
    initOrderCancellation,
    activeFilters,
    filters,
    typeFilter,
    typeFilterChanged,
  };
};
