import { Box, Button, Card, Flex, Heading, LegacySwitch, Table, Text, Tooltip } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { startCase, toLower } from "lodash";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CellProps, Column } from "react-table";

import { FilterDisplay } from "@/app/components/filter-display/filter-display";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { currencyFormatter } from "@/app/lib/currency-formatter";
import { calculateOrderAmounts } from "@/app/lib/order-amounts-calculator";
import { volumeFormatter } from "@/app/lib/volume-formatter";

import { SeparatorLayout } from "../../components";
import { getTableTranslation } from "../../lib/get-translations-for-components";
import { OrderPosition } from "../../types/generated/graphql";
import { OrderHistoryProps, OrderHistoryTableData } from "./order-history.types";

export const OrderHistoryView: FC<OrderHistoryProps> = ({
  tableData,
  currencySymbol,
  loading,
  filters,
  getOrderIsCancellable,
  initOrderCancellation,
  activeFilters,
  handleFiltersChange,
  fetchData,
  pageInfo,
  typeFilter,
  refetchOrderHistory,
  typeFilterChanged,
}) => {
  const { t } = useTranslation();

  const renderExpandedComponent = useCallback(
    (data: OrderHistoryTableData["expandedData"]) => (
      <SeparatorLayout
        data={data}
        contentStyle={{ color: "text", width: "15%" }}
        descriptionStyle={{ color: "text", width: "100%", wordBreak: "break-all" }}
      />
    ),
    [],
  );

  const tableColumns = useMemo(() => {
    const columns: Column<OrderHistoryTableData>[] = [
      {
        Header: t("Order ID"),
        accessor: "orderNumber",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper value={value} containerSx={{ color: "textDarker" }} />
        ),
      },
      {
        Header: t("Project ID"),
        disableSortBy: true,
        accessor: (row) => row.attributes.projectId,
        Cell: ({ value }: { value: number }) => (
          <TableValueWrapper value={value} containerSx={{ color: "textDarker" }} />
        ),
      },
      {
        Header: t("Vintage"),
        accessor: (row) => row.attributes.vintage,
        disableSortBy: true,
        Cell: ({ value }: { value: number }) => <Box sx={{ color: "textDarker" }}> {value}</Box>,
      },
      {
        Header: t("Location"),
        accessor: (row) => row.attributes.country,
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper maxContent={1} title="Locations" value={value} containerSx={{ color: "textDarker" }} />
        ),
      },
      {
        Header: t("Registry"),
        disableSortBy: true,
        accessor: (row) => row.attributes.registryName,
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper value={value} containerSx={{ color: "textDarker" }} />
        ),
      },
      {
        Header: t("Additional Co-Benefits"),
        accessor: (row) => row.attributes.coBenefits,
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper
            value={value}
            title="Additional Co-Benefits"
            maxContent={1}
            containerSx={{ color: "textDarker" }}
          />
        ),
      },
      {
        Header: t("Status"),
        accessor: "status",
        Cell: ({ value, row: { original } }) => (
          <Flex sx={{ color: "textDarker", alignItems: "center" }}>
            {`${t(startCase(toLower(value)))}`}
            {original.statusMessage && (
              <Tooltip
                id={`order-${original.id}-status-tooltip`}
                content={<Text>{t(original.statusMessage)}</Text>}
                sx={{
                  svg: {
                    width: 16,
                    height: 16,
                  },
                }}
              />
            )}
          </Flex>
        ),
      },
      {
        Header: t("Quantity"),
        id: "initialVolume",
        accessor: (row) => row.initialVolume,
        Cell: ({ value }: { value: number }) => (
          <TableValueWrapper value={volumeFormatter(value)} containerSx={{ color: "textDarker" }} />
        ),
      },
      {
        Header: (
          <Box
            sx={{
              margin: "auto",
            }}
          >
            {t("Total")}
          </Box>
        ),
        id: "total",
        disableSortBy: true,
        accessor: (row) =>
          calculateOrderAmounts(currency(row.price.amount, { fromCents: true }).value, row.initialVolume, {
            type: row.position,
          }).getTotal(),
        Cell: ({ value }: { value: string }) => (
          <Box
            sx={{
              textAlign: "right",
              maxWidth: "120px",
              px: 2,
            }}
          >
            {currencyFormatter(currencySymbol, value)}
          </Box>
        ),
      },
    ];
    columns.push({
      Header: t("Action"),
      id: "action",
      Cell: ({ row: { original } }: CellProps<OrderHistoryTableData>) => (
        <Flex sx={{ alignItems: "center" }}>
          {getOrderIsCancellable(original) && (
            <Button
              variant="pill.compactSecondary"
              sx={{ py: 1, mr: 2 }}
              onClick={() => initOrderCancellation(original.id)}
            >
              {t("Withdraw")}
            </Button>
          )}
        </Flex>
      ),
    });
    return columns;
  }, [t, currencySymbol, initOrderCancellation, getOrderIsCancellable]);

  return (
    <>
      <Heading sx={{ variant: "texts.tracexDefaultHeading" }}>{t("Order History")}</Heading>
      <FilterDisplay
        filters={filters}
        activeFilters={activeFilters}
        setActiveFilters={handleFiltersChange}
        loading={loading}
        refetch={refetchOrderHistory}
      />
      <Card variant="compact.dark">
        <Flex
          sx={{
            bg: "secondaryDarker",
            p: 3,
            pb: 4,
            mb: -1,
            mt: 3,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            justifyContent: "flex-end",
            opacity: loading ? 0.5 : 1,
          }}
        >
          <LegacySwitch
            checked={typeFilter === OrderPosition.Ask}
            uncheckedLabel={t("Bought")}
            checkedLabel={t("Sold")}
            onChange={(value: boolean) => {
              !loading && typeFilterChanged(value);
            }}
          />
        </Flex>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          manualPagination
          pageInfo={pageInfo}
          fetchData={fetchData}
          loading={loading}
          showScrollArrows
          expandable
          renderExpandedComponent={renderExpandedComponent}
          translation={getTableTranslation(t)}
          noHover
        />
      </Card>
    </>
  );
};
