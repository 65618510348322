import { Box, Link, Modal, Text } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { getSupportEmail } from "@/app/lib/get-support-email";

export const ContactSupportModal = ({ visible, closeModal }: { visible: boolean; closeModal: () => void }) => {
  const { t } = useTranslation();
  const supportEmail = getSupportEmail();

  return (
    <Modal
      visible={visible}
      maskClosable
      onCancel={closeModal}
      sx={{ maxHeight: "inherit" }}
      title={t("Something went wrong")}
      compact
    >
      <Box sx={{ p: 4 }}>
        <Text sx={{ color: "textDarker", fontWeight: "bold" }}>
          {t(`Sorry, we couldn't complete your request at this time.`)}
        </Text>
        <br />
        <br />
        <Text>{t(`Please contact us here: `)}</Text>
        <Link href={`mailto:${supportEmail}`} sx={{ color: "secondary" }}>
          {supportEmail}
        </Link>
        <Text>{t(`, and one of our team will get back to you shortly.`)}</Text>
      </Box>
    </Modal>
  );
};

export default ContactSupportModal;
