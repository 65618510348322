import { Box, Heading } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { FactorProvider, FactorStatus, FactorType, useAccountEnrolledFactorQuery } from "@/app/types/generated/graphql";

import { FactorSetting } from "./factor-setting";

// TODO: let the back end define this
const supportedFactors = [
  {
    name: "Google Authenticator",
    provider: FactorProvider.Google,
    type: FactorType.TokenSoftwareTotp,
    status: FactorStatus.NotSetup,
  },
];

export const UserSettingsForm = () => {
  const { t } = useTranslation();

  const { data, loading, error } = useAccountEnrolledFactorQuery();

  const enrolledFactors = useMemo(
    () =>
      !loading && !error && data && data.account && data.account.enrolledFactors ? data.account.enrolledFactors : [],
    [data, error, loading],
  );

  const factorSettings = useMemo(
    () =>
      supportedFactors.map((supportedFactor) => ({
        ...supportedFactor,
        ...(enrolledFactors.find(
          (enrolledFactor: any) =>
            enrolledFactor.provider === supportedFactor.provider && enrolledFactor.type === supportedFactor.type,
        ) || {}),
      })),
    [enrolledFactors],
  );

  const mfaSettingsContent = useMemo(() => {
    if (loading) {
      return t("Loading factors...");
    }
    if (factorSettings.length === 0 || error) {
      return t("No factors available");
    }
    return factorSettings.map(({ provider, type, status, name }) => (
      <Box key={`${provider} ${type}`} sx={{ mb: 3, pt: 5 }}>
        <FactorSetting factorProvider={provider} factorType={type} status={status} name={name} />
      </Box>
    ));
  }, [factorSettings, loading, t, error]);

  return (
    <>
      <Heading as="h2">{t("Multifactor Authentication Settings")}</Heading>
      {mfaSettingsContent}
    </>
  );
};

export default UserSettingsForm;
