import { Box, Flex, IconProps, Tabs } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { LoadingOverlayIndicator } from "@/app/components";

import { AccountVerificationStatus } from "./account-verification-status";
import { PaymentServicesTab } from "./payment-services-tab";
import { PersonalDetailsTab } from "./personal-details-tab";
import { PrimaryDetailsTab } from "./primary-details-tab";
import RegistryTab from "./registry-tab/registry-tab";
import { SettingsTabs, SettingsViewProps } from "./settings.types";
import { UserInfo } from "./user-info";

export const SettingsView: React.FC<SettingsViewProps> = ({
  switchTab,
  currentTab,
  loading,
  showAdminTabs,
  error,
  member,
}) => {
  const { t } = useTranslation();
  const settingsTabs = useMemo(() => {
    const tabs: {
      title: string;
      IconComponent?: React.FC<IconProps> | null;
      key: string;
      component: JSX.Element;
    }[] = [{ title: t("User Details"), key: SettingsTabs.details, component: <PersonalDetailsTab /> }];

    return [
      ...tabs,
      {
        title: t("Primary Details"),
        IconComponent: null,
        key: SettingsTabs.personalDetails,
        component: <PrimaryDetailsTab loading={loading} member={member} error={error} />,
      },
      {
        title: t("Payment Details"),
        key: SettingsTabs.paymentServices,
        component: <PaymentServicesTab />,
      },
      {
        title: t("Registry"),
        key: SettingsTabs.registry,
        component: <RegistryTab />,
      },
    ];
  }, [error, loading, member, t]);

  if (loading) return <LoadingOverlayIndicator />;
  return (
    <>
      <Flex sx={{ alignItems: "center", justifyContent: "space-between", mb: 4 }}>
        <UserInfo />
        <Flex sx={{ alignItems: "center" }}>
          <AccountVerificationStatus isAdmin={showAdminTabs} />
        </Flex>
      </Flex>
      <Tabs
        activeKey={currentTab as unknown as string}
        onChange={switchTab}
        sx={{ maxHeight: "inherit", position: "relative" }}
      >
        {settingsTabs.map(({ title, key, IconComponent, component }) => (
          <Tabs.TabPane
            tab={
              <>
                {IconComponent && (
                  <IconComponent
                    size={5}
                    color=""
                    sx={{
                      position: "absolute",
                      top: 0,
                      mt: -3,
                      pointerEvents: "none",
                    }}
                  />
                )}
                <Flex sx={{ alignItems: "center" }}>{title}</Flex>
              </>
            }
            key={key}
          >
            <Box sx={{ p: 5, bg: "foreground", minHeight: 500 }}>{component}</Box>
          </Tabs.TabPane>
        ))}
      </Tabs>
    </>
  );
};
