import { useMemo } from "react";

import { useCurrentTradingHaltQuery } from "@/app/types/generated/graphql";

import { TradingHaltNotifierViewProps } from "./trading-halt-notifier.types";

export const useTradingHaltNotifier = (skip = false): TradingHaltNotifierViewProps => {
  const { data: tradeHaltData, loading: tradeHaltLoading } = useCurrentTradingHaltQuery({
    skip,
  });

  const tradingHaltEndDateTime = useMemo(() => {
    if (tradeHaltLoading || !tradeHaltData?.currentTradingHalt) return null;

    const { endDateTime } = tradeHaltData.currentTradingHalt;
    return endDateTime;
  }, [tradeHaltLoading, tradeHaltData]);

  return { tradingHaltEndDateTime };
};
