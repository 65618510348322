import { useTranslation } from "react-i18next";
import { Flex, Heading } from "theme-ui";

import { SeparatorLayout } from "@/app/components";
import { LoadingErrorLayout } from "@/app/components/loading-error-layout";

import { RegistryTabViewProps } from "./registry-tab.types";

export const RegistryTabView: React.FC<RegistryTabViewProps> = ({ loading, registries, error }) => {
  const { t } = useTranslation();
  return (
    <LoadingErrorLayout loading={loading} error={error}>
      <Flex sx={{ alignItems: "center" }}>
        <Heading as="h2">{t("Carbon Credit Registry Account Details")}</Heading>
      </Flex>
      {registries?.map((registry, index) => (
        <SeparatorLayout
          key={registry.id}
          contentStyle={{
            p: "16px 16px 16px 5px",
            textAlign: "left",
            color: "white",
            width: ["100%", "30%"],
          }}
          wrapperStyle={{
            mt: 5,
            p: 20,
            borderRadius: 8,
            background: "secondaryDarker",
          }}
          descriptionStyle={{
            color: "white",
          }}
          data={{
            [`${index + 1}. Registry`]: registry?.registryName ?? "-",
            "Registry Account Name": registry?.accountName ?? "-",
            "Registry Account Number": registry?.accountNumber ?? "-",
          }}
          showSeparator={false}
        />
      ))}
    </LoadingErrorLayout>
  );
};
