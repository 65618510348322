import {
  Box,
  Flex,
  Input as ThemeInput,
  InputProps as InputPropsBase,
  ThemeUIStyleObject,
} from "@powerledger/ui-component-lib";
import { FC, forwardRef, ForwardRefRenderFunction, ReactNode } from "react";

import { useDebouncedInput } from "@/app/hooks/use-debounced-input";

export type InputProps = {
  prefix?: {
    outside?: boolean;
    component: FC | ReactNode | HTMLElement | string;
  };
  suffix?: {
    outside?: boolean;
    component: FC | ReactNode | HTMLElement | string;
  };
  variant?: string;
  containerSx?: ThemeUIStyleObject;
  debounce?: boolean;
} & Omit<InputPropsBase, "prefix">;

const Component: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    suffix,
    prefix,
    variant = "input",
    onChange,
    name = "",
    onInput,
    placeholder,
    containerSx,
    sx,
    debounce = false,
    ...props
  },
  ref,
) => {
  const { handleFieldChange, localState, handleFieldBlur } = useDebouncedInput({
    onChange,
    name,
    value: (props.value as string) || "",
    onBlur: props.onBlur,
    shouldDebounce: debounce && (Boolean(onChange) || !onInput),
  });
  return (
    <Flex
      sx={{
        alignItems: "center",
        position: "relative",

        ...containerSx,
      }}
    >
      {prefix && (
        <Box
          sx={{
            color: "textDarker",
            fontWeight: "light",
            zIndex: 1,
            ...(prefix?.outside
              ? {
                  mr: 2,
                }
              : {
                  position: "absolute",
                  left: 2,
                }),
          }}
        >
          {prefix?.component}
        </Box>
      )}
      <ThemeInput
        as={ThemeInput}
        name={name}
        variant={variant}
        placeholder={placeholder}
        onBlur={handleFieldBlur}
        sx={{
          pl: prefix ? 4 : 3,
          pr: suffix ? 4 : 3,
          position: "relative",
          ...sx,
        }}
        // No Debouncing onInput for now
        {...(onInput
          ? {
              onInput,
            }
          : {
              onChange: handleFieldChange,
              value: localState,
            })}
        {...props}
        ref={ref}
      />

      {suffix && (
        <Flex
          sx={{
            flexShrink: 0,
            fontWeight: "light",
            ...(suffix?.outside
              ? {
                  ml: 2,
                }
              : {
                  position: "absolute",
                  right: 2,
                }),
          }}
        >
          {suffix?.component}
        </Flex>
      )}
    </Flex>
  );
};

export const Input = forwardRef(Component);
