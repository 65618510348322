import * as Yup from "yup";

/**
 * Validation Schema for LinkedPaymentMethod details
 */
export const linkedPaymentMethodsSchema = Yup.object().shape({
  paymentMethodCode: Yup.string().required("Please select a payment method"),
  termsAccepted: Yup.boolean().oneOf([true], "Please accept the Dwolla and Plaid terms"),
  status: Yup.string().required("Required").notOneOf(["ERROR", "LINK_RETRY"]),
});
