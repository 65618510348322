import { FormikHelpers } from "formik";
import { Dispatch, SetStateAction } from "react";

import { FormattedOption } from "@/app/lib/format-asset-options";
export type BuyFormPrefilledAsset = {
  vintage?: number | null;
  projectTypes?: string[] | null;
  sectoralScopes?: string[] | null;
  sdgGoals?: string[] | null;
  coBenefits?: string[] | null;
  projectId?: string | null;
  instantBuyId?: string;
  unitPrice?: number | null;
  availableVolume?: number | null;
  projectLink?: string | null;
  projectName?: string | null;
  serialNumber?: string | null;
  vintageFrom?: string | null;
  vintageTo?: string | null;
  registryName?: string | null;
  country?: string[] | null;
};

export enum BuyOrderLifeCycle {
  Form = "Form",
  Success = "Success",
}

export type BuyFormValues = BuyFormPrefilledAsset & {
  clientCode?: string | null;
  volume: number;
  unitPrice: number;
};

export type BuyFormOptions = {
  registry: FormattedOption;
  projectTypes: FormattedOption;
  vintage: FormattedOption;
  sectoralScopes: FormattedOption;
  sdgGoals: FormattedOption;
  coBenefits: FormattedOption;
  country: FormattedOption;
  clientCode: FormattedOption;
};

export type BuyFormViewProps = {
  fixedValue: BuyFormValues;
  handleSubmit: (values: BuyFormValues, actions: FormikHelpers<BuyFormValues>) => Promise<void>;
  buyFormOptions: BuyFormOptions;
  currencySymbol: string;
  ccOptionsLoading?: boolean;
  setAddNewProjectId: Dispatch<SetStateAction<boolean>>;

  /**Current Step in Buy Order Lifecycle */
  currentStep: BuyOrderLifeCycle;

  closeModal: () => void;
  accountBalanceLoading?: boolean;
  availableAccountBalance?: number;
};

export type BuyOrderModalProps = {
  closeModal: () => void;
  defaultAsset?: BuyFormPrefilledAsset;
  visible: boolean;
};
export type UseBuyOrderModal = (args: Omit<BuyOrderModalProps, "visible">) => BuyFormViewProps;
