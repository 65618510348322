/** @jsxImportSource theme-ui */
import { Box, Flex, IconLoading, Text, WalletNakedSVG } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { currencyFormatter } from "@/app/lib/currency-formatter";

import { RefreshIconButton } from "../refresh-icon-button";
import { AccountBalanceProps } from "./account-balance.types";

export const AccountBalanceView: React.FC<AccountBalanceProps> = ({
  refetch,
  loading,
  currencySymbol,
  availableAccountBalance,
  currentAccountBalance,
}) => {
  const { t } = useTranslation();

  return (
    <Flex sx={{ gap: 2, fontSize: 1, flexDirection: "row", alignItems: "center" }}>
      <WalletNakedSVG
        sx={{
          "> g > path": {
            stroke: "#00CDDA",
            strokeWidth: 2,
          },
        }}
      />
      <Text>{t("Balance (Available/Current): ")}</Text>
      {loading ? (
        <IconLoading size="small" />
      ) : (
        <Text sx={{ fontWeight: 700 }}>{`${currencyFormatter(
          currencySymbol,
          availableAccountBalance,
        )} / ${currencyFormatter(currencySymbol, currentAccountBalance)}`}</Text>
      )}

      <Box sx={{ mt: 0 }}>
        <RefreshIconButton loading={loading} fetchData={refetch} />
      </Box>
    </Flex>
  );
};
