import { Box, Button, Flex, IconClose, Modal, Text } from "@powerledger/ui-component-lib";
import { lazy, Suspense, useState } from "react";
import { useTranslation } from "react-i18next";

import { LoadingOverlayIndicator } from "@/app/components/loading-overlay-indicator";
import { ReactComponent as ResourcesSVG } from "@/assets/images/resources.svg";
import { TraceCCLogoSmall } from "@/assets/logos/components/tracecc-logo-small";

import { HomePages } from "./home.types";

const UserResources = lazy(() => import("../../user-resources/user-resources"));

export const HomeLayout: React.FC<{ children: React.ReactNode; page?: HomePages }> = ({ children, page }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  return (
    <Flex
      sx={{
        minHeight: "100vh",
        overflow: "hidden",
        background: 'url("/assets/loginBackground.svg")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom center",
        backgroundAttachment: "fixed",
        backgroundSize: ["auto", null, null, null, null, "cover"],
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Flex
        sx={{
          backgroundColor: "rgba(40, 39, 80, 0.6)",
          width: "90%",
          maxWidth: "760px",
          borderRadius: "16px",
          backdropFilter: "blur(6px)",
          border: "1px solid #282750",
          py: 4,
        }}
      >
        <Flex
          sx={{
            flexDirection: "column",
            flex: 1,
            flexBasis: "0px",
            position: "relative",
            justifyContent: "center",
            alignItems: "flex-start",
            borderRight: "1px solid #6B6A8F",
            width: "100%",
            px: 4,
          }}
        >
          <Text sx={{ variant: "subheading", fontSize: [2], fontWeight: 600, mb: 2 }}>
            {t("Powerful trades at your fingertips")}
          </Text>
          <Flex
            sx={{
              pb: 6,
              flexDirection: "column",
              svg: {
                scale: "3",
                ml: "50%",
                mt: "24%",
              },
            }}
          >
            <TraceCCLogoSmall />
            <Box
              sx={{
                mt: "30%",
                fontWeight: 500,
                fontSize: "40px",
              }}
            >
              {t("TraceCC")}
            </Box>
          </Flex>

          <Button
            sx={{
              bg: "transparent",
              color: "text",
              position: "absolute",
              bottom: 0,
              py: 2,
              px: 3,
              boxShadow: "0px 0px 5px white",
              opacity: 0.6,
              "&:enabled:hover, &:enabled:focus": {
                bg: "transparent",
                opacity: 1,
              },
              display: "flex",
              svg: {
                path: {
                  fill: "text",
                },
              },
            }}
            onClick={() => {
              setShowModal(!showModal);
            }}
          >
            <ResourcesSVG />
            <Box sx={{ ml: 2 }}>{t("Resources")}</Box>
          </Button>
        </Flex>
        <Flex
          sx={{
            px: 4,
            py: 4,
            flex: 1,
            flexBasis: "0px",
            flexDirection: "column",
            alignItems: "center",
            /**For Smooth Transitioning Define max and min height based on two pages */
            maxHeight: page === HomePages.login ? 550 : 400,
            minHeight: page === HomePages.login ? 530 : 400,
            overflow: "hidden",
          }}
        >
          {children}
        </Flex>
      </Flex>

      <Modal
        visible={showModal}
        hideCloseButton
        onCancel={() => {
          setShowModal(false);
        }}
        sx={{
          p: 3,
          bg: "rgba(40, 39, 80, 0.6)",
        }}
      >
        <Flex
          sx={{
            position: "absolute",
            right: 0,
            p: 3,
          }}
        >
          <Button
            onClick={() => {
              setShowModal(false);
            }}
            sx={{
              bg: "transparent",
              p: 0,
              "&:enabled:hover, &:enabled:focus": {
                bg: "#b2f0f3",
                opacity: 0.9,
                svg: {
                  path: {
                    fill: "black",
                  },
                },
              },
            }}
          >
            <IconClose size={6} />
          </Button>
        </Flex>
        <Suspense fallback={<LoadingOverlayIndicator />}>
          <UserResources />
        </Suspense>
      </Modal>
    </Flex>
  );
};
