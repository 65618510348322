import { FormikProps } from "formik";
import { MutableRefObject } from "react";

import { HoldingsTableData } from "@/app/pages/cc-holdings";

export type SellFormValues = {
  commonPrice: number;
  values: (HoldingsTableData["0"] & {
    unitPrice: number;
    volume: number;
    reason?: string;
    totalPrice?: number;
  })[];
};

export type SellOrderModalProps = {
  visible: boolean;
  closeModal: () => void;
  selectedCCIds: string[];
};

export enum SellOrderLifeCycle {
  Form = "FORM",
  Summary = "SUMMARY",
  Success = "SUCCESS",
}

export type SellFormProps = {
  handleSubmit: (values: SellFormValues) => void;
  formRef: MutableRefObject<FormikProps<SellFormValues> | null>;
  tableLoading: boolean;
  tableData: any[];
  /** Confirm Sale from Order Summary Page */
  confirmSale: () => Promise<void>;
  /** Goes back to form from Order summary page */
  goToSellForm: () => void;
  currencySymbol: string;
  /** Initial Values for the form */
  initialValues: SellFormValues;
  /** Current Step in Sell Order Life Cycle */
  currentStep: SellOrderLifeCycle;
  /** savedData to store form state */
  savedData?: SellFormValues;
  /** Failed Orders creation to show in table  */
  failedOrders: any[];
  closeModal: () => void;
};

export type UseSellOrderModalFn = (args: { closeModal: () => void; selectedCCIds: string[] }) => SellFormProps;

export type SellOrderSummaryProps = {
  goBack: () => void;
  onConfirm: () => Promise<void>;
  currencySymbol: string;
  data: SellFormValues["values"];
};
