import currency from "currency.js";
import { useTranslation } from "react-i18next";
import { Card, Flex, Text } from "theme-ui";

export const CCTableHeader = ({ loading = false, totalCCs = 0 }) => {
  const { t } = useTranslation();
  if (loading) {
    return <></>;
  }
  return (
    <Card variant="compact.dark" sx={{ mb: -2, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
      <Flex
        sx={{
          p: 3,
          alignItems: "center",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          justifyContent: "space-between",
          bg: "secondaryDarker",
        }}
      >
        <Flex sx={{ alignItems: "center", mr: 4 }}>
          <Text sx={{ color: "textDarker" }}>{t("Total Carbon Credits")}</Text>
          <Text sx={{ ml: 2 }}>
            {currency(totalCCs, {
              separator: ",",
              precision: 0,
              symbol: "",
              useVedic: false,
            }).format()}
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
