import { accountQuery } from "./account";
import { assetsQuery } from "./assets";
import { holdingsQuery } from "./holdings";
import { messagesQuery } from "./messages";
import { tenantsQuery } from "./tenants";

export const typePolicies = {
  typePolicies: {
    Query: {
      fields: {
        holdings: holdingsQuery,
        account: accountQuery,
        assets: assetsQuery,
        messages: messagesQuery,
        tenants: tenantsQuery,
      },
    },
  },
};
