import { t } from "i18next";
import { useCallback, useState } from "react";

import { notifications } from "@/app/container/notifications-handler";
import {
  InboxMessage,
  InboxMessagesDocument,
  InboxMessagesQuery,
  InboxMessagesQueryVariables,
  MessageStatus,
  useInboxMessagesQuery,
  useMarkAsReadMutation,
} from "@/app/types/generated/graphql";

import { NotificationsTabType, UseNotificationsFn } from "./notifications.types";

export function filterMessagesByStatus(notifications: InboxMessagesQuery["messages"]) {
  const allUnread =
    (notifications?.filter((notification) => notification?.status === MessageStatus.Unread) as InboxMessage[]) ?? [];
  const allRead =
    (notifications?.filter((notification) => notification?.status === MessageStatus.Read) as InboxMessage[]) ?? [];
  return { allUnread, allRead };
}

export const useNotifications: UseNotificationsFn = () => {
  const { data: inboxMessages, loading } = useInboxMessagesQuery({
    fetchPolicy: "network-only",
  });
  const filteredNotifications = filterMessagesByStatus(inboxMessages?.messages);
  const [currentTab, setCurrentTab] = useState<NotificationsTabType>(NotificationsTabType.UNREAD);
  const [markAsReadId, setMarkAsReadId] = useState<string>("");

  const [markAsRead] = useMarkAsReadMutation({
    onCompleted(data, clientOptions) {
      clientOptions?.client?.cache.updateQuery<InboxMessagesQuery, InboxMessagesQueryVariables>(
        { query: InboxMessagesDocument },
        (query) => ({
          messages:
            query?.messages?.map((message) => {
              if (message) {
                return message.id === data.markAsRead?.id ? { ...message, status: data.markAsRead.status } : message;
              }
              return message;
            }) ?? [],
        }),
      );
    },
  });

  const markAsReadHandler = useCallback(
    async (id: string) => {
      try {
        setMarkAsReadId(id);
        await markAsRead({
          variables: { markAsReadId: id },
        });
      } catch (e) {
        notifications.error({
          description: t("Something went wrong"),
        });
      } finally {
        setMarkAsReadId("");
      }
    },
    [markAsRead],
  );

  return {
    loading,
    currentTab,
    setCurrentTab,
    filteredNotifications,
    markAsReadHandler,
    markAsReadId,
  };
};
