import { Link } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { getSupportEmail } from "@/app/lib/get-support-email";

import { fallBackErrorMessage } from "./notifications-handler";

export const ContactSupportMailLink = ({ message = "", description = "" }) => {
  const supportEmail = getSupportEmail().toLowerCase();
  const { t } = useTranslation();
  if (!description?.length) {
    description = t(fallBackErrorMessage, { supportEmail });
  }
  const content = `${message} ${description}`;

  const contactIndex = content.indexOf(supportEmail);
  const contentBeforeContactText = content?.substring(0, contactIndex);
  const contentAfterContactText = content?.substring(contactIndex + supportEmail.length);
  return contactIndex < 0 ? (
    <>{content}</>
  ) : (
    <>
      {contentBeforeContactText}
      <Link href={`mailto:${supportEmail}`} sx={{ whiteSpace: "nowrap" }}>
        {supportEmail}
      </Link>
      {contentAfterContactText}
    </>
  );
};
