import { useOktaAuth } from "@okta/okta-react";
import { Box } from "@powerledger/ui-component-lib";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications-handler";

import { ForgotPasswordConfirmation } from "../confirm-email";
import { PublicHeader } from "../header";
import { ForgotPasswordEmailForm } from "./email-form";

export const ForgotPassword = () => {
  const { oktaAuth } = useOktaAuth();
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation();
  const [userId, setUserId] = useState("");

  const sendForgotPasswordCallback = useCallback(
    async (username) => {
      setUserId(username);
      try {
        await oktaAuth.forgotPassword({ factorType: "EMAIL", username });
      } catch (error: unknown) {
        if (error instanceof Error) {
          notifications.error({
            description: t(error?.message),
          });
        }
      } finally {
        setSubmitted(true);
      }
    },
    [oktaAuth, t],
  );

  const renderStep = useMemo(
    () =>
      submitted ? (
        <ForgotPasswordConfirmation userId={userId} goBack={() => setSubmitted(false)} />
      ) : (
        <ForgotPasswordEmailForm onSuccess={sendForgotPasswordCallback} />
      ),
    [sendForgotPasswordCallback, submitted, userId],
  );

  return (
    <Box
      sx={{
        minHeight: "100vh",
        background: 'url("/assets/publicBackground.svg")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <PublicHeader />
      {renderStep}
    </Box>
  );
};
