import { Box, Flex, Header } from "@powerledger/ui-component-lib";
import { FC } from "react";

import { LayoutViewProps } from "./layout.types";

export const LayoutView: FC<LayoutViewProps> = ({
  siderWidth,
  headerHeight,
  children,
  tooltipComponent,
  footer,
  header,
  sider,
}) => {
  return (
    <Box sx={{ height: "100%", paddingLeft: siderWidth }}>
      {tooltipComponent}
      <Flex
        sx={{
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            marginTop: headerHeight,
            flex: 1,
            p: 4,
            pt: 0,
          }}
        >
          {children}
        </Box>
        {footer}
      </Flex>
      <Box
        sx={{
          left: siderWidth,
          right: 0,
          top: 0,
          position: "fixed",
          backgroundColor: "background",
          zIndex: 10,
        }}
      >
        <Header>{header}</Header>
      </Box>
      <Box
        sx={{
          width: siderWidth,
          left: 0,
          top: 0,
          right: "auto",
          height: "100vh",
          position: "fixed",
        }}
      >
        {sider}
      </Box>
    </Box>
  );
};
