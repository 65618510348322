import userGuideImage from "@/assets/images/PL_TraceX_User_Guide_v02.png";

import { USER_GUIDE_URL } from "../../lib/env-helpers";
import { ResourceType } from "./user-resources.types";

export const getResources = (args: { authenticated?: boolean; isAdmin?: boolean }): ResourceType[] => {
  // Sorted alphabetically by `name`
  return [
    {
      url: USER_GUIDE_URL,
      name: "User Guide",
      fileName: "2022_11_18_TraceX_User_Guide.pdf",
      image: userGuideImage,
    },
  ];
};
