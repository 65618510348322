import { LegacySelect } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { components as selectComponents } from "react-select";
import { Flex } from "theme-ui";

import { getSelectTranslation } from "@/app/lib/get-translations-for-components";

import { ProjectIdsSearch } from "../project-ids-search";
import { RefreshIconButton } from "../refresh-icon-button";
import { FilterDisplayProps, FiltersKey, FiltersType } from "./filter-display.types";

export function FilterDisplay<T extends FiltersType, K extends FiltersKey>({
  activeFilters,
  setActiveFilters,
  filters,
  loading,
  refetch,
}: FilterDisplayProps<T, K>) {
  const { t } = useTranslation();
  return (
    <Flex sx={{ alignItems: "center", flexWrap: "wrap", my: 2, gap: 1 }}>
      <ProjectIdsSearch
        sx={{
          width: ["32%", "32%", "32%", "32%", "15%"],
          lineClamp: 2,
        }}
        disabled={loading}
        onFieldChange={(option) =>
          setActiveFilters({
            projectId: option,
          })
        }
        selectedValue={activeFilters.projectId || undefined}
      />
      {filters.map((filter) => (
        <LegacySelect
          key={filter.key}
          disabled={loading}
          value={activeFilters[filter.key]}
          sx={{
            width: ["32%", "32%", "32%", "32%", "15.5%"],
            lineClamp: 2,
          }}
          components={{
            Option: ({ children, ...props }) => (
              // show Any in the option instead of filter name
              <selectComponents.Option {...props}>
                {children?.toString() === filter.label ? "Any" : children}
              </selectComponents.Option>
            ),
          }}
          translation={getSelectTranslation(t)}
          options={filter.options}
          onChange={(option) =>
            setActiveFilters({
              [filter.key]: option,
            })
          }
        />
      ))}
      <Flex
        sx={{
          ml: "auto",
        }}
      >
        <RefreshIconButton loading={loading} fetchData={refetch} />
      </Flex>
    </Flex>
  );
}
