import { Box, Button, Flex, IconLoading, LegacySelect, Text } from "@powerledger/ui-component-lib";
import { Field, Formik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import * as Yup from "yup";

import { Form, FormFieldLabel } from "@/app/components/form";
import { FieldSelectors } from "@/app/hooks";
import { RegistryType } from "@/app/lib/format-asset-options";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";
import { calculateOrderAmounts } from "@/app/lib/order-amounts-calculator";
import { volumeFormatter } from "@/app/lib/volume-formatter";
import { OrderPosition } from "@/app/types/generated/graphql";

import { CleaveInput } from "../../cleave-input";
import { AppCleaveTypes } from "../../cleave-input/get-cleave-options";
import { getNewStateWithAllOrNoneForAKey, MultiSelectActions } from "../../local-selects/helpers";
import { LocalMultiSelect } from "../../local-selects/local-multi-select";
import { LocationSelect } from "../../local-selects/location-select";
import { ProjectIdsSearch } from "../../project-ids-search";
import { PROJECT_ID_CACHE } from "../../project-ids-search/use-project-ids-search";
import Confirmation from "../confirmation";
import { BuyFormViewProps, BuyOrderLifeCycle } from "./buy-order-modal.types";
import BuyOrderSummary from "./buy-order-summary";

export const amountLimitError = "Total order value cannot be greater than available balance";
export const BuyForm: FC<BuyFormViewProps> = ({
  fixedValue,
  handleSubmit,
  buyFormOptions,
  currencySymbol,
  ccOptionsLoading,
  closeModal,
  currentStep,
  accountBalanceLoading,
  setAddNewProjectId,
  availableAccountBalance = 0,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  if (currentStep === BuyOrderLifeCycle.Success) {
    return (
      <Confirmation
        currencySymbol={currencySymbol}
        text={t("The order request has been submitted.")}
        action={{
          content: t("View your order here"),
          onClick: () => {
            navigate("/orders?type=Bid");
            closeModal();
          },
        }}
      />
    );
  }
  return (
    <Box sx={{ minHeight: "200px" }}>
      <Formik
        initialValues={fixedValue}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={Yup.object().shape({
          registryName: Yup.string().nullable(),
          vintage: Yup.string().nullable(),
          projectTypes: Yup.array().of(Yup.string()).nullable(),
          projectId: Yup.string().nullable(),
          country: Yup.array().nullable(),
          sectoralScopes: Yup.array().of(Yup.string()).nullable(),
          coBenefits: Yup.array().of(Yup.string()).nullable(),
          sdgGoals: Yup.array().of(Yup.string()).nullable(),
          clientCode: Yup.string().required("Required"),
          unitPrice: Yup.number()
            .moreThan(0.1, t("Unit Price should be at least $0.15"))
            .hasTickSize(
              0.05,
              t("Unit Price can only be in 5 cent multiples i.e. $0.15, $0.20, $1.25, but not $0.12 or $0.53"),
            ),
          volume: Yup.number()
            .min(10, t("Quantity should be at least 10"))
            .lessThan(
              fixedValue.availableVolume ? fixedValue.availableVolume + 1 : Number.MAX_VALUE,
              t("Quantity should be less than available volume"),
            ),
        })}
        validateOnMount
      >
        {({ handleSubmit, setFieldValue, isSubmitting, values, errors, touched, isValid, setFieldTouched }) => {
          const calculatedAmount = calculateOrderAmounts(values.unitPrice, values.volume, { type: OrderPosition.Bid });

          const multiOptionValues = {
            registryName: buyFormOptions?.registry.find((o) => values.registryName === o.value),
            projectTypes: buyFormOptions?.projectTypes.filter((scope) => values.projectTypes?.includes(scope.value)),
            vintage: buyFormOptions?.vintage.find((o) => values.vintage?.toString() === o.value?.toString()),
            clientCode: buyFormOptions?.clientCode?.find((scope) => scope.value === values.clientCode),
            sectoralScopes: buyFormOptions?.sectoralScopes.filter((scope) =>
              values.sectoralScopes?.includes(scope.value),
            ),
            country: buyFormOptions?.country.flatMap(
              (o) => o.options?.filter((so) => values.country?.includes(so.value)) || [],
            ),
            coBenefits: buyFormOptions?.coBenefits.filter((scope) => values.coBenefits?.includes(scope.value)),
            sdgGoals: buyFormOptions?.sdgGoals.filter((scope) => values.sdgGoals?.includes(scope.value)),
          };

          const isSelectorDisabled = !!fixedValue.instantBuyId || ccOptionsLoading;

          return (
            <Form onSubmit={handleSubmit}>
              <Box
                sx={{
                  pt: 5,
                  pb: 4,
                  px: 5,
                }}
              >
                <Flex>
                  <Flex
                    sx={{
                      pt: 3,
                      pb: 4,
                      justifyContent: "space-between",
                      flexDirection: "column",
                      width: "50%",
                      mr: 4,
                    }}
                  >
                    <Text sx={{ fontSize: 4, mb: 3 }}>{t("Product Attributes")}</Text>
                    <Form.Item key="buy-cc-registry-name">
                      <FormFieldLabel
                        hasErrorMessage={false}
                        small
                        name="registryName"
                        label={t("Registry")}
                        sx={{
                          ...(errors.registryName && touched.registryName ? FieldSelectors["select"] : {}),
                        }}
                      >
                        <LegacySelect
                          onBlur={() => setFieldTouched("registryName", true)}
                          translation={getSelectTranslation(t)}
                          value={multiOptionValues?.registryName}
                          disabled={isSelectorDisabled}
                          options={buyFormOptions?.registry}
                          onChange={async (option) => {
                            await setFieldValue("registryName", option?.value);
                            await setFieldValue(
                              option?.value === RegistryType.VERRA ? "projectTypes" : "sectoralScopes",
                              null,
                            );
                          }}
                        />
                      </FormFieldLabel>
                    </Form.Item>

                    <Form.Item>
                      <FormFieldLabel
                        hasErrorMessage={false}
                        small
                        name="vintage"
                        label={t("Vintage")}
                        sx={{
                          ...(errors.vintage && touched.vintage ? FieldSelectors["select"] : {}),
                        }}
                      >
                        <LegacySelect
                          onBlur={() => setFieldTouched("vintage", true)}
                          translation={getSelectTranslation(t)}
                          value={multiOptionValues?.vintage}
                          disabled={isSelectorDisabled}
                          options={buyFormOptions?.vintage}
                          onChange={async (option) => {
                            await setFieldValue("vintage", option?.value);
                          }}
                        />
                      </FormFieldLabel>
                    </Form.Item>
                    <Form.Item>
                      <FormFieldLabel small name="country" label={t("Locations")}>
                        <LocationSelect
                          isLoading={ccOptionsLoading}
                          isMulti
                          maxStateChipsLimit={2}
                          chipsLimit={2}
                          closeMenuOnSelect={false}
                          value={multiOptionValues?.country}
                          options={buyFormOptions?.country}
                          disabled={isSelectorDisabled}
                          placeholder={t("Any")}
                          maxMenuHeight={275}
                          onChange={(options) => {
                            const deselectAll = options.find((opt) => opt.value === MultiSelectActions.AllDeselected);
                            const selectAll = options.find((opt) => opt.value === MultiSelectActions.AllSelected);

                            const currValues = multiOptionValues.country.map((m) => m.value);
                            if (selectAll) {
                              const newState = getNewStateWithAllOrNoneForAKey(
                                currValues,
                                buyFormOptions.country,
                                true,
                                selectAll.key,
                              );
                              setFieldValue("country", newState);
                            } else if (deselectAll) {
                              const newState = getNewStateWithAllOrNoneForAKey(
                                currValues,
                                buyFormOptions.country,
                                false,
                                deselectAll.key,
                              );
                              setFieldValue("country", newState);
                            } else {
                              setFieldValue(
                                "country",
                                options.map((o) => o.value),
                              );
                            }
                          }}
                        />
                      </FormFieldLabel>
                    </Form.Item>
                    <Flex
                      sx={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <Form.Item key="buy-cc-project-id" sx={{ width: "50%", mr: 2 }}>
                        <FormFieldLabel small name="projectId" label={t("Project ID")}>
                          <ProjectIdsSearch
                            addNewFeature
                            disabled={isSelectorDisabled}
                            onFieldChange={(option) => {
                              if (option.key === PROJECT_ID_CACHE) {
                                setAddNewProjectId(true);
                              } else {
                                setAddNewProjectId(false);
                              }
                              setFieldValue("projectId", option.value);
                            }}
                            selectedValue={
                              values.projectId ? { label: values.projectId, value: values.projectId } : undefined
                            }
                            emptyOptionLabel="Any"
                          />
                        </FormFieldLabel>
                      </Form.Item>
                      <Form.Item key="buy-cc-project-type" sx={{ width: "50%", ml: 2 }}>
                        <FormFieldLabel
                          hasErrorMessage={false}
                          small
                          name="projectTypes"
                          label={t(`Project Type`)}
                          sx={{
                            ...(errors.projectTypes && touched.projectTypes ? FieldSelectors["select"] : {}),
                          }}
                        >
                          <LocalMultiSelect
                            isMulti
                            chipsLimit={1}
                            onBlur={() => setFieldTouched("projectTypes", true)}
                            disabled={values?.registryName === RegistryType.VERRA || isSelectorDisabled}
                            value={multiOptionValues?.projectTypes}
                            options={buyFormOptions?.projectTypes}
                            placeholder={t("Any")}
                            closeMenuOnSelect={false}
                            translation={getSelectTranslation(t)}
                            onChange={(option) => {
                              setFieldValue(
                                "projectTypes",
                                option.map((opt) => opt.value),
                              );
                            }}
                          />
                        </FormFieldLabel>
                      </Form.Item>
                    </Flex>
                    <Form.Item key="buy-cc-sectoral-scope">
                      <FormFieldLabel small name="sectoralScopes" label={t(`Sectoral Scope`)}>
                        <LocalMultiSelect
                          translation={getSelectTranslation(t)}
                          isMulti
                          closeMenuOnSelect={false}
                          chipsLimit={1}
                          placeholder={t("Any")}
                          disabled={values?.registryName === RegistryType.GOLD_STANDARD || isSelectorDisabled}
                          value={multiOptionValues?.sectoralScopes}
                          options={buyFormOptions?.sectoralScopes}
                          maxMenuHeight={275}
                          onChange={(option) => {
                            setFieldValue(
                              "sectoralScopes",
                              option.map((opt) => opt.value),
                            );
                            setFieldTouched("sectoralScopes", true, true);
                          }}
                        />
                      </FormFieldLabel>
                    </Form.Item>
                    <Form.Item key="buy-cc-co-benefits">
                      <FormFieldLabel
                        hasErrorMessage={false}
                        small
                        name="coBenefits"
                        label={t("Additional Co-Benefits")}
                      >
                        <LocalMultiSelect
                          isMulti
                          chipsLimit={2}
                          onBlur={() => setFieldTouched("coBenefits", true)}
                          value={multiOptionValues?.coBenefits}
                          options={buyFormOptions?.coBenefits}
                          disabled={isSelectorDisabled}
                          placeholder={t("Any")}
                          closeMenuOnSelect={false}
                          translation={getSelectTranslation(t)}
                          onChange={(option) => {
                            setFieldValue(
                              "coBenefits",
                              option.map((opt) => opt.value),
                            );
                          }}
                        />
                      </FormFieldLabel>
                    </Form.Item>
                    <Form.Item key="buy-cc-sdg-goal">
                      <FormFieldLabel
                        hasErrorMessage={false}
                        small
                        name="sdgGoals"
                        label={t("Sustainable Development Goals (SDGs)")}
                        sx={{
                          ...(errors.sdgGoals && touched.sdgGoals ? FieldSelectors["select"] : {}),
                        }}
                      >
                        <LocalMultiSelect
                          isMulti
                          chipsLimit={1}
                          onBlur={() => setFieldTouched("sdgGoals", true)}
                          value={multiOptionValues?.sdgGoals}
                          options={buyFormOptions?.sdgGoals}
                          placeholder={t("Any")}
                          closeMenuOnSelect={false}
                          disabled={isSelectorDisabled}
                          translation={getSelectTranslation(t)}
                          onChange={(option) => {
                            setFieldValue(
                              "sdgGoals",
                              option.map((opt) => opt.value),
                            );
                          }}
                        />
                      </FormFieldLabel>
                    </Form.Item>
                  </Flex>

                  <Flex
                    sx={{
                      pt: 3,
                      pb: 4,
                      flexDirection: "column",
                      width: "50%",
                      ml: 4,
                    }}
                  >
                    <Flex
                      sx={{
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <Form.Item sx={{ width: "50%", mr: 2 }}>
                        <FormFieldLabel
                          small
                          name="volume"
                          hasErrorMessage={errors.volume !== amountLimitError}
                          label={t("Product Quantity *")}
                        >
                          <Field
                            name="volume"
                            validate={() =>
                              availableAccountBalance < calculatedAmount.getTotal() ? amountLimitError : ""
                            }
                            sx={{
                              input: {
                                fontSize: 2,
                              },
                            }}
                            {...(values.availableVolume
                              ? {
                                  suffix: `/${volumeFormatter(values.availableVolume)}`,
                                }
                              : {})}
                            type={AppCleaveTypes.Quantity}
                            component={CleaveInput}
                          />
                        </FormFieldLabel>
                      </Form.Item>
                      <Form.Item sx={{ width: "50%", ml: 2 }}>
                        <FormFieldLabel
                          small
                          name="unitPrice"
                          hasErrorMessage={errors.unitPrice !== amountLimitError}
                          label={t("Bid Price per Unit *")}
                        >
                          <Field
                            validate={() =>
                              availableAccountBalance < calculatedAmount.getTotal() ? amountLimitError : ""
                            }
                            name={"unitPrice"}
                            prefix={currencySymbol}
                            sx={{
                              input: {
                                fontSize: 2,
                              },
                            }}
                            disabled={fixedValue.instantBuyId}
                            type={AppCleaveTypes.Amount}
                            component={CleaveInput}
                          />
                        </FormFieldLabel>
                      </Form.Item>
                    </Flex>
                    <Form.Item key="buy-cc-client-id">
                      <FormFieldLabel
                        hasErrorMessage={false}
                        small
                        name="clientCode"
                        label={t("Client Code *")}
                        sx={{
                          ...(errors.clientCode && touched.clientCode ? FieldSelectors["select"] : {}),
                        }}
                      >
                        <LegacySelect
                          onBlur={() => setFieldTouched("clientCode", true)}
                          translation={getSelectTranslation(t)}
                          value={multiOptionValues?.clientCode}
                          options={buyFormOptions?.clientCode}
                          onChange={async (option) => {
                            await setFieldValue("clientCode", option?.value);
                          }}
                        />
                      </FormFieldLabel>
                    </Form.Item>

                    <Flex sx={{ flexDirection: "column", fontVariantNumeric: "tabular-nums" }}>
                      <BuyOrderSummary
                        currencySymbol={currencySymbol}
                        title={"Your Bid Order"}
                        unitPrice={values.unitPrice}
                        volume={values.volume}
                        calculatedAmount={calculatedAmount}
                      />
                    </Flex>

                    {errors.unitPrice === amountLimitError && (
                      <Box
                        sx={{
                          mt: 4,
                          color: "warning",
                          textAlign: "right",
                          fontSize: 1,
                        }}
                      >
                        {t(amountLimitError)}
                      </Box>
                    )}

                    <Flex
                      sx={{
                        justifyContent: "flex-end",
                        alignItems: "center",
                        mt: 2,
                        fontFamily: "MintGroteskV08",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="pill.compact"
                        sx={{ px: 4 }}
                        disabled={isSubmitting || !isValid || accountBalanceLoading}
                      >
                        {t("Place Order")}
                      </Button>
                      {isSubmitting && <IconLoading sx={{ ml: 2 }} size="small" />}
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
