import { ApolloError } from "@apollo/client";

import { demoAccount } from "../../app/container/apollo-client/demo-data";
import { FewAccountDetailsDocument } from "../../app/types/generated/graphql";

export const mockFewAccountDetails = {
  request: {
    query: FewAccountDetailsDocument,
  },
  result: {
    data: {
      account: {
        id: demoAccount.id,
        tenantId: demoAccount.tenantId,
        termsAcceptedDate: demoAccount.termsAcceptedDate,
        rulesAcceptedDate: demoAccount.rulesAcceptedDate,
        privacyPolicyAcceptedDate: demoAccount.privacyPolicyAcceptedDate,
        status: demoAccount.status,
        firstName: demoAccount.firstName,
        lastName: demoAccount.lastName,
        email: demoAccount.email,
      },
    },
  },
};

export const mockFewAccountDetails_Failure = {
  request: {
    query: FewAccountDetailsDocument,
  },
  error: new ApolloError({
    errorMessage:
      "Failed to load account details, please refresh the page and try again. If issue persists, please contact support.",
  }),
};
