import currency from "currency.js";
import { FormikHelpers } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications-handler";
import { emptyCCFilterState } from "@/app/hooks/use-cc-filters";
import { useCCOptions } from "@/app/hooks/use-cc-options";
import { useCurrencySymbol } from "@/app/hooks/use-currency-symbol";
import {
  MarketOrdersV2Document,
  OrderHistoryV2Document,
  OrderPosition,
  OrderType,
  ProjectIdsDocument,
  useAddProjectIdMutation,
  useCreateCcOrderMutation,
  useCreateInstantCcBuyOrderMutation,
  UserIcxAccountBalanceDocument,
  useUserIcxAccountBalanceQuery,
} from "@/app/types/generated/graphql";

import { clearProjectIdCache } from "../../project-ids-search/use-project-ids-search";
import {
  BuyFormOptions,
  BuyFormPrefilledAsset,
  BuyFormValues,
  BuyOrderLifeCycle,
  UseBuyOrderModal,
} from "./buy-order-modal.types";

export const useBuyOrderModal: UseBuyOrderModal = ({ closeModal, defaultAsset }) => {
  const { t } = useTranslation();
  const { ccOptions, loading: ccOptionsLoading } = useCCOptions();
  const [addNewProjectId, setAddNewProjectId] = useState(false);

  const [currentStep, setCurrentStep] = useState(BuyOrderLifeCycle.Form);

  const { currencySymbol, currencyCode, loading: currencySymbolLoading } = useCurrencySymbol();
  const { data: accountBalanceData, loading: accountBalanceLoading } = useUserIcxAccountBalanceQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    onError: () => {
      notifications.error({
        description: t("Failed to fetch available account balance"),
      });
    },
  });

  const [createCCOrder] = useCreateCcOrderMutation({
    refetchQueries: [OrderHistoryV2Document, UserIcxAccountBalanceDocument],
  });
  const [addProjectId] = useAddProjectIdMutation({
    refetchQueries: [ProjectIdsDocument],
  });
  const [createInstantCCBuyOrder] = useCreateInstantCcBuyOrderMutation({
    refetchQueries: [MarketOrdersV2Document, OrderHistoryV2Document, UserIcxAccountBalanceDocument],
  });

  const availableAccountBalance = accountBalanceData?.userIcxAccountBalance.availableBalance;

  const handleSubmit = useCallback(
    async (values: BuyFormValues, actions: FormikHelpers<BuyFormValues>) => {
      const mutate = values.instantBuyId ? createInstantCCBuyOrder : createCCOrder;

      try {
        await mutate({
          variables: {
            sellOrderId: values.instantBuyId,
            order: {
              position: OrderPosition.Bid,
              price: {
                amount: currency(values.unitPrice).intValue.toString(),
                currencyCode,
              },
              type: OrderType.Market,
              volume: Number(values.volume),
            },
            attributes: {
              clientCode: values.clientCode,
              coBenefits: values.coBenefits,
              country: values.country,
              projectId: values.projectId,
              projectTypes: values.projectTypes,
              sdgGoals: values.sdgGoals,
              vintage: values.vintage && Number(values.vintage),
              registryName: values.registryName,
              projectLink: values.projectLink,
              projectName: values.projectName,
              serialNumber: values.serialNumber,
              vintageFrom: values.vintageFrom,
              vintageTo: values.vintageTo,
            },
          },
        });
        if (addNewProjectId && values.projectId) {
          await addProjectId({
            variables: {
              input: {
                projectId: values.projectId,
              },
            },
          });
          clearProjectIdCache();
        }
        setCurrentStep(BuyOrderLifeCycle.Success);
      } catch (e) {
        e instanceof Error &&
          notifications.error({
            description: t(e?.message),
          });
      } finally {
        actions.setSubmitting(false);
      }
    },
    [createCCOrder, addProjectId, addNewProjectId, createInstantCCBuyOrder, currencyCode, t],
  );

  const [prefilledAsset, setPrefilledAsset] = useState<BuyFormPrefilledAsset>();

  useEffect(() => {
    if (defaultAsset) setPrefilledAsset(defaultAsset);
  }, [defaultAsset]);

  const buyFormOptions: BuyFormOptions = useMemo(
    () => ({
      registry: [emptyCCFilterState("Any"), ...(ccOptions?.registry || [])],
      vintage: [emptyCCFilterState("Any"), ...(ccOptions?.vintage || [])],
      country: ccOptions?.country || [],
      projectTypes: ccOptions?.projectTypes || [],
      sectoralScopes: ccOptions?.sectoralScopes || [],
      sdgGoals: ccOptions?.sdgGoals || [],
      coBenefits: ccOptions?.additionalCoBenefits || [],
      clientCode: ccOptions?.clientCodes || [],
    }),
    [ccOptions],
  );

  const fixedValue: BuyFormValues = useMemo(
    () => ({
      instantBuyId: prefilledAsset?.instantBuyId,
      registryName: prefilledAsset?.registryName ?? null,
      vintage: prefilledAsset?.vintage ?? null,
      country: prefilledAsset?.country?.length ? prefilledAsset.country : null,
      projectTypes: prefilledAsset?.projectTypes?.length ? prefilledAsset.projectTypes : null,
      sectoralScopes: prefilledAsset?.sectoralScopes?.length ? prefilledAsset.sectoralScopes : null,
      sdgGoals: prefilledAsset?.sdgGoals?.length ? prefilledAsset.sdgGoals : null,
      coBenefits: prefilledAsset?.coBenefits?.length ? prefilledAsset.coBenefits : null,
      projectId: prefilledAsset?.projectId,
      projectLink: prefilledAsset?.projectLink || null,
      projectName: prefilledAsset?.projectName || null,
      serialNumber: prefilledAsset?.serialNumber || null,
      vintageFrom: prefilledAsset?.vintageFrom || null,
      vintageTo: prefilledAsset?.vintageTo || null,
      volume: 0,
      availableVolume: prefilledAsset?.availableVolume ?? 0,
      unitPrice: prefilledAsset?.unitPrice ? currency(prefilledAsset?.unitPrice, { fromCents: true }).value : 0,
    }),
    [prefilledAsset],
  );

  return {
    fixedValue,
    handleSubmit,
    buyFormOptions,
    currencySymbol,
    closeModal,
    currentStep,
    ccOptionsLoading,
    currencySymbolLoading,
    accountBalanceLoading,
    availableAccountBalance,
    setAddNewProjectId,
  };
};
