import { PageInfo, TableFetchData } from "@powerledger/ui-component-lib";

import { SeparatorLayoutProps } from "@/app/components/separator-layout/separator-layout.types";
import { BaseCCPageFilterKeys } from "@/app/hooks/use-cc-filters";
import { AssetAttributeOptions } from "@/app/lib/format-asset-options";

import {
  AssetType,
  CcOrderAttributes,
  Order,
  OrderPosition,
  OrderStatus,
  OrderType,
} from "../../types/generated/graphql";

export type MarketFilterKeys =
  | Exclude<BaseCCPageFilterKeys, "clientCode">
  | "projectId"
  | "projectTypes"
  | "sectoralScopes"
  | "sdgGoals";

export type MarketFilterState = Record<MarketFilterKeys, string[]>;

export type TypeFilter = Record<OrderPosition, boolean>;

export const defaultTypeFilterState = {
  [OrderPosition.Ask]: true,
  [OrderPosition.Bid]: true,
};
export type AssetCount = {
  bid: number;
  ask: number;
};

export const defaultMarketFilterState: MarketFilterState = {
  projectId: [],
  vintage: [],
  location: [],
  registry: [],
  coBenefits: [],
  sectoralScopes: [],
  sdgGoals: [],
  projectTypes: [],
};

export type MarketViewProps = {
  typeFilter: OrderPosition | null;
  handleChangeTypeFilter: (value: OrderPosition | null) => void;
  ccOptions?: AssetAttributeOptions;
  assetCounts?: AssetCount;
  hideSellButton?: boolean;
  reloadMarketData: () => void;
  fetchData: TableFetchData<MarketTableData>;
  pageInfo: PageInfo;
  tableData: MarketTableData[];
  hideBuyButton?: boolean;
  resetFilterState?: boolean;
  applyFilters: (values: MarketFilterState) => void;
  resetSelectedFilters: () => void;
  loading?: boolean;
  selectedFilters: MarketFilterState;
  currencySymbol: string;
};

export type TableOrderType = {
  id: string;
  assetType: AssetType;
  position: OrderPosition;
  type: OrderType;
  status: OrderStatus;
  initialVolume: number;
  currentVolume: number;
  unitPrice: string;
  vintage: number;
  eligibilities: string[];
  locations: string[];
  fuelSources: string[];
};
export type MarketTableData = Omit<Order, "attributes" | "type"> & {
  attributes: CcOrderAttributes & { sdgCount: number };
} & {
  expandedData: SeparatorLayoutProps["data"];
};
