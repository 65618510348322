import * as Yup from "yup";

Yup.addMethod<Yup.StringSchema>(Yup.string, "hasMaxLengthAndNoSpecialChars", function (maxLength = 50) {
  return this.max(maxLength, "Value is too Long").test(
    "Special Character Test",
    "No special characters allowed",
    (value) => !new RegExp(/[“”"<>="`!?%~${}\][\\]/gm).test(value || ""),
  );
});

Yup.addMethod<Yup.NumberSchema>(Yup.number, "hasTickSize", function (tickSize = 0.05, message = "") {
  return this.test("Tick Size Test", message ?? `Minimum tick size should be ${tickSize}`, (value) => {
    if (!value) return false;
    return Number.isInteger(Number((value / tickSize).toFixed(2)));
  });
});
