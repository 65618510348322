import { useCallback, useState } from "react";
import { Box } from "theme-ui";

import { HomePages } from "./home.types";
import { HomeLayout } from "./home-layout";
import { Login } from "./login/login";
import { Signup } from "./signup/signup";

export const Home = () => {
  const [page, setPage] = useState<HomePages>(HomePages.login);
  const goToSignup = useCallback(() => {
    setPage(HomePages.signup);
  }, []);

  const goToLogin = useCallback(() => {
    setPage(HomePages.login);
  }, []);

  return (
    <HomeLayout page={page}>
      <Box
        aria-label="home-wrapper"
        sx={{
          width: "100%",
          mt: page === HomePages.signup ? -500 : 0,
          transition: "margin 0.3s ease-in-out",
        }}
      >
        {page === HomePages.login && <Login goToSignup={goToSignup} page={page} />}
        {page === HomePages.signup && (
          <Box
            sx={{
              mt: 500,
            }}
          >
            <Signup goToLogin={goToLogin} page={page} />
          </Box>
        )}
      </Box>
    </HomeLayout>
  );
};
