import { LegacySelect } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { components as selectComponents } from "react-select";

import { emptyCCFilterState } from "@/app/hooks/use-cc-filters";

import { getSelectTranslation } from "../../lib/get-translations-for-components";
import { ProjectIdsSearchProps } from "./project-ids-search.types";
import { addToStorageProjectIDCache, PROJECT_ID_CACHE, useProjectIdsSearch } from "./use-project-ids-search";

export const ProjectIdsSearch: FC<ProjectIdsSearchProps> = ({
  onFieldChange,
  selectedValue,
  onBlur,
  disabled,
  emptyOptionLabel = "Project ID",
  addNewFeature = false,
  ...props
}) => {
  const { cachedData, getMoreData, onCustomSearch, baseData } = useProjectIdsSearch({
    addNewFeature,
  });

  const { t } = useTranslation();

  return (
    <LegacySelect
      translation={getSelectTranslation(t)}
      value={selectedValue}
      placeholder={t(emptyOptionLabel)}
      options={[emptyCCFilterState(emptyOptionLabel), ...cachedData]}
      isPaginated
      getMoreData={getMoreData}
      disabled={disabled}
      hasMoreData={cachedData?.length < (baseData?.length || 0)}
      onChange={(option) => {
        if (option) {
          if (option.key === PROJECT_ID_CACHE) {
            addToStorageProjectIDCache(option.value);
            onFieldChange({
              ...option,
              label: option.value,
            });
          } else {
            onFieldChange(option);
          }
        }
      }}
      onInputChange={(text) => {
        text = text.substring(0, 10);
        onCustomSearch(text);
        return text;
      }}
      onBlur={onBlur}
      components={{
        Option: ({ children, ...props }) => (
          // show Any in the option instead of filter name
          <selectComponents.Option {...props}>
            {children?.toString() === emptyOptionLabel ? "Any" : children}
          </selectComponents.Option>
        ),
      }}
      {...props}
    />
  );
};
