import { IconProps, IconWrapper } from "@powerledger/ui-component-lib";

import { CCWalletSVG } from "../vectors";

export const IconCCWallet: React.FC<IconProps> = (props) => (
  <IconWrapper
    icon={CCWalletSVG}
    sx={{
      "svg path": {
        fill: "transparent !important",
      },
      svg: {
        width: "100%",
        height: "100%",
      },
    }}
    {...props}
  />
);
