import { useCallback, useContext, useState } from "react";

import { emptyCCFilterState, useCCFilters } from "@/app/hooks/use-cc-filters";

import { ModalContext } from "../../modal-provider/modal-context";
import { CcAssetAttributes, useCcHoldingsQuery } from "../../types/generated/graphql";
import { CCHoldingsFiltersState, CCHoldingsViewProps } from "./cc-holdings.types";

export const useCCHoldings = (): CCHoldingsViewProps => {
  const [selected, setSelected] = useState<string[]>([]);

  const [activeFilters, setActiveFilters] = useState<CCHoldingsFiltersState>({
    projectId: emptyCCFilterState("Project ID"),
    clientCode: emptyCCFilterState("Client Code"),
    vintage: emptyCCFilterState("Vintage"),
    location: emptyCCFilterState("Location"),
    registry: emptyCCFilterState("Registry"),
    coBenefits: emptyCCFilterState("Additional Co-Benefits"),
  });

  const { showSellOrderModal } = useContext(ModalContext);

  const {
    data: holdingsData,
    loading,
    refetch,
  } = useCcHoldingsQuery({
    variables: {
      where: {
        projectId: activeFilters.projectId.value || undefined,
        vintages: activeFilters.vintage.value ? [activeFilters.vintage.value] : undefined,
        countries: activeFilters.location.value ? [activeFilters.location.value] : undefined,
        clientCodes: activeFilters.clientCode.value ? [activeFilters.clientCode.value] : undefined,
        registries: activeFilters.registry.value ? [activeFilters.registry.value] : undefined,
        coBenefits: activeFilters.coBenefits.value ? [activeFilters.coBenefits.value] : undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { filters, loading: filtersLoading } = useCCFilters();

  const holdingsTableData = (holdingsData?.ccHoldings || []).map((cc) => {
    const attributes = cc.attributes as CcAssetAttributes;
    return {
      ...cc,
      attributes,
      expandedData: {
        "Project Name": attributes.projectName,
        "Project Type": attributes.projectTypes,
        "Sectoral Scope": attributes.sectoralScopes,
        "Sustainable Development Goals (SDGs)": attributes.sdgGoals,
        "Project Information Link": attributes.projectLink,
      },
    };
  });

  const refetchHoldings = useCallback(() => {
    refetch();
  }, [refetch]);

  const handleFiltersChange: CCHoldingsViewProps["handleFiltersChange"] = useCallback((args) => {
    setActiveFilters((prev) => ({
      ...prev,
      ...args,
    }));
  }, []);

  return {
    filters,
    handleFiltersChange,
    activeFilters,
    refetchHoldings,
    loading: loading || filtersLoading,
    holdingsTableData,
    showSellOrderModal,
    setSelected,
    selected,
  };
};
