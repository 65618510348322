import { useMemo } from "react";

import { CurrencyCode, useAccountTenantIdQuery, useTenantsQuery } from "@/app/types/generated/graphql";

export const useCurrencySymbol = () => {
  const { data: accountTenantId, loading: accountQueryLoading } = useAccountTenantIdQuery();
  const { data: tenantInfo, loading: tenantsQueryLoading } = useTenantsQuery();
  const [currencySymbol, currencyCode] = useMemo(() => {
    const tenant = tenantInfo?.tenants.find((tenant) => tenant.id === accountTenantId?.account?.tenantId);
    return [
      tenant?.localisation?.currencySymbol ?? "$",
      (tenant?.localisation?.currencyCode ?? CurrencyCode["Usd"]) as CurrencyCode,
    ];
  }, [tenantInfo?.tenants, accountTenantId?.account?.tenantId]);

  return {
    currencySymbol,
    currencyCode,
    loading: accountQueryLoading || tenantsQueryLoading,
  };
};
