import { useFeatureToggle } from "./use-feature-toggle";
import { FeatureToggleKey, UseAllFeatureToggleValues } from "./use-feature-toggle.types";

export const useAllFeatureToggleValues: UseAllFeatureToggleValues = () => {
  const { value: multipleUserV2Enabled, loading } = useFeatureToggle({
    featureKey: FeatureToggleKey.MultipleUsersV2,
  });
  const { value: multiSelectionAttributeEnabled, loading: loading2 } = useFeatureToggle({
    featureKey: FeatureToggleKey.MultipleAttributeSelection,
  });

  return {
    toggles: {
      [FeatureToggleKey.MultipleUsersV2]: multipleUserV2Enabled,
      [FeatureToggleKey.MultipleAttributeSelection]: multiSelectionAttributeEnabled,
    },
    loading: loading || loading2,
  };
};
