import { useCallback, useMemo, useState } from "react";

import { ContactSupportModal } from "../components";
import ConfirmImportModal from "../components/confirm-import-modal/confirm-import-modal";
import { ConfirmImportModalCustomProps } from "../components/confirm-import-modal/confirm-import-modal.types";
import { ConfirmRemoveUserModal } from "../components/confirm-remove-user-modal.tsx/confirm-remove-user-modal";
import { ConfirmRemoveUserModalCustomProps } from "../components/confirm-remove-user-modal.tsx/confirm-remove-user-modal.types";
import { OrderCancelModal } from "../components/order-cancel-modal";
import { OrderCancelModalCustomProps } from "../components/order-cancel-modal/order-cancel-modal.types";
import { BuyOrderModal } from "../components/order-modal/buy-order-modal";
import { BuyFormPrefilledAsset } from "../components/order-modal/buy-order-modal/buy-order-modal.types";
import SellFormModal from "../components/order-modal/sell-order-modal/sell-order-modal";
import { ModalContextProvider } from "./modal-context";
import { ModalEnums, ModalState } from "./modal-provider.types";

export const ModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalState, setModalState] = useState<ModalState | null>(null);

  const renderModals = useMemo(() => {
    switch (modalState?.type) {
      case ModalEnums.OrderCancel:
        return (
          <OrderCancelModal
            visible={modalState?.type === ModalEnums.OrderCancel}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ConfirmImport:
        return (
          <ConfirmImportModal
            visible={modalState?.type === ModalEnums.ConfirmImport}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ConfirmRemoveUserModal:
        return (
          <ConfirmRemoveUserModal
            visible={modalState?.type === ModalEnums.ConfirmRemoveUserModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );

      case ModalEnums.ContactSupportModal:
        return (
          <ContactSupportModal
            visible={modalState?.type === ModalEnums.ContactSupportModal}
            closeModal={() => setModalState(null)}
          />
        );

      case ModalEnums.SellOrderModal:
        return (
          <SellFormModal
            visible={modalState?.type === ModalEnums.SellOrderModal}
            closeModal={() => setModalState(null)}
            {...modalState?.customProps}
          />
        );
      case ModalEnums.BuyOrderModal:
        return (
          <BuyOrderModal
            visible={modalState?.type === ModalEnums.BuyOrderModal}
            closeModal={() => setModalState(null)}
            {...modalState?.customProps}
          />
        );

      default:
        return null;
    }
  }, [modalState]);

  const showOrderCancelModal = useCallback((customProps: OrderCancelModalCustomProps) => {
    setModalState({
      type: ModalEnums.OrderCancel,
      customProps,
    });
  }, []);

  const showBuyOrderModal = useCallback((prefilledAsset?: BuyFormPrefilledAsset) => {
    setModalState({
      type: ModalEnums.BuyOrderModal,
      customProps: {
        defaultAsset: prefilledAsset,
      },
    });
  }, []);

  const showConfirmImportModal = useCallback((customProps: ConfirmImportModalCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmImport,
      customProps,
    });
  }, []);

  const showSellOrderModal = useCallback((selectedCCIds: string[]) => {
    setModalState({
      type: ModalEnums.SellOrderModal,
      customProps: {
        selectedCCIds,
      },
    });
  }, []);

  const showOnboardingCompletedModal = useCallback(() => {
    setModalState({
      type: ModalEnums.OnboardingCompletedModal,
      customProps: undefined,
    });
  }, []);

  const showContactSupportModal = useCallback(() => {
    setModalState({
      type: ModalEnums.ContactSupportModal,
      customProps: undefined,
    });
  }, []);

  const showConfirmRemoveUserModal = useCallback((customProps: ConfirmRemoveUserModalCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmRemoveUserModal,
      customProps,
    });
  }, []);

  return (
    <ModalContextProvider
      values={useMemo(
        () => ({
          showOrderCancelModal,
          showConfirmRemoveUserModal,
          showConfirmImportModal,
          showContactSupportModal,
          showBuyOrderModal,
          showSellOrderModal,
          showOnboardingCompletedModal,
        }),
        [
          showOrderCancelModal,
          showConfirmImportModal,
          showConfirmRemoveUserModal,
          showContactSupportModal,
          showBuyOrderModal,
          showSellOrderModal,
          showOnboardingCompletedModal,
        ],
      )}
    >
      {renderModals}
      {children}
    </ModalContextProvider>
  );
};
