import OktaAuth from "@okta/okta-auth-js";

import { OKTA_CLIENT_ID, OKTA_ISSUER } from "../lib/env-helpers";

export const TOKEN_STORAGE_KEY = "icx-tracecc-t";
export default new OktaAuth({
  issuer: OKTA_ISSUER,
  clientId: OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/login/callback`,
  pkce: true,
  tokenManager: {
    storageKey: TOKEN_STORAGE_KEY,
  },
});
