import { Box } from "@powerledger/ui-component-lib";
import { useMemo } from "react";

import { useFewAccountDetailsQuery } from "../../types/generated/graphql";

export const UserInfo = () => {
  const { data } = useFewAccountDetailsQuery();

  const account = useMemo(() => data?.account, [data]);

  return (
    <Box>
      <Box sx={{ fontSize: 3 }}>{`${account?.firstName || ""} ${account?.lastName || ""}`}</Box>
      <Box sx={{ fontSize: 1, color: "textDarker" }}>{account?.email || ""}</Box>
    </Box>
  );
};
