import { useFeatureFlag } from "configcat-react";

import { FeatureToggleKey, FeatureToggleKeyMap } from ".";

export const useFeatureToggle = ({
  featureKey,
  defaultValue,
}: {
  featureKey: FeatureToggleKey;
  defaultValue?: boolean;
}) => {
  return useFeatureFlag(FeatureToggleKeyMap[featureKey], defaultValue || false);
};
