import { Box, Button, Flex, IconLoadingOutlined, Modal } from "@powerledger/ui-component-lib";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications-handler";
import { FactorProvider, FactorType, useResetFactorMutation } from "@/app/types/generated/graphql";

export const ResetFactor = ({
  factorProvider,
  factorType,
  visible,
  closeModal,
}: {
  factorProvider: FactorProvider;
  factorType: FactorType;
  visible: boolean;
  closeModal: () => void;
}) => {
  const { t } = useTranslation();

  const [resetFactor, { loading: isSubmitting }] = useResetFactorMutation({
    refetchQueries: ["AccountEnrolledFactor"],
  });

  const resetFactorCallback = useCallback(async () => {
    try {
      await resetFactor({
        variables: {
          reset: {
            factorType,
            factorProvider,
          },
        },
      });
    } finally {
      notifications.success({
        description: t("Google Authenticator has been successfully reset"),
      });
      closeModal();
    }
  }, [closeModal, factorProvider, factorType, resetFactor, t]);

  return (
    <Modal visible={visible} onCancel={closeModal} title={t("Are you sure you want to reset this factor?")}>
      <Box sx={{ p: 4 }}>
        <Box>{t("This will remove this authentication factor from your account.")}</Box>
        <Flex sx={{ justifyContent: "flex-end" }}>
          <Button sx={{ mt: 4, ml: 2, mr: 2 }} variant="pill" onClick={resetFactorCallback} disabled={isSubmitting}>
            {isSubmitting ? <IconLoadingOutlined /> : t("Confirm")}
          </Button>
          <Button sx={{ mt: 4, ml: 2, mr: 2 }} variant="pill" onClick={closeModal} disabled={isSubmitting}>
            {t("Cancel")}
          </Button>
        </Flex>
      </Box>
    </Modal>
  );
};

export default ResetFactor;
