import { useAccountLinkedRegistriesQuery } from "@/app/types/generated/graphql";

import { UseRegistryTab } from "./registry-tab.types";

export const useRegistryTab: UseRegistryTab = () => {
  const {
    data: linkedRegistriesData,
    loading: linkedRegistriesLoading,
    error: linkedRegistriesError,
  } = useAccountLinkedRegistriesQuery();

  const registries = linkedRegistriesData?.account?.members?.[0].registries;

  return {
    loading: linkedRegistriesLoading,
    registries,
    error:
      linkedRegistriesError ?? !registries?.length
        ? "Failed to load registry details, please refresh the page and try again. If issue persists, please contact support."
        : "",
  };
};
