import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { useCCOptions } from "@/app/hooks/use-cc-options";
import { ModalContext } from "@/app/modal-provider";
import {
  CCDepositFormViewProps,
  ImportCarbonCreditInputFields,
  UseCCDepositFormFn,
} from "@/app/pages/cc-deposit-form/cc-deposit-form.types";
import {
  CarbonCreditInput,
  CarbonCreditsDocument,
  MembershipType,
  useCarbonCreditsQuery,
  useEditCarbonCreditMutation,
  useGetMembersQuery,
  useImportCarbonCreditMutation,
} from "@/app/types/generated/graphql";

const getFieldsTouched = (values: Record<string, any>) => {
  const touched: Record<string, any> = {};
  for (const key in values) {
    touched[key] = true;
  }
  return touched;
};

/**
 * If there's one value
 * Check if the value is CORSIA LABELLED,
 *     If no ; disable all option except CORSIA LABELLED and selected option
 *     If yes ; enable all options
 * If there's two value
 * disable options other than the values (to disable them)
 * Else enable all
 * returns true if field is disabled and false if field is not disabled
 */
export const allowCorsiaLabelledPlusOneMore = (type?: string, values?: string[] | null): boolean => {
  const CORSIA_LABELLED = "CORSIA";
  if (values?.length === 1) {
    if (values[0].toUpperCase().includes(CORSIA_LABELLED)) {
      return false;
    } else {
      return !type?.toUpperCase().includes(CORSIA_LABELLED) && type !== values[0];
    }
  } else if (values?.length === 2) {
    return !values.includes(type || "");
  }
  return false;
};

export const conditionalCCDepositMutations = async ({
  importCC,
  editCC,
  isEdit,
  id,
  values,
}: {
  importCC: ReturnType<typeof useImportCarbonCreditMutation>[0];
  editCC: ReturnType<typeof useEditCarbonCreditMutation>[0];
  isEdit: boolean;
  id?: string;
  values: ImportCarbonCreditInputFields;
}) => {
  const { accountId, tenantId, id: valueId, __typename, ...restValues } = values;
  const mutate = isEdit ? editCC : importCC;
  return await mutate({
    variables: {
      input: {
        carbonCreditAuditId: isEdit ? id : undefined,
        carbonCredit: {
          ...restValues,
          vintage: Number(values.vintage),
          volume: Number(values.volume),
        },
      },
    },
  });
};

export const useCCDepositForm: UseCCDepositFormFn = () => {
  const [displayRemarks, setDisplayRemarks] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const isEdit = params.id !== "new";

  const { ccOptions, loading: ccOptionsLoading, error: ccOptionError } = useCCOptions();

  const { showConfirmImportModal } = useContext(ModalContext);

  const { data: members, loading: membersLoading, error: membersQueryError } = useGetMembersQuery();

  const [importCC] = useImportCarbonCreditMutation({
    refetchQueries: [CarbonCreditsDocument],
  });

  const [editCC] = useEditCarbonCreditMutation({
    refetchQueries: [CarbonCreditsDocument],
  });

  const {
    data: carbonCredits,
    loading: ccLoading,
    error: carbonCreditsError,
  } = useCarbonCreditsQuery({
    skip: !isEdit,
    variables: {
      where: {
        id: params.id,
      },
    },
  });

  const carbonCreditAuditDetails = carbonCredits?.carbonCredits?.[0];

  const clientCodeOptions = useMemo(() => {
    return (
      members?.account?.members?.map((member) => ({
        label: member.clientCode || "",
        value: member.clientCode || "",
      })) || []
    );
  }, [members?.account]);

  const isTrader = useMemo(
    () => Boolean(members?.account?.members?.find((member) => member.membershipType === MembershipType["Trader"])),
    [members?.account],
  );

  const initialValues: ImportCarbonCreditInputFields = isEdit
    ? (carbonCreditAuditDetails?.carbonCredit as CarbonCreditInput)
    : {
        registryName: "",
        projectId: "",
        projectName: "",
        projectTypes: null,
        volume: 0,
        vintage: null,
        vintageFrom: null,
        vintageTo: null,
        country: "IN",
        sectoralScopes: [],
        coBenefits: null,
        sdgGoals: null,
        projectLink: "",
        registryAccountName: "",
        registryAccountNumber: "",
        clientCode: null,
        serialNumber: "",
      };

  const goBack = useCallback(() => navigate(-1), [navigate]);

  const handleSubmit: CCDepositFormViewProps["handleSubmit"] = useCallback(
    (values) => {
      showConfirmImportModal({
        isEdit,
        onConfirm: async () => {
          await conditionalCCDepositMutations({
            importCC,
            editCC,
            isEdit,
            id: carbonCreditAuditDetails?.id,
            values,
          });
          goBack();
        },
      });
    },
    [importCC, editCC, isEdit, goBack, showConfirmImportModal, carbonCreditAuditDetails],
  );
  const handleToggleDisplayRemarks = () => setDisplayRemarks((showErrors) => !showErrors);

  const remarks = carbonCreditAuditDetails?.remarks?.[0] || "";

  const error =
    ccOptionError || carbonCreditsError || membersQueryError || (isEdit && !carbonCreditAuditDetails)
      ? "Something went wrong, please try again or contact us at {{supportEmail}}"
      : "";

  return {
    ccOptions,
    loading: ccLoading || ccOptionsLoading,
    membersLoading,
    formikProps: { initialValues, initialTouched: isEdit ? getFieldsTouched(initialValues) : {} },
    goBack,
    isEdit,
    handleToggleDisplayRemarks,
    displayRemarks,
    handleSubmit,
    isTrader,
    clientCodeOptions,
    remarks,
    error: error,
  };
};
