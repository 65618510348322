import { useTranslation } from "react-i18next";

import { PublicLayout } from "../..";
import ResendEmail from "./resend-email";

export const ForgotPasswordConfirmation = ({ goBack, userId }: { userId: string; goBack: () => void }) => {
  const { t } = useTranslation();

  return (
    <PublicLayout
      heading={t("OK great, please follow the link sent to your email and proceed within 1 hour.")}
      subheading={t("Forgotten Password")}
      action={{ text: t("Back"), onClick: goBack }}
    >
      <ResendEmail userId={userId} />
    </PublicLayout>
  );
};

export default ForgotPasswordConfirmation;
