import { AttributeSelector, Flex, Heading } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { getSelectTranslation } from "@/app/lib/get-translations-for-components";
import { OrderPosition } from "@/app/types/generated/graphql";

import { MarketHeaderProps } from ".";

const typeOptions = [
  { label: "Both Bid and Ask Orders", value: null },
  { label: "Bid Orders", value: OrderPosition.Bid },
  { label: "Ask Orders", value: OrderPosition.Ask },
];
export const MarketHeader: FC<MarketHeaderProps> = ({ handleChangeTypeFilter, typeFilter }) => {
  const { t } = useTranslation();
  return (
    <Flex
      sx={{
        justifyContent: "space-between",
      }}
    >
      <Heading sx={{ variant: "texts.tracexDefaultHeading" }}>{t("Current Market")}</Heading>
      <Flex
        sx={{
          fontSize: 0,
          my: 3,
          alignItems: "center",
        }}
      >
        <AttributeSelector
          translation={getSelectTranslation(t)}
          label=""
          sx={{ p: 2, width: "250px" }}
          options={typeOptions}
          value={typeFilter}
          onChange={(value: { label: string; value: OrderPosition | null }) => handleChangeTypeFilter(value.value)}
        />
      </Flex>
    </Flex>
  );
};
