import { Box, Button, Flex, IconLoading, Modal, Text } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { useModalHelpers } from "@/app/hooks/use-modal-helpers";

import { ConfirmImportModalProps } from "./confirm-import-modal.types";

const ConfirmImportModal: React.FC<ConfirmImportModalProps> = ({ onConfirm, visible, isEdit, closeModal, loading }) => {
  const { t } = useTranslation();

  const { submitting, onConfirm: onSubmit } = useModalHelpers({
    onSubmit: onConfirm,
    closeModal,
    loading,
    messages: {
      successMessage: "Your carbon credit deposit request is being reviewed.",
    },
  });

  return (
    <Modal maskClosable visible={!!visible} hideCloseButton onCancel={closeModal} sx={{ maxHeight: "inherit" }}>
      <Box sx={{ p: 4 }}>
        <Text sx={{ color: "text", fontSize: 3 }}>
          {isEdit
            ? t("Are you sure you want to submit the request again?")
            : t("Are you sure you want to submit the request?")}
        </Text>
        <Flex sx={{ justifyContent: "flex-end", mt: 4, gap: 2 }}>
          <Button
            type="button"
            variant="warning"
            sx={{
              width: 9,
            }}
            disabled={submitting}
            onClick={closeModal}
          >
            {t("Cancel")}
          </Button>
          <Button variant="pill" sx={{ width: 9 }} disabled={submitting} onClick={onSubmit}>
            {t("Confirm")}
          </Button>
          {submitting && <IconLoading />}
        </Flex>
      </Box>
    </Modal>
  );
};
export default ConfirmImportModal;
