import { startCase } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications-handler";
import { emptyCCFilterState, useCCFilters } from "@/app/hooks/use-cc-filters";
import { CarbonCreditAuditStatus, useCarbonCreditsQuery } from "@/app/types/generated/graphql";

import { CCDepositFilterState, CCDepositsViewProps, UseCCDepositsFn } from "./cc-deposits.types";

export const useCCDeposits: UseCCDepositsFn = () => {
  const { t } = useTranslation();

  const [filterState, setFilterState] = useState<CCDepositFilterState>({
    projectId: emptyCCFilterState("Project ID"),
    vintage: emptyCCFilterState("Vintage"),
    location: emptyCCFilterState("Location"),
    registry: emptyCCFilterState("Registry"),
    coBenefits: emptyCCFilterState("Additional Co-Benefits"),
    status: emptyCCFilterState("Status"),
  });

  const {
    data: carbonData,
    loading,
    refetch: refetchQuery,
  } = useCarbonCreditsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        projectId: filterState.projectId.value || undefined,
        vintages: filterState.vintage.value ? [Number(filterState.vintage.value)] : undefined,
        countries: filterState.location.value ? [filterState.location.value] : undefined,
        registries: filterState.registry.value ? [filterState.registry.value] : undefined,
        coBenefits: filterState.coBenefits.value ? [filterState.coBenefits.value] : undefined,
        status: filterState.status.value ? [filterState.status.value] : undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
    onError(error) {
      notifications.error({
        description: t(error.message),
      });
    },
  });

  const { filters: baseFilters, loading: filtersLoading } = useCCFilters();
  const filters = [
    ...(baseFilters.filter((filter) => filter.key !== "clientCode") || []),
    {
      key: "status",
      label: "Status",
      options: [
        emptyCCFilterState("Status"),
        ...Object.values(CarbonCreditAuditStatus).map((status) => ({
          label: startCase(status.toLowerCase()),
          value: status,
        })),
      ],
    },
  ] as CCDepositsViewProps["filters"];

  const data = (carbonData?.carbonCredits || []).map((cc) => ({
    ...cc,
    expandedData: {
      "Project Name": cc?.carbonCredit?.projectName,
      "Project Type": cc?.carbonCredit?.projectTypes,
      "Sectoral Scope": cc?.carbonCredit?.sectoralScopes,
      "Sustainable Development Goals (SDGs)": cc?.carbonCredit?.sdgGoals,
      "Project Information Link": cc?.carbonCredit?.projectLink,
    },
  }));

  const refetch = useCallback(() => {
    refetchQuery();
  }, [refetchQuery]);

  const handleFiltersChange: CCDepositsViewProps["handleFiltersChange"] = useCallback((args) => {
    setFilterState((prev) => ({
      ...prev,
      ...args,
    }));
  }, []);

  return {
    loading: loading || filtersLoading,
    data,
    filters,
    refetch,
    filterState,
    handleFiltersChange,
  };
};
