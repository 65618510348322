import { useOktaAuth } from "@okta/okta-react";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { LoadingOverlayIndicator } from "@/app/components/loading-overlay-indicator";
import { UnverifiedAccountModal } from "@/app/components/unverified-account-modal";
import { useLogout } from "@/app/hooks";
import { useCCOptions } from "@/app/hooks/use-cc-options";
import { useOktaRoles } from "@/app/hooks/use-okta-roles";
import { grantAccess, UserRoles } from "@/app/lib/role-based-access-control";
import { CCDepositForm } from "@/app/pages/cc-deposit-form";
import { CCDeposits } from "@/app/pages/cc-deposits";
import { RecHoldings } from "@/app/pages/cc-holdings";
import { Markets } from "@/app/pages/markets";
import Notifications from "@/app/pages/notifications/notifications";
import { OrderHistory } from "@/app/pages/orders";
import { Settings } from "@/app/pages/settings";
import { UserResources } from "@/app/pages/user-resources";
import { AccountStatus } from "@/app/types/generated/graphql";

import { PrivateRoutes, RouteComposerProps } from "./routes.type";

export const RouteComposer: React.FC<RouteComposerProps> = ({
  accountStatus,
  toggleValue = false,
  guard,
  userRoles,
  children,
}) => {
  //If account status is undefined, or loading show Loading Overlay
  if (!accountStatus) return <LoadingOverlayIndicator />;

  //If Feature Toggle is Required but toggle value is false, redirect to home route
  if (!toggleValue && guard.toggleKey) {
    return <Navigate to="/" replace />;
  }

  //If Route Allows Current User Status
  if (guard.allowedStatuses.includes(accountStatus) && grantAccess(userRoles, guard.allowedRole)) {
    return children;
  }

  //If Account Status is Onboarding then redirect to Signup
  if (accountStatus === AccountStatus.Onboarding) {
    return <Navigate to="/" replace />;
  }

  //If Account status is not onboarding but user going to signup, then redirect to market
  if (guard.allowedStatuses.includes(AccountStatus.Onboarding)) {
    return <Navigate to="/market" replace />;
  }

  // If Account Status is not Active & also not onboarding, then show unverified modal
  if (accountStatus !== AccountStatus.Active) {
    return <UnverifiedAccountModal visible />;
  }

  //If Account status is active and not onboarding, but grant access fails, then redirect to market
  if (!grantAccess(userRoles, guard.allowedRole)) {
    return <Navigate to="/market" replace />;
  }

  return <Navigate to="/" replace />;
};

export const PrivateRoute: React.FC<{
  render: (userRoles: UserRoles[], status?: AccountStatus) => JSX.Element;
}> = ({ render }) => {
  const { authState, oktaAuth } = useOktaAuth();
  const { logout, loggingOut } = useLogout();

  const { userRoles } = useOktaRoles();

  const { loading, error } = useCCOptions();

  useEffect(() => {
    /**
     * For some reason when token has expired, the okta returns isAuthenticated true but does not renew the token
     * This logic is kept as fallback for when that happens
     */
    const expiryTime = authState?.accessToken?.expiresAt;
    if (expiryTime && expiryTime < Date.now() / 1000) {
      oktaAuth.tokenManager.renew("accessToken").catch(() => {
        logout();
      });
    }
  }, [authState, logout, oktaAuth]);

  if (!authState || loading || error || loggingOut) return <LoadingOverlayIndicator />;

  if (!authState?.isAuthenticated) return <Navigate to="/" replace />;

  return render(userRoles, AccountStatus.Active);
};

/**
 * Private Routes added in order of which it is shown in Layout Sidebar
 * i.e. Admin Dashboard -> Markets ->  Holdings -> Orders-> User Resources -> ...rest
 * To Show Routes in Sider, add layout property with following type
    navTitle: string;
    headerTitle: string;
    subTitle?: string;
    icon: string;
 */
export const privateRoutes: PrivateRoutes[] = [
  {
    path: "/market",
    layout: {
      navTitle: "Market",
      headerTitle: "Market",
      icon: "Markets",
    },
    render: () => <Markets />,
    guards: {
      allowedStatuses: [AccountStatus.Active],
    },
  },
  {
    path: "/carbon-credits",
    layout: {
      navTitle: `Carbon Credits`,
      headerTitle: `Carbon Credits`,
      icon: "CCWallet",
    },
    subRoutes: [
      {
        path: "/holdings",
        layout: {
          navTitle: `Holdings`,
          headerTitle: `Holdings`,
          icon: "CCWallet",
        },
        guards: {
          allowedStatuses: [AccountStatus.Active],
        },
        render: () => <RecHoldings />,
      },
      {
        path: "/deposit-requests",
        layout: {
          navTitle: `Deposit Requests`,
          headerTitle: `Deposit Requests`,
          icon: "ImportBox",
        },
        guards: {
          allowedStatuses: [AccountStatus.Active],
        },
        subRoutes: [
          {
            path: "/deposit/:id",
            layout: {
              navTitle: `Deposit Carbon Credits`,
              headerTitle: `Deposit Carbon Credits`,
              icon: "CCWallet",
            },
            guards: {
              allowedStatuses: [AccountStatus.Active],
            },
            render: () => <CCDepositForm />,
          },
        ],
        render: () => <CCDeposits />,
      },
    ],
    guards: {
      allowedStatuses: [AccountStatus.Active],
    },
  },
  {
    path: "/orders",
    layout: {
      navTitle: "Order History",
      headerTitle: "Order History",
      subTitle: "Trade",
      icon: "OrderHistory",
    },
    guards: {
      allowedStatuses: [AccountStatus.Active],
    },
    render: () => <OrderHistory />,
  },
  {
    path: "/user-resources",
    layout: {
      navTitle: "User Resources",
      headerTitle: "User Resources",
      icon: "QuestionMark",
    },
    render: () => <UserResources />,
    guards: {
      allowedStatuses: [AccountStatus.AwaitingVerification, AccountStatus.AwaitingReverification, AccountStatus.Active],
    },
  },
  {
    path: "/notifications",
    render: () => <Notifications />,
    guards: {
      allowedStatuses: [AccountStatus.AwaitingVerification, AccountStatus.AwaitingReverification, AccountStatus.Active],
    },
  },
  {
    path: "/settings",
    render: () => <Settings />,
    guards: {
      allowedStatuses: [AccountStatus.AwaitingVerification, AccountStatus.AwaitingReverification, AccountStatus.Active],
    },
  },
];
