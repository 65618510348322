import { FormattedOption } from "@/app/lib/format-asset-options";

import { useCCOptions } from "../use-cc-options";

// Add "projectId" later once API is made for querying project id
export type BaseCCPageFilterKeys = "vintage" | "clientCode" | "location" | "registry" | "coBenefits";

export type BaseCCFilters = {
  key: BaseCCPageFilterKeys;
  label?: string;
  options: FormattedOption;
}[];

export const emptyCCFilterState = (key = ""): FormattedOption["0"] => ({ label: key, value: null });

export function useCCFilters(): {
  filters: BaseCCFilters;
  loading: boolean;
} {
  const { ccOptions, loading } = useCCOptions();
  const filters: BaseCCFilters = [
    {
      //This will go first after ProjectID Query is built
      key: "clientCode",
      label: "Client Codes",
      options: [emptyCCFilterState("Client Codes"), ...(ccOptions?.clientCodes || [])],
    },
    // Project ID will go here
    {
      key: "vintage",
      label: "Vintage",
      options: [emptyCCFilterState("Vintage"), ...(ccOptions?.vintage || [])],
    },
    {
      key: "location",
      label: "Location",
      options: [emptyCCFilterState("Location"), ...(ccOptions?.country || [])],
    },
    {
      key: "registry",
      label: "Registry",
      options: [emptyCCFilterState("Registry"), ...(ccOptions?.registry || [])],
    },
    {
      key: "coBenefits",
      label: "Additional Co-Benefits",
      options: [emptyCCFilterState("Additional Co-Benefits"), ...(ccOptions?.additionalCoBenefits || [])],
    },
  ];

  return { filters, loading };
}
