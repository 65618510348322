import { Box, Flex, Heading, Table } from "@powerledger/ui-component-lib";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column } from "react-table";

import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { ClientsTableViewProps } from "./clients-table.types";

export const ClientsTableView: React.FC<ClientsTableViewProps> = ({ clients, loading, refetch }) => {
  const { t } = useTranslation();
  const columns: Column<ClientsTableViewProps["clients"]["0"]>[] = useMemo(
    () => [
      {
        Header: t("Client Code"),
        accessor: "clientCode",
      },
      {
        Header: t("Name"),
        accessor: "name",
      },
      {
        Header: t("Email Address"),
        accessor: "invoiceEmail",
      },
      {
        Header: t("Contact Number"),
        accessor: "invoiceContactNumber",
      },
    ],
    [t],
  );

  return (
    <Box>
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          mt: 4,
          mb: 3,
        }}
      >
        <Heading as="h3">{t("Client List")}</Heading>
        <RefreshIconButton loading={loading} fetchData={refetch} />
      </Flex>
      <Table
        loading={loading}
        translation={getTableTranslation(t)}
        pagination={false}
        dataSource={clients}
        noHover
        columns={columns}
      />
    </Box>
  );
};
