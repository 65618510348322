import { AssetAttributeOptions, FormattedOption } from "@/app/lib/format-asset-options";
import { MarketFilterKeys } from "@/app/pages/markets/market.types";

export const getNewStateWithAllOrNoneForAKey = (
  values: Array<string>,
  baseOptions: FormattedOption,
  all = true,
  key?: string,
) => {
  if (!key) {
    const thisKeyValues: string[] = baseOptions.map((opt) => opt.value);
    // If all is true, return all options else return empty array
    return all ? thisKeyValues : [];
  }

  const thisKeyOption = baseOptions.find((opt) => opt.label === key)?.options || [];
  const thisKeyValues: string[] = thisKeyOption?.map((opt) => opt.value);
  const otherKeyValues = values.filter((value) => !thisKeyOption.find((val) => value == val.value));

  return all ? [...otherKeyValues, ...thisKeyValues] : otherKeyValues;
};

export const getOptionForType = (type: MarketFilterKeys, options?: AssetAttributeOptions) => {
  switch (type) {
    case "vintage":
      return options?.vintage;
    case "projectTypes":
      return options?.projectTypes;
    case "sectoralScopes":
      return options?.sectoralScopes;
    case "sdgGoals":
      return options?.sdgGoals;
    case "location":
      return options?.country;
    case "registry":
      return options?.registry;
    case "coBenefits":
      return options?.additionalCoBenefits;
    default:
      return [];
  }
};

/** For Selecting All And Deselect All Values for a key in Multi Selector */
export enum MultiSelectActions {
  AllSelected = "ALL_SELECTED",
  AllDeselected = "ALL_DESELETED",
}
