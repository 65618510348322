import { Box, Flex, Text } from "@powerledger/ui-component-lib";
import React from "react";
import { useTranslation } from "react-i18next";

import { useGetLocationTexts } from "@/app/hooks/use-get-locations-text/use-get-location-texts";
import { isValidWebLink } from "@/app/lib/validate-web-link";

import { MaxContentLimitViewProps, TableValueWrapperProps } from "./table-value-wrapper.types";

const MaxContentLimitView: React.FC<MaxContentLimitViewProps> = ({
  title,
  array,
  maxContent = 1,
  getIcon,
  getToolTipText,
  delimiter,
}) => {
  const { t } = useTranslation();

  const showValue = array.slice(0, maxContent).map((val) => {
    const IconComponent = getIcon?.(val.toString());
    const tooltip = t(getToolTipText?.(val.toString()) || val.toString() || "");
    return IconComponent ? (
      <IconComponent
        key={val}
        size={5}
        color="textDarker"
        data-tip-disable={!tooltip}
        data-tip={t(tooltip)}
        data-for={title}
      />
    ) : isValidWebLink(val.toString()) ? (
      <a href={val.toString()} target="_blank" rel="noreferrer">
        {t(val.toString())}
      </a>
    ) : (
      t(val.toString())
    );
  });
  const remainingValue = array.slice(maxContent).map((val) => {
    const tooltip = t(getToolTipText?.(val.toString()) || val.toString() || "");
    return tooltip || t(val.toString());
  });

  return (
    <>
      <Flex
        sx={{
          whiteSpace: "pre-line",
        }}
      >
        {getIcon ? showValue : showValue.join(delimiter || ", ")}
      </Flex>
      {Boolean(remainingValue.length) && (
        <Text sx={{ ml: 1 }} data-for={title || "eligibilities"} data-tip={remainingValue}>
          {t(" + {{remainingLength}} more", { remainingLength: remainingValue.length })}
        </Text>
      )}
    </>
  );
};

export const TableValueWrapper: React.FC<TableValueWrapperProps> = ({
  title = "",
  value,
  delimiter,
  maxContent = 1,
  getIcon,
  getToolTipText,
  containerSx,
}) => {
  const { t } = useTranslation();
  const { getLocationTextsWithRegion } = useGetLocationTexts();
  if (title === "Locations" && value) {
    value = getLocationTextsWithRegion((Array.isArray(value) ? value : [value]) as string[]);
  }

  if (Array.isArray(value)) {
    return (
      <Box sx={{ color: "textDarker", ...containerSx }}>
        {value.length ? (
          <MaxContentLimitView
            array={value}
            title={title}
            maxContent={maxContent}
            getIcon={getIcon}
            delimiter={delimiter}
            getToolTipText={getToolTipText}
          />
        ) : (
          "-"
        )}
      </Box>
    );
  } else {
    return (
      <Box sx={{ color: "textDarker", ...containerSx, a: { color: "primary" } }}>
        {isValidWebLink(value?.toString()) ? (
          <a href={value.toString()} target="_blank" rel="noreferrer">
            {t(value.toString())}
          </a>
        ) : (
          t(value?.toString() ?? "-")
        )}
      </Box>
    );
  }
};
