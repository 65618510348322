import { MembershipType, useGetMembersQuery } from "@/app/types/generated/graphql";

import { ClientsTableView } from "./clients-table-view";

export const ClientsTable = () => {
  const {
    data: members,
    loading,
    refetch: refetchQuery,
  } = useGetMembersQuery({
    notifyOnNetworkStatusChange: true,
  });

  const clients =
    members?.account?.members?.filter((member) => member.membershipType === MembershipType["Client"]) ?? [];

  const refetch = () => {
    refetchQuery();
  };

  return <ClientsTableView clients={clients} loading={loading} refetch={refetch} />;
};
