import { Flex, Heading } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useTranslation } from "react-i18next";

import { AddressFields } from "@/app/components/address-fields";
import { LoadingErrorLayout } from "@/app/components/loading-error-layout";
import { SeparatorLayout } from "@/app/components/separator-layout";

import { AccountMemberDetailsProps } from "../settings.types";
import { ClientsTable } from "./clients-table";

export const PrimaryDetailsTab: React.FC<AccountMemberDetailsProps> = ({ member, loading, error }) => {
  const { t } = useTranslation();

  return (
    <LoadingErrorLayout loading={loading} error={error}>
      <Flex sx={{ alignItems: "center" }}>
        <Heading as="h2">{t("Primary Details")}</Heading>
      </Flex>
      <SeparatorLayout
        contentStyle={{
          p: "16px 16px 16px 5px",
          textAlign: "left",
          color: "white",
          width: ["100%", "32%"],
        }}
        wrapperStyle={{
          mt: 5,
          p: 20,
          borderRadius: 8,
          background: "secondaryDarker",
        }}
        descriptionStyle={{
          color: "white",
        }}
        data={{
          "Member Name": member?.name ?? "-",
          "Membership Type": startCase(member?.membershipType?.toLowerCase()) || "-",
          "Membership ID": member?.membershipId ?? "-",
          "Registered Address": <AddressFields address={member?.registeredAddress} />,
          "Invoice Address": <AddressFields address={member?.invoiceAddress} />,
        }}
        showSeparator={false}
      />
      <ClientsTable />
    </LoadingErrorLayout>
  );
};
