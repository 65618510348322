import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-locize-backend";
// @ts-ignore
import { locizePlugin } from "locize";
// @ts-ignore
import LastUsed from "locize-lastused";
import { initReactI18next } from "react-i18next";

import {
  APOLLO_GRAPH_VARIANT,
  IS_TEST,
  LOCIZE_APIKEY,
  LOCIZE_PROJECTID,
  LOCIZE_REFLNG,
  LOCIZE_VERSION,
} from "./env-helpers";
import I18nDate from "./i18n-date";
import I18nNumbers from "./i18n-numbers";

export const fallbackLng = "en";

const IS_PRODUCTION = APOLLO_GRAPH_VARIANT === "production";

const locizeOptions = {
  projectId: LOCIZE_PROJECTID as string,
  apiKey: LOCIZE_APIKEY as string,
  referenceLng: LOCIZE_REFLNG as string,
  version: LOCIZE_VERSION as string,
  allowedAddOrUpdateHosts: ["localhost"],
};

if (!IS_PRODUCTION) {
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  i18n.use(LastUsed);
}

if (!IS_TEST) {
  //if CI MODE do not initialize i18n with locize
  i18n
    .use(Backend)
    // locize-editor
    // InContext Editor of locize
    .use(locizePlugin);
}

i18n
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend

  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18nBase instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      nsSeparator: false,
      keySeparator: false,
      debug: !IS_PRODUCTION && !IS_TEST,
      fallbackLng: "en",
      saveMissing: !IS_PRODUCTION, // you should not use saveMissing in production
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: locizeOptions,
      locizeLastUsed: locizeOptions,
      detection: {
        lookupLocalStorage: "lang",
        lookupSessionStorage: "lang",
        caches: IS_PRODUCTION ? ["sessionStorage"] : [],
      },
    },
    async () => {
      await I18nDate.init(i18n.language);
      await I18nNumbers.init(i18n.language);
      i18n.on("languageChanged", (lng) => {
        I18nDate.init(lng);
        I18nNumbers.init(lng);
      });
    },
  );

export default i18n;
