import { Box, Flex, Table, Text } from "@powerledger/ui-component-lib";
import currency from "currency.js";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { ContactSupportMailLink } from "@/app/container/contact-support-mail-link";
import { currencyFormatter } from "@/app/lib/currency-formatter";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { calculateOrderAmounts } from "@/app/lib/order-amounts-calculator";
import { volumeFormatter } from "@/app/lib/volume-formatter";

import { TableValueWrapper } from "../table-value-wrapper";
import { GenericTooltip } from "../tooltips";
import { SellFormValues } from "./sell-order-modal/sell-order-modal.types";

const CurrencyValueView = ({ symbol = "$", value = "" }) => (
  <Box sx={{ textAlign: "right", width: "100%" }}>{currencyFormatter(symbol, value, { fromCents: false })}</Box>
);

const CurrencyHeader = ({ title = "" }) => (
  <Box
    sx={{
      width: "100%",
      textAlign: "right",
      whiteSpace: "break-spaces",
    }}
  >
    {title}
  </Box>
);

export const SellOrderSummaryTable = ({
  data,
  currencySymbol,
}: {
  currencySymbol: string;
  data: SellFormValues["values"];
}) => {
  const { t } = useTranslation();

  const tableColumns = useMemo(() => {
    const cols: Column<SellFormValues["values"]["0"]>[] = [
      {
        Header: t("Registry"),
        disableSortBy: true,
        accessor: (row) => row.attributes.registryName,
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      },
      {
        Header: t("Project ID"),
        accessor: (row) => row.attributes.projectId,
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      },
      {
        id: "av_volume",
        Header: () => (
          <Flex
            sx={{
              flexDirection: "column",
            }}
          >
            <Text>{t("Volume")}</Text>
            <Text
              sx={{
                fontSize: 0,
                fontWeight: 400,
              }}
            >
              {t("Available / Current")}
            </Text>
          </Flex>
        ),
        disableSortBy: true,
        Cell: ({ row }: { row: Row<SellFormValues["values"]["0"]> }) => (
          <Text sx={{ color: "textDarker" }}>
            {`${volumeFormatter(row.original.availableBalance)} / ${volumeFormatter(row.original.currentBalance)}`}
          </Text>
        ),
      },
      {
        Header: t("Quantity"),
        accessor: "volume",
        Cell: ({ value }: { value: number }) => <TableValueWrapper value={volumeFormatter(value)} />,
        disableSortBy: true,
      },
      {
        Header: <CurrencyHeader title={t("Unit Price")} />,
        id: "unitPrice",
        accessor: (row) => row.unitPrice,
        disableSortBy: true,

        Cell: ({ value }: { value: string }) => {
          return <CurrencyValueView symbol={currencySymbol} value={value} />;
        },
      },
      {
        Header: <CurrencyHeader title={t("Trade Value")} />,
        id: "tradeValue",
        disableSortBy: true,
        accessor: (row) => +row.volume * currency(row.unitPrice).value,
        Cell: ({ value }: { value: string }) => {
          return <CurrencyValueView symbol={currencySymbol} value={value} />;
        },
      },
      {
        Header: <CurrencyHeader title={t("Transaction Fee")} />,
        id: "transactionFee",
        disableSortBy: true,
        accessor: (row) => {
          const order = calculateOrderAmounts(row.unitPrice, row.volume);
          return -order.getFee(false); // transaction fees are always deducted while selling so negative value
        },
        Cell: ({ value }: { value: string }) => {
          return <CurrencyValueView symbol={currencySymbol} value={value} />;
        },
      },
    ];
    if (data?.some((d) => !!d.reason)) {
      cols.push({
        Header: t("Reason"),
        accessor: "reason",
        Cell: ({ value }: { value: string }) => (
          <Box
            sx={{
              fontWeight: "bold",
              a: {
                color: "accent",
              },
            }}
          >
            <ContactSupportMailLink description={t(value)} />
          </Box>
        ),
        disableSortBy: true,
      });
    }
    return cols;
  }, [data, currencySymbol, t]);

  return (
    <>
      <Table
        columns={tableColumns}
        dataSource={data}
        noHover
        pagination={false}
        selectorPosition="left"
        translation={getTableTranslation(t)}
      />
      <GenericTooltip />
    </>
  );
};
