import { Box, Button, ChevronDownSVG, Flex, Grid, IconRefresh, Text } from "@powerledger/ui-component-lib";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { RegistryType } from "@/app/lib/format-asset-options";

import { FiltersButton } from "./filter-button";
import { FilterColumn } from "./filter-column";
import { ColumnType, MarketFilterViewProps } from "./market-filter.types";
import { getDisabledFilterKeysBasedOnRegistry } from "./use-market-filter";

export const MarketFilterView: FC<MarketFilterViewProps> = ({
  handleToggleFilter,
  appliedFilters,
  filtersOpen,
  handleFilterStateToggle,
  clearTypeFilter,
  editingFilters,
  options,
  applyFilters,
  resetFilters,
  isFilterEmpty,
  disableFilterSelect,
}) => {
  const { t } = useTranslation();

  const columns: ColumnType[] = useMemo(
    () => [
      {
        title: "Project ID",
        selectedOptions: editingFilters.projectId,
        toggleFilter: (value: string) => {
          handleFilterStateToggle("projectId", [value]);
        },
        clearFilters: () => clearTypeFilter(["projectId"]),
        options: [],
        withSearch: true,
      },
      {
        title: "Vintage",
        selectedOptions: editingFilters.vintage,
        toggleFilter: (value: string) => {
          handleFilterStateToggle("vintage", [value]);
        },
        clearFilters: () => clearTypeFilter(["vintage"]),
        options: options?.vintage || [],
      },
      {
        title: "Location",
        selectedOptions: editingFilters.location,
        toggleFilter: (value: string, name?: string) => {
          handleFilterStateToggle("location", [value], name);
        },
        clearFilters: () => clearTypeFilter(["location"]),
        options: options?.country || [],
      },
      {
        title: "Registry",
        selectedOptions: editingFilters.registry,
        toggleFilter: (value: string) => {
          handleFilterStateToggle("registry", [value]);
          handleFilterStateToggle(getDisabledFilterKeysBasedOnRegistry(value as RegistryType), []);
        },
        clearFilters: () => clearTypeFilter(["registry"]),
        options: options?.registry || [],
      },
      {
        title: "Project Type",
        selectedOptions: editingFilters.projectTypes,
        toggleFilter: (value: string) => {
          handleFilterStateToggle("projectTypes", [value]);
        },
        clearFilters: () => clearTypeFilter(["projectTypes"]),
        options: options?.projectTypes || [],
        disabled: editingFilters.registry.length === 1 && editingFilters.registry.includes(RegistryType.VERRA),
      },
      {
        title: "Sectoral Scope",
        selectedOptions: editingFilters.sectoralScopes,
        toggleFilter: (value: string) => {
          handleFilterStateToggle("sectoralScopes", [value]);
        },
        clearFilters: () => clearTypeFilter(["sectoralScopes"]),
        options: options?.sectoralScopes || [],
        disabled: editingFilters.registry.length === 1 && editingFilters.registry.includes(RegistryType.GOLD_STANDARD),
      },
      {
        title: "Additional Co-Benefits",
        selectedOptions: editingFilters.coBenefits,
        toggleFilter: (value: string) => {
          handleFilterStateToggle("coBenefits", [value]);
        },
        clearFilters: () => clearTypeFilter(["coBenefits"]),
        options: options?.additionalCoBenefits || [],
      },
      {
        title: "Sustainable Development Goals (SDGs)",
        selectedOptions: editingFilters.sdgGoals,
        toggleFilter: (value: string) => {
          handleFilterStateToggle("sdgGoals", [value]);
        },
        clearFilters: () => clearTypeFilter(["sdgGoals"]),
        options: options?.sdgGoals || [],
      },
    ],
    [editingFilters, options, clearTypeFilter, handleFilterStateToggle],
  );

  return (
    <Box
      sx={{
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "secondary",
        borderRadius: 4,
        mb: 4,
        display: "block",
        fontSize: 1,
        opacity: disableFilterSelect ? 0.5 : 1,
        pointerEvents: disableFilterSelect ? "none" : "auto",
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          borderRadius: 4,
          px: [3, null, null, null, 4],
          py: 3,
          backgroundColor: "#5857E9",
          ...(filtersOpen
            ? {
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "secondary",
              }
            : {}),
        }}
      >
        <Button
          sx={{
            py: 1,
            px: 0,
            bg: "transparent",
            color: "text",
            ":enabled:focus, :enabled:hover": {
              bg: "transparent",
            },
            alignItems: "center",
          }}
          onClick={() => handleToggleFilter()}
        >
          <Flex
            sx={{
              p: 1,
              alignItems: "center",
              svg: {
                transform: filtersOpen ? "rotate(180deg)" : "",
                width: 20,
                height: 20,
              },
            }}
          >
            <ChevronDownSVG />
          </Flex>
          <Flex
            sx={{
              fontSize: 2,
              fontWeight: 600,
              lineHeight: "20px",
              ml: 3,
              flexDirection: appliedFilters.split(",").length > 3 ? "column" : ["column", "column", "column", "row"],
              alignItems: "flex-start",
            }}
          >
            {t("Filter Carbon Credits")}
            <Text
              sx={{
                fontStyle: "italic",
                fontWeight: 100,
                ml: appliedFilters.split(",").length > 3 ? 0 : [0, 0, 0, 3],
                fontSize: ["0.6em", "0.7em", "0.8em", "0.9em"],
              }}
            >
              {t("({{appliedFilters}} selected)", {
                appliedFilters,
              })}
            </Text>
          </Flex>
        </Button>
        <Flex sx={{ alignItems: "center" }}>
          {!isFilterEmpty && (
            <Button
              variant="text"
              sx={{
                p: 2,
                cursor: "pointer",
                fontSize: 1,
              }}
              tabIndex={0}
              onClick={() => {
                resetFilters();
                handleToggleFilter(false);
              }}
            >
              {t("Reset")}
              <IconRefresh width={5} height={4} color="primary" />
            </Button>
          )}
          <Flex sx={{ alignItems: "center", ml: [2, null, null, null, 2] }}>
            <FiltersButton
              active
              onClick={() => {
                applyFilters(editingFilters);
                handleToggleFilter(false);
              }}
              sx={{ ml: 3 }}
              disabled={isFilterEmpty}
            >
              <>{t("Apply")}</>
            </FiltersButton>
          </Flex>
        </Flex>
      </Flex>
      <Grid
        aria-label="select filter values"
        columns={4}
        gap={0}
        sx={{
          height: filtersOpen ? 800 : 0,
          overflow: "hidden",
        }}
      >
        {columns.map((column, index) => (
          <FilterColumn
            key={column.title}
            column={column}
            sx={{
              height: 400,
              ...(index < 4
                ? { borderBottomWidth: `1px`, borderBottomColor: "secondary", borderBottomStyle: "solid" }
                : {}),
              pb: 2,
              ...(index === columns.length - 1 ? { borderWidth: 0 } : {}),
            }}
          />
        ))}
      </Grid>
    </Box>
  );
};
