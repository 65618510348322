import { Flex, Heading } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { FilterDisplay } from "@/app/components/filter-display";

import { CCHoldingsViewProps } from "./cc-holdings.types";
import { CCHoldingsTable } from "./components/cc-holdings-table/cc-holdings-table";

export const CCHoldingsView: FC<CCHoldingsViewProps> = ({
  filters,
  handleFiltersChange,
  activeFilters,
  refetchHoldings,
  selected,
  setSelected,
  loading = false,
  holdingsTableData,
  showSellOrderModal,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Flex>
        <Heading sx={{ variant: "texts.tracexDefaultHeading" }}>{t("Carbon Credits - Holdings")}</Heading>
      </Flex>
      <FilterDisplay
        filters={filters}
        activeFilters={activeFilters}
        setActiveFilters={handleFiltersChange}
        loading={loading}
        refetch={refetchHoldings}
      />
      <CCHoldingsTable
        showSellOrderModal={showSellOrderModal}
        data={holdingsTableData}
        loading={loading}
        selected={selected}
        setSelected={setSelected}
      />
    </>
  );
};
