import currency from "currency.js";

import { OrderPosition } from "../types/generated/graphql";
import { BUYER_FEE, BUYER_FEE_PER_UNIT, GST, SELLER_FEE, SELLER_FEE_PER_UNIT } from "./env-helpers";

type CalculatorOption = {
  buyerFeePerUnit?: number;
  sellerFeePerUnit?: number;
  gst?: number;
  type?: OrderPosition;
  buyerFee?: number;
  sellerFee?: number;
};

export function calculateOrderAmounts(unitPrice = 0, volume = 0, opts?: CalculatorOption) {
  const {
    buyerFeePerUnit = BUYER_FEE_PER_UNIT,
    sellerFeePerUnit = SELLER_FEE_PER_UNIT,
    gst = GST,
    type = OrderPosition.Ask,
    buyerFee = BUYER_FEE,
    sellerFee = SELLER_FEE,
  } = opts || {};
  return {
    buyerFeePerUnit,
    sellerFeePerUnit,
    tax: gst,
    buyerFee,
    sellerFee,
    getRespectiveFee() {
      return type === OrderPosition.Ask
        ? { perUnit: this.sellerFeePerUnit, percentage: this.sellerFee }
        : { perUnit: this.buyerFeePerUnit, percentage: this.buyerFee };
    },
    convertToCents(value: number) {
      return currency(value).intValue;
    },
    getTradeValue(toCents = true) {
      const value = unitPrice * volume;
      return toCents ? this.convertToCents(value) : value;
    },
    getFee(toCents = true) {
      const fees = this.getRespectiveFee();
      const totalUnitFee = volume * fees.perUnit;
      const minFee = this.getTradeValue(false) * fees.percentage;
      const fee = minFee > totalUnitFee ? minFee : totalUnitFee;
      return toCents ? this.convertToCents(fee) : fee;
    },
    getGST(type: "fee" | "tradeValue" = "tradeValue", toCents = true) {
      const taxableAmount = type === "tradeValue" ? this.getTradeValue(false) : this.getFee(false);
      const value = this.tax * taxableAmount;
      return toCents ? this.convertToCents(value) : value;
    },
    getTotal(toCents = true) {
      const tradeValuePlusGST = this.getTradeValue(false) + this.getGST("tradeValue", false);
      const feePlusGST = this.getFee(false) + this.getGST("fee", false);
      const value = type === OrderPosition.Ask ? tradeValuePlusGST - feePlusGST : tradeValuePlusGST + feePlusGST;
      return toCents ? this.convertToCents(value) : value;
    },
  };
}
