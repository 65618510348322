import { FC } from "react";

import { MarketFilterState } from "../../market.types";
import { MarketFilterView } from "./market-filter-view";
import { useMarketFilter } from "./use-market-filter";

export const MarketFilter: FC<{
  applyFilters: (filters: MarketFilterState) => void;
  resetSelectedFilters: () => void;
  resetFilterState?: boolean;
}> = (props) => {
  const logic = useMarketFilter(props);

  return <MarketFilterView {...logic} />;
};
