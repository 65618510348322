import { Box, Button, Flex, Heading, IconLoading, ThemeUIStyleObject } from "@powerledger/ui-component-lib";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { currencyFormatter } from "@/app/lib/currency-formatter";
import { calculateOrderAmounts } from "@/app/lib/order-amounts-calculator";

import { SellOrderSummaryTable } from "../sell-order-summary-table";
import { SellOrderSummaryProps } from "./sell-order-modal.types";

export default function SellOrderSummary({ data, currencySymbol, goBack, onConfirm }: SellOrderSummaryProps) {
  const { t } = useTranslation();

  const [submitting, setSubmitting] = useState(false);

  const { totalTradeValue, totalGST, totalTxnFee, totalTxnFeeGST, totalOrdersPrice } = data.reduce(
    (acc, detail) => {
      const orderAmount = calculateOrderAmounts(detail.unitPrice, detail.volume);

      acc.totalTradeValue += orderAmount.getTradeValue();
      acc.totalGST += orderAmount.getGST("tradeValue");
      acc.totalTxnFee += orderAmount.getFee();
      acc.totalTxnFeeGST += orderAmount.getGST("fee");
      acc.totalOrdersPrice += orderAmount.getTotal();

      return acc;
    },
    {
      totalTradeValue: 0,
      totalGST: 0,
      totalTxnFee: 0,
      totalTxnFeeGST: 0,
      totalOrdersPrice: 0,
    },
  );

  return (
    <Box sx={{ py: 3 }}>
      <Heading
        sx={{
          variant: "texts.tracexDefaultHeading",
          px: 3,
        }}
      >
        {t("Review Order Details")}
      </Heading>
      <Box
        sx={{
          p: 3,
        }}
      >
        <SellOrderSummaryTable data={data} currencySymbol={currencySymbol} />
      </Box>
      <Flex
        sx={{
          p: 3,
          flexDirection: "column",
          alignItems: "flex-end",
          color: "textDarker",
          lineHeight: 2,
          fontSize: 1,
        }}
      >
        <OrderSummaryFlex
          leftContent={t("Total Trade Value")}
          rightContent={currencyFormatter(currencySymbol, totalTradeValue)}
          sx={{ color: "textDarker", fontWeight: "bold" }}
        />
        <OrderSummaryFlex
          leftContent={t("GST (Total Trade Value)")}
          rightContent={currencyFormatter(currencySymbol, totalGST)}
          sx={{ color: "textDarker", fontWeight: "bold" }}
        />
        <OrderSummaryFlex
          leftContent={t("Transaction Fee")}
          rightContent={currencyFormatter(currencySymbol, -totalTxnFee)}
          sx={{ color: "textDarker", fontWeight: "bold" }}
        />
        <OrderSummaryFlex
          leftContent={t("GST (Transaction Fee)")}
          rightContent={currencyFormatter(currencySymbol, -totalTxnFeeGST)}
          sx={{ color: "textDarker", fontWeight: "bold" }}
        />
        <OrderSummaryFlex
          leftContent={t("Transaction Total")}
          rightContent={currencyFormatter(currencySymbol, totalOrdersPrice)}
          sx={{ color: "text", fontWeight: "bold" }}
        />

        <Flex sx={{ mt: 4, gap: 3, alignItems: "center" }}>
          <Button
            sx={{
              bg: "transparent",
              borderRadius: 0,
              height: 5,
              color: "text",
              "&:enabled:hover": {
                bg: "transparent",
                borderBottom: "1px solid white",
              },
              "&:enabled:focus": {
                bg: "transparent",
              },
              "&:disabled": {
                bg: "transparent",
              },
            }}
            disabled={submitting}
            onClick={goBack}
          >
            {t("Edit Details")}
          </Button>
          <Button
            sx={{
              py: 1,
              px: 4,
            }}
            variant="pill.compact"
            disabled={submitting}
            onClick={async () => {
              setSubmitting(true);
              await onConfirm();
              setSubmitting(false);
            }}
          >
            {t("Confirm Offer Order")}
          </Button>
          {submitting && <IconLoading size="small" />}
        </Flex>
      </Flex>
    </Box>
  );
}

export const OrderSummaryFlex = ({
  leftContent,
  rightContent,
  sx,
}: {
  sx?: ThemeUIStyleObject;
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}) => (
  <Flex sx={{ gap: 6, ...sx }}>
    <Box sx={{ minWidth: 120, textAlign: "end" }}>{leftContent}</Box>
    <Box sx={{ minWidth: 120, textAlign: "end" }}>{rightContent}</Box>
  </Flex>
);
