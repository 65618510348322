import { Box, Button, Flex, IconInfo, Table, Text } from "@powerledger/ui-component-lib";
import { TFunction } from "i18next";
import { sumBy } from "lodash";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { SeparatorLayout } from "@/app/components";
import { CCTableHeader } from "@/app/components/cc-table-header";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { volumeFormatter } from "@/app/lib/volume-formatter";

import { CCHoldingsTableProps } from "./cc-holdings-table.types";

export const getHoldingsAvailableColumn = (t: TFunction<"translation", undefined, "translation">) => ({
  Header: () => (
    <Flex
      sx={{
        flexDirection: "column",
        width: 100,
      }}
    >
      <Flex sx={{ width: 150, alignItems: "center" }}>
        <Text>{t("Volume")}</Text>
        <IconInfo
          size={5}
          sx={{ ml: 1 }}
          data-tip={t(
            "The available quantity for your account may differ from the current quantity because of pending transactions that have been presented against the account, but have not yet been processed. ",
          )}
        />
      </Flex>
      <Text
        sx={{
          fontSize: 0,
          fontWeight: 400,
        }}
      >
        {t("Available / Current")}
      </Text>
    </Flex>
  ),
  id: "availableVolume",
  accessor: (row: CCHoldingsTableProps["data"]["0"]) => row.availableBalance,
  Cell: ({ row }: { row: Row<CCHoldingsTableProps["data"]["0"]> }) => (
    <Text sx={{ color: "textDarker" }}>
      {volumeFormatter(row.original.availableBalance)}/ {volumeFormatter(row.original.currentBalance)}
    </Text>
  ),
});

export const CCHoldingsTable: FC<CCHoldingsTableProps> = ({
  data = [],
  loading = false,
  showSellOrderModal,
  setSelected,
  selected,
}) => {
  const { t } = useTranslation();

  const renderExpandedComponent = useCallback(
    (data: Record<string, string | number>) => (
      <SeparatorLayout
        data={data}
        contentStyle={{ color: "text", width: "18%" }}
        descriptionStyle={{ color: "text", width: "100%", wordBreak: "break-all" }}
      />
    ),
    [],
  );

  const tableColumns: Column<CCHoldingsTableProps["data"]["0"]>[] = useMemo(
    () => [
      {
        Header: t("Client Code"),
        disableSortBy: true,
        accessor: (row) => row.attributes.clientCode,
        Cell: ({ value }: { value: string }) => <TableValueWrapper containerSx={{ width: "80px" }} value={value} />,
      },
      {
        Header: t("Project ID"),
        disableSortBy: true,
        accessor: (row) => <TableValueWrapper containerSx={{ width: "80px" }} value={row.attributes.projectId} />,
      },
      {
        Header: t("Vintage"),
        accessor: (row) => row.attributes.vintage,
        Cell: ({ value }: { value: number }) => <TableValueWrapper containerSx={{ width: "80px" }} value={value} />,
      },
      {
        Header: t("Location"),
        accessor: (row) => row.attributes.country,
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper title="Locations" containerSx={{ width: "80px" }} value={value} />
        ),
      },
      {
        Header: t("Registry"),
        accessor: (row) => row.attributes.registryName,
        Cell: ({ value }: { value: string }) => <TableValueWrapper containerSx={{ width: "80px" }} value={value} />,
      },
      {
        Header: t("Additional Co-Benefits"),
        disableSortBy: true,
        accessor: (row) => <TableValueWrapper maxContent={2} value={row.attributes?.coBenefits || []} />,
      },
      getHoldingsAvailableColumn(t),
    ],
    [t],
  );

  const totalCCs = useMemo(() => sumBy(data, "currentBalance"), [data]);

  return (
    <Box
      sx={{
        mt: 3,
      }}
    >
      <CCTableHeader totalCCs={totalCCs} loading={loading} />
      <Box
        sx={{
          table: {
            "& td": {
              whiteSpace: "unset",
            },
          },
        }}
      >
        <Table
          disableCheckbox={(rows) => !rows.length || rows.some((row) => row.original.availableBalance < 10)}
          columns={tableColumns}
          noHover
          expandable
          renderExpandedComponent={renderExpandedComponent}
          dataSource={data}
          loading={loading}
          isMultiSelect
          selectorPosition="left"
          pagination={false}
          showScrollArrows
          getSelected={(rows) => setSelected(rows as string[])}
          translation={getTableTranslation(t)}
        />
      </Box>
      <Flex
        sx={{
          mt: 4,
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            p: 3,
          }}
          disabled={!selected?.length}
          onClick={() => showSellOrderModal(selected)}
        >
          {t("Sell Selected Carbon Credits")}
        </Button>
      </Flex>
    </Box>
  );
};
