import { IconLoading, IconWarning } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex } from "theme-ui";

import { getSupportEmail } from "@/app/lib/get-support-email";

import { LoadingOverlayIndicator } from "../loading-overlay-indicator";
import { LoadingErrorLayoutProps } from "./loading-error-layout.types";

/**
 * Component to display loading || error conditions
 */
export const LoadingErrorLayout: React.FC<LoadingErrorLayoutProps> = ({
  children,
  overlayLoading = false,
  loading = false,
  error,
  goBack,
}) => {
  const { t } = useTranslation();
  if (loading) {
    if (overlayLoading) {
      return <LoadingOverlayIndicator />;
    }
    return (
      <>
        <Box
          sx={{
            div: {
              position: "relative",
            },
          }}
        >
          <Flex
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconLoading />
          </Flex>
        </Box>
      </>
    );
  }

  if (error) {
    return (
      <Flex
        sx={{
          mt: 4,
          mb: 2,
          bg: "secondaryDarker",
          borderRadius: "8px",
          p: 3,
          alignItems: "center",
          fontSize: 2,
          color: "negative",
        }}
      >
        <IconWarning color={"negative"} size={6} />
        {t(error, {
          supportEmail: getSupportEmail(),
        })}
        {goBack && (
          <Button
            sx={{
              alignItems: "center",
              bg: "transparent",
              ":enabled:hover, :enabled:focus": {
                bg: "transparent",
              },
              px: 0,
              py: 0,
              ml: 3,
              borderRadius: 0,
              color: "text",
              ":enabled:hover": {
                borderBottomWidth: "1px ",
                borderBottomStyle: "solid",
                borderBottomColor: "text",
              },
            }}
            aria-label={t("go back")}
            onClick={goBack}
          >
            {t("Go Back")}
          </Button>
        )}
      </Flex>
    );
  }
  return <>{children}</>;
};
