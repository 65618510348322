import { ApolloError } from "@apollo/client";

import { demoAccountCC } from "@/app/container/apollo-client/demo-data/account/demo-account";

import { demoAccount } from "../../app/container/apollo-client/demo-data";
import { AccountBankDetailsDocument, AccountDocument } from "../../app/types/generated/graphql";

export const mockAccount = {
  request: {
    query: AccountDocument,
  },
  result: {
    data: {
      account: demoAccount,
    },
  },
};

export const mockAccountBankDetails = {
  request: {
    query: AccountBankDetailsDocument,
  },
  result: {
    data: {
      account: {
        id: demoAccountCC.id,
        banks: demoAccountCC.banks,
      },
    },
  },
};

export const mockAccountBankDetails_Failure = {
  request: {
    query: AccountBankDetailsDocument,
  },
  error: new ApolloError({
    errorMessage:
      "Failed to load payment details, please refresh the page and try again. If issue persists, please contact support.",
  }),
};
