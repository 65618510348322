import { Box, Button, Flex, Table } from "@powerledger/ui-component-lib";
import { FC, useCallback, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { SeparatorLayout } from "@/app/components/separator-layout";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { currencyFormatter } from "@/app/lib/currency-formatter";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";
import { getDefaultValue } from "@/app/lib/order-attributes-modifier";
import { ModalContext } from "@/app/modal-provider/modal-context";
import { OrderPosition, useFewAccountDetailsQuery } from "@/app/types/generated/graphql";

import { MarketTableData } from "../../market.types";
import { MarketTableProps } from "./market-table.types";

export const MarketTable: FC<MarketTableProps> = ({
  fetchData,
  reloadMarketData,
  pageInfo,
  loading,
  assetCounts,
  hideSellButton,
  tableData,
  hideBuyButton,
  currencySymbol,
  selectedFilters,
}) => {
  const { t } = useTranslation();
  const { showBuyOrderModal } = useContext(ModalContext);
  const { data: accountData } = useFewAccountDetailsQuery({
    fetchPolicy: "cache-only",
  });

  const renderExpandedComponent = useCallback(
    (data: MarketTableData["expandedData"]) => (
      <SeparatorLayout
        data={data}
        contentStyle={{ color: "text", width: "12%" }}
        descriptionStyle={{ color: "text", width: "100%", wordBreak: "break-all" }}
      />
    ),
    [],
  );

  const tableColumns = useMemo<Column<MarketTableData>[]>(() => {
    return [
      {
        Header: t("Registry"),
        accessor: (row) => getDefaultValue(row.position, row.attributes.registryName),
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => {
          return <TableValueWrapper value={value} containerSx={{ color: "textDarker" }} />;
        },
      },
      {
        Header: t("Project Name"),
        accessor: (row) => getDefaultValue(row.position, row.attributes.projectName),
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => {
          return <TableValueWrapper value={value} containerSx={{ color: "textDarker" }} />;
        },
      },
      {
        Header: t("Vintage"),
        accessor: (row) => getDefaultValue(row.position, row.attributes.vintage),
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => {
          return <Box sx={{ color: "textDarker" }}> {value}</Box>;
        },
      },
      {
        Header: t("Location"),
        accessor: (row) => getDefaultValue(row.position, row.attributes.country),
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper title="Locations" containerSx={{ width: "80px" }} value={value} />
        ),
      },
      {
        Header: t("Additional Co-Benefits"),
        accessor: (row) => getDefaultValue(row.position, row.attributes.coBenefits),
        disableSortBy: true,
        Cell: ({ value }: { value: string[] }) => {
          return <TableValueWrapper value={value} containerSx={{ color: "textDarker" }} maxContent={2} />;
        },
      },
      {
        Header: t("SDG Count"),
        accessor: (row) => row.attributes.sdgCount,
        disableSortBy: true,
        Cell: ({ value }: { value: number }) => {
          return <TableValueWrapper value={value} containerSx={{ color: "textDarker" }} />;
        },
      },
      {
        Header: t("Quantity"),
        accessor: "currentVolume",
        Cell: ({ value }: { value: number }) => {
          return <TableValueWrapper value={value} containerSx={{ color: "textDarker" }} />;
        },
      },
      {
        Header: (
          <Box
            sx={{
              textAlign: "right",
              width: "100px",
            }}
          >
            {t("Unit Price")}
          </Box>
        ),
        id: "unitPrice",
        width: "100px",
        accessor: "price.amount" as keyof Pick<MarketTableData, "price">,
        Cell: ({ value }: { value: number }) => {
          return (
            <Flex
              sx={{
                textAlign: "right",
                width: "120px",
                px: 2,
                flexDirection: "column",
              }}
            >
              <Box>{currencyFormatter(currencySymbol, value)}</Box>
            </Flex>
          );
        },
      },
      {
        Header: () => t("Order Type"),
        accessor: "position",
        Cell: ({ value }) => {
          const color = value === OrderPosition.Ask ? "#FC8282" : "#8FE9AA";
          return (
            <Box
              sx={{
                background: color,
                borderRadius: 3,
                pa: 2,
                width: "40px",
                height: "20px",
                textAlign: "center",
                borderWidth: 1,
                borderColor: color,
                borderStyle: "solid",
                color: "#19183E",
              }}
            >
              {value}
            </Box>
          );
        },
      },
      {
        Header: t("Action"),
        id: "action",
        Cell: ({ row }: { row: Row<MarketTableData> }) =>
          row.original.position === OrderPosition.Ask &&
          row.original.accountId !== accountData?.account?.id &&
          row.original.currentVolume >= 10 && (
            <Flex sx={{ alignItems: "center" }}>
              <Button
                variant="pill.compactSecondary"
                sx={{ py: 1, mr: 2 }}
                onClick={() => {
                  showBuyOrderModal({
                    registryName: row.original.attributes.registryName,
                    vintage: row.original.attributes.vintage,
                    country: row.original.attributes.country,
                    projectTypes: row.original.attributes.projectTypes,
                    sdgGoals: row.original.attributes.sdgGoals,
                    coBenefits: row.original.attributes.coBenefits,
                    projectId: row.original.attributes.projectId,
                    sectoralScopes: row.original.attributes.sectoralScopes,
                    projectLink: row.original.attributes.projectLink,
                    projectName: row.original.attributes.projectName,
                    serialNumber: row.original.attributes.serialNumber,
                    vintageFrom: row.original.attributes.vintageFrom,
                    vintageTo: row.original.attributes.vintageTo,
                    instantBuyId: row.original.id,
                    unitPrice: +row.original.price.amount,
                    availableVolume: row.original.currentVolume,
                  });
                }}
              >
                {t("Buy Now")}
              </Button>
            </Flex>
          ),
      },
    ];
  }, [currencySymbol, showBuyOrderModal, accountData?.account?.id, t]);

  const openBuyOrderModal = () => {
    showBuyOrderModal({
      registryName: selectedFilters.registry?.[0],
      vintage: selectedFilters.vintage?.[0] ? Number(selectedFilters.vintage?.[0]) : undefined,
      country: selectedFilters.location,
      projectTypes: selectedFilters.projectTypes,
      sdgGoals: selectedFilters.sdgGoals,
      coBenefits: selectedFilters.coBenefits,
      projectId: selectedFilters.projectId?.[0],
      sectoralScopes: selectedFilters.sectoralScopes,
    });
  };

  return (
    <>
      <Flex
        sx={{
          mb: 2,
          alignItems: "space-between",
          bg: "#262547",
          borderRadius: "8px",
          p: 3,
        }}
      >
        <Box
          sx={{
            color: "textDarker",
            fontSize: 0,
            flex: 2,
          }}
        >
          {assetCounts && !loading && (
            <SeparatorLayout
              reverse
              data={{
                ...(!hideSellButton && { "Carbon Credits listed as Bids": assetCounts.bid.toLocaleString() }),
                ...(!hideBuyButton && { "Carbon Credits listed as Asks": assetCounts.ask.toLocaleString() }),
              }}
              headingStyle={{
                color: "primary",
              }}
              contentStyle={{
                minWidth: [0, 0, 0, "200px"],
              }}
              wrapperStyle={{
                pl: 3,
              }}
            />
          )}
        </Box>
        <Flex
          sx={{
            justifyContent: "flex-end",
            gap: 2,
            flexWrap: "wrap",
            flex: 1,
            minHeight: 45,
          }}
        >
          {!hideBuyButton && !loading && (
            <Button disabled={loading} sx={{ width: "80px" }} variant="pill.compact" onClick={openBuyOrderModal}>
              {t("Buy")}
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex
        sx={{
          justifyContent: "flex-end",
          my: 2,
        }}
      >
        <RefreshIconButton loading={loading} fetchData={reloadMarketData} />
      </Flex>
      <Table
        loading={loading}
        showScrollArrows
        expandable
        columns={tableColumns}
        dataSource={tableData}
        manualPagination
        pageInfo={pageInfo}
        fetchData={fetchData}
        renderExpandedComponent={renderExpandedComponent}
        translation={getTableTranslation(t)}
      />
    </>
  );
};
