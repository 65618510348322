import { FC } from "react";

import { MarketFilter } from "./components/market-filter/market-filter";
import { MarketHeader } from "./components/market-header";
import { MarketTable } from "./components/market-table/";
import { MarketViewProps } from "./market.types";

export const MarketView: FC<MarketViewProps> = ({
  selectedFilters,
  fetchData,
  reloadMarketData,
  pageInfo,
  loading,
  applyFilters,
  resetSelectedFilters,
  assetCounts,
  tableData,
  hideSellButton,
  typeFilter,
  handleChangeTypeFilter,
  hideBuyButton,
  resetFilterState,
  currencySymbol,
}) => {
  return (
    <>
      <MarketHeader handleChangeTypeFilter={handleChangeTypeFilter} typeFilter={typeFilter} />
      <MarketFilter
        applyFilters={applyFilters}
        resetSelectedFilters={resetSelectedFilters}
        resetFilterState={resetFilterState}
      />
      <MarketTable
        currencySymbol={currencySymbol}
        assetCounts={assetCounts}
        fetchData={fetchData}
        reloadMarketData={reloadMarketData}
        pageInfo={pageInfo}
        tableData={tableData}
        loading={loading}
        hideBuyButton={hideBuyButton}
        hideSellButton={hideSellButton}
        selectedFilters={selectedFilters}
      />
    </>
  );
};
