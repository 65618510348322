import { Theme, ThemeUIStyleObject } from "@powerledger/ui-component-lib";

export const CustomThemeProperty = <T extends string>(
  key: T & (T extends keyof Theme ? "Value is reserved by Theme!" : string),
  value: ThemeUIStyleObject,
) => value;

export const toastTracex = {
  ".Toastify__toast": {
    color: "black",
    borderLeftWidth: "10px",
    borderLeftStyle: "solid",
    backgroundColor: "white",
    minWidth: "350px",
    marginLeft: "-30px",
  },
  ".Toastify__toast--info": {
    borderLeftColor: "#605BC8",
  },
  ".Toastify__toast--success": {
    borderLeftColor: "#00B19F",
  },
  ".Toastify__toast--error": {
    borderLeftColor: "#DA1E28",
  },
  ":root ": {
    "--toastify-icon-color-info": "#605BC8",
    "--toastify-icon-color-success": "#00B19F",
    "--toastify-icon-color-error": "#DA1E28",
  },
};

export const texts = CustomThemeProperty("texts", {
  tracexDefaultHeading: {
    fontWeight: "bolder",
    fontSize: "2.3rem",
    padding: "10px 0",
  },
});

export const tracexTheme: Record<string, ThemeUIStyleObject> = {
  texts,
  styles: {
    root: {
      ...toastTracex,
    },
  },
  table: {
    bg: "#232245",
    th: {
      bg: "#232245",
    },
    td: {
      bg: "#232245",
    },
  },
  forms: {
    select: {
      optionDisabled: {
        opacity: 0.5,
        pointerEvents: "none",
        "&:hover": {
          bg: "white",
        },
      },
    },
  },
};
