import { Box, Button, EmailSVG, Flex, Grid, Heading, IconLoading, Text } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { object, string } from "yup";

import { ErrorMessage, Form, PasswordInput } from "@/app/components/form";
import { Input } from "@/app/components/input";

import { LoginViewProps } from "./login.types";
import { MFAChallengeInput } from "./mfa-challenge-input";

export const LoginView: React.FC<LoginViewProps> = ({
  t,
  goToPage,
  mfaModal,
  setMFAModal,
  handleLogin,
  formRef,
  goToSignup,
}) => (
  <Box>
    <Heading
      as="h4"
      sx={{
        textAlign: "center",
        fontSize: [3, 4],
        mb: 2,
      }}
    >
      {t("Hi, Welcome Back!")}
    </Heading>
    <Text
      sx={{
        variant: "subheading",
        fontSize: 0,
      }}
    >
      {t(
        "TraceCC offers multi-factor authentication (MFA). We strongly encourage all users to activate MFA. MFA setup is accessible in the user details section on settings page.",
      )}
    </Text>
    <MFAChallengeInput
      visible={mfaModal.visible}
      closeModal={() => setMFAModal({ visible: false, transaction: undefined })}
      loginTransaction={mfaModal.transaction}
    />
    <Formik
      innerRef={formRef}
      initialValues={{
        username: "",
        password: "",
      }}
      validationSchema={object().shape({
        username: string().required(t("Username is required")),
        password: string().required(t("Password is required")),
      })}
      onSubmit={handleLogin}
      validateOnMount
    >
      {({ handleSubmit, isValid, isSubmitting, status, setFieldValue, submitForm }) => (
        <>
          {!!status && <Box sx={{ color: "yellow", mt: 3, textAlign: "center" }}>{t(status)}</Box>}
          <Form onSubmit={handleSubmit} sx={{ mt: 4, fontSize: 1 }}>
            <Form.Item>
              <Input
                name="username"
                placeholder={t("Username")}
                autoCapitalize="none"
                prefix={{
                  component: (
                    <Flex
                      sx={{
                        height: "18px",
                        width: "18px",
                      }}
                    >
                      <EmailSVG width="18" color="#fff" />
                    </Flex>
                  ),
                }}
                onInput={(e) => {
                  setFieldValue("username", e.currentTarget.value);
                }}
                sx={{ backgroundColor: "#282750" }}
              />
              <ErrorMessage name="username" sx={{ mt: 1, ml: 2 }} />
            </Form.Item>

            <Form.Item>
              <PasswordInput
                name="password"
                placeholder={t("Password")}
                sx={{
                  backgroundColor: "#282750",
                }}
                onInput={(e) => {
                  setFieldValue("password", e.currentTarget.value);
                }}
              />
              <ErrorMessage name="password" sx={{ mt: 1, ml: 2 }} />
            </Form.Item>

            <Flex sx={{ mt: 2, ml: 2, justifyContent: "space-between", mb: 4, width: "100%" }}>
              <Button
                as="a"
                variant="underlined"
                sx={{ fontSize: "0.8rem" }}
                onClick={goToPage("/forgot-password")}
                disabled={isSubmitting}
              >
                {t("Forgot password?")}
              </Button>
            </Flex>
            <Flex
              sx={{
                width: "100%",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid columns={[2, "80% 20%"]} sx={{ alignItems: "center", width: "100%", mb: 3 }}>
                <Flex
                  sx={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    variant="primary"
                    sx={{
                      width: "80%",
                    }}
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                  >
                    {t("Sign in")}
                  </Button>
                </Flex>
                {isSubmitting && <IconLoading />}
              </Grid>
              {/* <Text sx={{ variant: "subheading", fontSize: [1], mb: 1 }}>{t("Not signed up yet?")}</Text>
              <Button variant="secondary" onClick={goToSignup} disabled={isSubmitting}>
                {t("Create an account")}
              </Button> */}
            </Flex>
          </Form>
        </>
      )}
    </Formik>
  </Box>
);
