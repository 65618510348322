import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useProjectIdsQuery } from "@/app/types/generated/graphql";

export const PROJECT_ID_CACHE = "PROJECT_ID_CACHE";

export const getAllCachedProjectIdIncludingString = (text?: string) => {
  const allCached: string[] = JSON.parse(sessionStorage.getItem(PROJECT_ID_CACHE) ?? "[]");
  return allCached.filter((id) => id?.toLowerCase().includes(text?.toLowerCase() ?? ""));
};

export const addToStorageProjectIDCache = (projectId = "") => {
  if (projectId) {
    const currentCache = getAllCachedProjectIdIncludingString();
    if (!currentCache.some((val) => val === projectId))
      sessionStorage.setItem(PROJECT_ID_CACHE, JSON.stringify([...currentCache, projectId]));
  }
};

export const clearProjectIdCache = () => {
  sessionStorage.removeItem(PROJECT_ID_CACHE);
};

const createOption = (allData: string[], addNew = false) =>
  allData.map((data) => ({
    label: data,
    value: data,
    key: addNew ? PROJECT_ID_CACHE : "",
  }));

export const useProjectIdsSearch = ({
  pageSize = 10,
  addNewFeature = false,
}: {
  pageSize?: number;
  addNewFeature?: boolean;
}) => {
  const [scrollCursor, setScrollCursor] = useState(1);
  const [searchText, setSearchText] = useState("");
  const { data, loading } = useProjectIdsQuery({
    skip: !searchText,
    variables: {
      where: {
        projectId: searchText,
      },
    },
  });

  const cachedData = useMemo(() => {
    const cachedQuery = createOption(
      data?.projectIds?.slice(0, scrollCursor * pageSize)?.map((data) => data.projectId) ?? [],
    );
    const cacheFromStorage =
      addNewFeature && searchText ? createOption(getAllCachedProjectIdIncludingString(searchText), true) : [];
    const combinedCache = [...cacheFromStorage, ...cachedQuery];
    if (addNewFeature && !combinedCache.length && searchText && !loading) {
      return [
        {
          label: "+ Add New",
          value: searchText,
          key: PROJECT_ID_CACHE,
        },
      ];
    }
    return combinedCache;
  }, [scrollCursor, data?.projectIds, searchText, loading, addNewFeature, pageSize]);

  const getMoreData = useCallback(() => {
    setScrollCursor((cursor) => cursor + 1);
  }, []);

  const onProjectIdSearch = useCallback((text = "") => {
    setSearchText(text);
    return true;
  }, []);

  const onCustomSearch = useMemo(() => debounce(onProjectIdSearch, 500), [onProjectIdSearch]);

  useEffect(() => {
    return () => {
      onCustomSearch.cancel();
    };
  }, [onCustomSearch]);

  return {
    cachedData,
    getMoreData,
    onCustomSearch,
    loading,
    baseData: data?.projectIds,
  };
};
