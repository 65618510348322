import { Account, ApprovalStatus, Document, DocumentStatus, PaymentMethodLinkStatus } from "../types/generated/graphql";

export enum SignupStepperStages {
  MarketSelection = "Market_Selection",
  ApplicantDetails = "Applicant_Details",
  ApplicantControllerDetails = "Applicant_Controller_Details",
  ApplicantBeneficialOwners = "Applicant_Beneficial_Owners",
  RequiredDocumentation = "Required_Documentation",
  PaymentServiceMethodSelection = "Payment_Service_Method_Selection",
  LinkBankAccount = "Link_Bank_Account",
  LinkRegistryAccount = "Link_Registry_Account",
  FinalVerification = "Final_Verification",
}

/**
 * Checks whether all documents are in uploaded state
 * @param documents
 * @returns
 */
export const areDocumentsUploaded = (documents?: Document[] | null) => {
  return documents?.every((document) => document.status === DocumentStatus.Uploaded);
};

/**
 * Checks if some documents or payment methods are missing in account
 * @param account
 * @returns
 */
export const hasMissingDetails = (account: Account) =>
  !account?.linkedPaymentMethods?.length ||
  !account?.linkedPaymentMethods?.some((paymentMethod) => paymentMethod.status === PaymentMethodLinkStatus.Active) ||
  !areDocumentsUploaded(account?.company?.documents as Document[]) ||
  !account?.company?.contacts?.every((company) => areDocumentsUploaded(company?.documents as Document[]));

/**
 * Check approved status of account and its nested entities => company and its contacts
 * @param account
 * @returns
 */
export const checkNestedApprovedStatus = (account?: Account | null) => {
  return !!(
    account &&
    (account?.company?.approvalState?.status === ApprovalStatus.Approved ||
      account?.company?.approvalState?.status === ApprovalStatus.NotRequested) &&
    account?.company?.contacts?.every(
      (contact) =>
        contact.approvalState?.status === ApprovalStatus.Approved ||
        contact.approvalState?.status === ApprovalStatus.NotRequested,
    )
  );
};
