import {
  Box,
  Button,
  EmailSVG,
  Flex,
  Grid,
  Heading,
  IconArrow,
  IconLoading,
  IconLoadingOutlined,
  Link,
  Text,
} from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { object, string } from "yup";

import { ErrorMessage, Form } from "@/app/components/form";
import { Input } from "@/app/components/input";
import { PRIVACY_POLICY_URL } from "@/app/lib/env-helpers";

import { SignupViewProps } from "./signup.types";
import { checkIsValidEmail, defaultSignupValues } from "./use-signup";

export const SignupView: React.FC<SignupViewProps> = ({
  sent,
  setSent,
  sendActivationEmailCallBack,
  goToLogin,
  formRef,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "100%" }}>
      <Heading
        as="h4"
        sx={{
          textAlign: "center",
          fontSize: [3, 4],
          mb: 2,
          height: 4,
          display: "flex",
          alignItems: "center",
          justifyContent: sent ? "" : "center",
        }}
      >
        {!!sent && (
          <Flex
            as="button"
            sx={{
              bg: "transparent",
              border: "none",
              cursor: "pointer",
              width: "50px",
            }}
            onClick={() => {
              formRef?.current?.resetForm({
                values: defaultSignupValues,
              });
              formRef?.current?.validateForm(defaultSignupValues);
              setSent(false);
            }}
          >
            <IconArrow size={5} color="text" />
          </Flex>
        )}
        {t("Let's get started!")}
      </Heading>

      <Formik
        initialValues={defaultSignupValues}
        innerRef={formRef}
        validationSchema={object().shape({
          email: string()
            .required(t("Email is required"))
            .test("validEmail", "Please enter a valid email address", function (email) {
              return checkIsValidEmail(email || "");
            }),
        })}
        onSubmit={async (value) => {
          await sendActivationEmailCallBack(value);
        }}
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, values, touched, setFieldTouched, isValid, submitForm, setFieldValue }) => (
          <>
            {sent ? (
              <Flex
                sx={{
                  mt: 4,
                  flexDirection: "column",
                  fontFamily: "MintGroteskV08",
                }}
              >
                <Text sx={{ fontSize: 2, mb: 3 }}>
                  {t(`We've sent an email to {{email}}.`, { email: values.email })}
                </Text>
                <Text sx={{ fontSize: 2, mb: 3 }}>
                  {t("Please click the verification link in the email to continue.")}
                </Text>
                <Box sx={{ fontSize: 1 }}>
                  <Text>{t("Didn't receive an email? Check your spam folder, or ")}</Text>
                  <Button
                    as="button"
                    onClick={submitForm}
                    sx={{
                      bg: "transparent",
                      color: "text",
                      textDecoration: "underline",
                      cursor: "pointer",
                      p: 0,
                      "&:enabled:hover, &:enabled:focus": {
                        bg: "transparent",
                      },
                    }}
                  >
                    {t("Resend Email")}
                    {isSubmitting && <IconLoadingOutlined />}
                  </Button>
                </Box>
              </Flex>
            ) : (
              <>
                <Form onSubmit={handleSubmit} sx={{ mt: 4, fontSize: 1 }}>
                  <Form.Item>
                    <Input
                      name="email"
                      placeholder={t("Please provide your email address")}
                      autoCapitalize="none"
                      prefix={{
                        component: (
                          <Flex
                            sx={{
                              height: "18px",
                              width: "18px",
                            }}
                          >
                            <EmailSVG width="18" color="#fff" />
                          </Flex>
                        ),
                      }}
                      onInput={async (e) => {
                        await setFieldValue("email", e.currentTarget.value);
                        !touched?.email && setFieldTouched("email", true, true);
                      }}
                      sx={{ backgroundColor: "#282750" }}
                    />
                    <ErrorMessage name="email" sx={{ mt: 1, ml: 2 }} />
                  </Form.Item>
                </Form>

                <Flex
                  sx={{
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid columns={[2, "80% 20%"]} sx={{ alignItems: "center", width: "100%", mb: 3 }}>
                    <Flex
                      sx={{
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="primary"
                        sx={{
                          width: "80%",
                        }}
                        onClick={submitForm}
                        disabled={isSubmitting || !isValid}
                      >
                        {t("Sign up")}
                      </Button>
                    </Flex>
                    {isSubmitting && <IconLoading />}
                  </Grid>
                  <Box sx={{ pt: 2, mb: 3, color: "textDark", fontSize: "0.7rem" }}>
                    <Text>
                      {t(
                        "By submitting your email address you agree to the collection of your personal information in accordance with the Market Operator's ",
                      )}
                    </Text>
                    <Link
                      sx={{ color: "white", "&:visited": { color: "white" } }}
                      target="_blank"
                      href={PRIVACY_POLICY_URL}
                    >
                      {t("Privacy Policy")}
                    </Link>
                  </Box>
                  <Text sx={{ variant: "subheading", fontSize: [1], mb: 1 }}>{t("Already a participant?")}</Text>
                  <Button variant="secondary" onClick={goToLogin}>
                    {t("Log in")}
                  </Button>
                </Flex>
              </>
            )}
          </>
        )}
      </Formik>
    </Box>
  );
};
