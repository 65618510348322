import { Box, Button, Flex, Heading } from "@powerledger/ui-component-lib";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { FormFieldLabel } from "@/app/components/form/form-field-label";
import { LoadingErrorLayout } from "@/app/components/loading-error-layout";
import { useFewAccountDetailsQuery } from "@/app/types/generated/graphql";

import { UserSettingsForm } from "../mfa-settings";
import { ResetPasswordModal } from "./reset-password-modal";

export const PersonalDetailsTab = () => {
  const { t } = useTranslation();
  const { data: accountData, loading: accountLoading, error } = useFewAccountDetailsQuery();
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const account = useMemo(() => accountData?.account, [accountData]);

  return (
    <LoadingErrorLayout loading={accountLoading} error={error?.message ?? ""}>
      <Heading as="h2">{t("Personal Details")}</Heading>
      <Box sx={{ pt: 5 }}>
        <Flex sx={{ alignItems: "center" }}>
          <Box sx={{ flex: 1, pl: 2 }}>
            <FormFieldLabel small label={t("User Email")}>
              <Box
                sx={{
                  fontSize: 1,
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  borderBottomColor: "textDarkest",
                  py: 2,
                }}
              >
                {account?.email}
              </Box>
            </FormFieldLabel>
          </Box>
        </Flex>
        <Flex sx={{ mt: 4 }}>
          <Box sx={{ flex: 1, pr: 2 }}>
            <FormFieldLabel small label={t("First name")} />
            <Box
              sx={{
                fontSize: 1,
                borderBottomWidth: 1,
                borderBottomStyle: "solid",
                borderBottomColor: "textDarkest",
                py: 2,
              }}
            >
              {account?.firstName}
            </Box>
          </Box>
          <Box sx={{ flex: 1, px: 1 }}>
            <FormFieldLabel small label={t("Last name")}>
              <Box
                sx={{
                  fontSize: 1,
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  borderBottomColor: "textDarkest",
                  py: 2,
                }}
              >
                {account?.lastName}
              </Box>
            </FormFieldLabel>
          </Box>
          <Flex sx={{ flex: 1, pl: 2, alignItems: "center", justifyContent: "flex-end" }}>
            <Button
              variant="text"
              sx={{ color: "primary", fontSize: 0 }}
              onClick={() => setShowResetPasswordModal(true)}
            >
              {t("Reset Password")}
            </Button>
          </Flex>
        </Flex>
      </Box>
      <Box sx={{ pt: 5 }}>
        <UserSettingsForm />
      </Box>
      <ResetPasswordModal visible={showResetPasswordModal} closeModal={() => setShowResetPasswordModal(false)} />
    </LoadingErrorLayout>
  );
};
