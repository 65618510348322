import { cloneElement, Fragment } from "react";
import { components as selectComponents, OptionProps, ValueContainerProps } from "react-select";
import { Checkbox, Flex, Text } from "theme-ui";

import { FormattedOption } from "@/app/lib/format-asset-options";

import { MultiSelectActions } from "./helpers";

export const ValueContainerComponent = <T extends object, K extends boolean>({
  children,
  displayValueText,
  ...props
}: {
  children: any;
  displayValueText?: {
    texts: string[];
    showTexts: boolean;
  };
} & ValueContainerProps<T, K>) => {
  if (!props.hasValue) {
    return <selectComponents.ValueContainer {...props}>{children}</selectComponents.ValueContainer>;
  }

  const CHIPSLIMIT = props.selectProps.chipsLimit || 1;
  const [chips, otherChildren] = children;

  const displayChips = chips.slice(0, CHIPSLIMIT);

  /**
   * textElements is used for displaying custom texts passed instead of actual children,
   * the element is cloned from original React Select children to make it adhere to the styles same as other chips
   */
  const textElements = (displayValueText?.texts || []).map((text) => (
    <Fragment key={text}>
      {cloneElement(displayChips[0], {
        children: text,
        /** Added to disable the cross button for custom text */
        data: {
          key: MultiSelectActions.AllDeselected,
        },
      })}
    </Fragment>
  ));

  const overflowCounter = textElements.length ?? props.getValue().length;
  const OverflowView = () => (
    <>
      {overflowCounter > CHIPSLIMIT && (
        <Text sx={{ fontSize: 0, ml: 1, color: "textDarker" }}>{`+${overflowCounter - CHIPSLIMIT}`}</Text>
      )}
    </>
  );
  return (
    <selectComponents.ValueContainer {...props}>
      <Flex
        sx={{
          alignItems: "center",
        }}
      >
        {displayValueText?.showTexts ? (
          <>
            {textElements.slice(0, CHIPSLIMIT)}
            <OverflowView />
          </>
        ) : (
          <Flex sx={{ alignItems: "center", flexWrap: "wrap" }}>
            {displayChips}
            <OverflowView />
          </Flex>
        )}
        {otherChildren}
      </Flex>
    </selectComponents.ValueContainer>
  );
};

export const OptionComponent = <T extends FormattedOption["0"], K extends boolean>({
  children,
  isSelected,
  ...props
}: {
  isSelected?: boolean;
  children: React.ReactNode;
} & OptionProps<T, K>) => {
  if (!children) return <></>;

  return (
    <selectComponents.Option isSelected={false} {...props}>
      <Flex
        sx={{
          justifyContent: "flex-start",
          fontSize: 1,
          alignItems: "center",
          input: { display: "none", zIndex: "-1" },
        }}
      >
        <Checkbox
          checked={props.data.value === MultiSelectActions.AllDeselected || isSelected}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => props.innerProps.onClick?.(e as any)}
        />
        {children}
      </Flex>
    </selectComponents.Option>
  );
};
