import { CountrySelect, IconChevronDown, IconWarning, LegacySelect, NextSVG } from "@powerledger/ui-component-lib";
import { Field, Formik } from "formik";
import ReactHtmlParser from "react-html-parser";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Grid, Heading, Text } from "theme-ui";
import * as Yup from "yup";

import { CleaveInput } from "@/app/components/cleave-input";
import { AppCleaveTypes } from "@/app/components/cleave-input/get-cleave-options";
import { LocalDatePicker } from "@/app/components/date-picker";
import { Form, FormFieldLabel, FormInput } from "@/app/components/form";
import { Input } from "@/app/components/input";
import { LoadingOverlayIndicator } from "@/app/components/loading-overlay-indicator";
import { LocalMultiSelect } from "@/app/components/local-selects/local-multi-select";
import { FieldSelectors } from "@/app/hooks/use-formik-input-error";
import { RegistryType } from "@/app/lib/format-asset-options";
import { AppDateFormats } from "@/app/lib/format-date";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";
import { isValidWebLink } from "@/app/lib/validate-web-link";
import {
  CCDepositFormViewProps,
  ImportCarbonCreditInputFields,
} from "@/app/pages/cc-deposit-form/cc-deposit-form.types";
import { getMaxVintageFromDate, getMinVintageToDate } from "@/app/pages/cc-deposit-form/helpers";
import { allowCorsiaLabelledPlusOneMore } from "@/app/pages/cc-deposit-form/use-cc-deposit-form";

export const CCDepositFormView: React.FC<CCDepositFormViewProps> = ({
  ccOptions,
  loading,
  membersLoading,
  formikProps,
  goBack,
  isEdit,
  handleToggleDisplayRemarks,
  displayRemarks,
  handleSubmit,
  clientCodeOptions,
  remarks,
  error,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return <LoadingOverlayIndicator />;
  }

  if (error) {
    return (
      <Flex
        sx={{
          mt: 4,
          mb: 2,
          bg: "secondaryDarker",
          borderRadius: "8px",
          p: 3,
          alignItems: "center",
          fontSize: 2,
          color: "negative",
        }}
      >
        <IconWarning color={"negative"} size={6} />
        {t(error)}
        <Button
          sx={{
            alignItems: "center",
            bg: "transparent",
            ":enabled:hover, :enabled:focus": {
              bg: "transparent",
            },
            px: 0,
            py: 0,
            ml: 3,
            borderRadius: 0,
            color: "text",
            ":enabled:hover": {
              borderBottomWidth: "1px ",
              borderBottomStyle: "solid",
              borderBottomColor: "text",
            },
          }}
          aria-label={t("go back")}
          onClick={goBack}
        >
          {t("Go Back")}
        </Button>
      </Flex>
    );
  }
  return (
    <>
      <Flex
        sx={{
          mb: 3,
          alignItems: "center",
          gap: 3,
        }}
      >
        <Button
          sx={{
            alignItems: "center",
            transform: "rotate(180deg)",
            bg: "transparent",
            ":enabled:hover, :enabled:focus": {
              bg: "transparent",
            },
            px: 0,
            svg: {
              path: {
                stroke: "text",
              },
            },
          }}
          aria-label={t("go back")}
          onClick={goBack}
        >
          <NextSVG width={20} color="text" />
        </Button>
        <Heading sx={{ variant: "texts.tracexDefaultHeading" }}>{t("Carbon Credits Deposit Form")}</Heading>
      </Flex>
      {isEdit && (
        <Flex
          sx={{
            mt: 2,
            mb: 2,
            bg: "secondaryDarker",
            borderRadius: "8px",
            p: 3,
            fontSize: 2,
            flexDirection: "column",
            justifyContent: "space-between",
            transition: "all 0.3s ease-in-out",
            overflowY: "hidden",
          }}
        >
          <Button
            sx={{
              bg: "transparent",
              p: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: 3,
              ":enabled:hover, :enabled:focus": {
                bg: "transparent",
              },
              color: "text",
              fontWeight: 500,
            }}
            onClick={handleToggleDisplayRemarks}
          >
            {t("Failure reason for member to fix for resubmission")}
            <IconChevronDown
              color="text"
              size={6}
              sx={{
                transition: "all 0.3s ease-in-out",

                transform: displayRemarks ? "rotate(180deg)" : "",
              }}
            />
          </Button>
          <Box
            sx={{
              fontSize: 1,
              maxHeight: displayRemarks ? "10000px" : "0px",
              mt: displayRemarks ? 4 : 0,
              overflow: "hidden",
              transition: "all 0.1s ease-in-out",
            }}
          >
            <Text>{t("Reason for Failure")}</Text>
            {remarks ? ReactHtmlParser(remarks) : "-"}
          </Box>
        </Flex>
      )}
      <Flex
        sx={{
          mt: 4,
          mb: 2,
          bg: "secondaryDarker",
          borderRadius: "8px",
          p: 3,
        }}
      >
        <Formik
          initialValues={formikProps.initialValues as ImportCarbonCreditInputFields}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          enableReinitialize
          validateOnMount
          validationSchema={Yup.object().shape(
            {
              registryName: Yup.string().required("Required"),
              projectId: Yup.string().required("Required"),
              projectName: Yup.string().required("Required"),
              projectTypes: Yup.array().when("registryName", {
                is: (registryName: string) => registryName === RegistryType.VERRA,
                then: Yup.array().of(Yup.string()).nullable(),
                otherwise: Yup.array().of(Yup.string()).min(1),
              }),
              volume: Yup.number().required("Required").min(1),
              vintage: Yup.number().required("Required"),
              vintageFrom: Yup.string().when("vintageTo", {
                is: (vintageTo: string) => !!vintageTo,
                then: Yup.string().required("Required"),
                otherwise: Yup.string().nullable(),
              }),
              vintageTo: Yup.string().when("vintageFrom", {
                is: (vintageFrom: string) => !!vintageFrom,
                then: Yup.string().required("Required"),
                otherwise: Yup.string().nullable(),
              }),
              country: Yup.string().required("Required"),
              sectoralScopes: Yup.array().when("registryName", {
                is: (registryName: string) => registryName === RegistryType.GOLD_STANDARD,
                then: Yup.array().of(Yup.string()).nullable(),
                otherwise: Yup.array().of(Yup.string()).min(1),
              }),
              coBenefits: Yup.array().of(Yup.string()).nullable(),
              sdgGoals: Yup.array().of(Yup.string()).nullable(),
              projectLink: Yup.string()
                .required(t("Required"))
                .test("validLink", "Enter valid link", function (link) {
                  return isValidWebLink(link || "");
                }),
              registryAccountName: Yup.string().required("Required"),
              registryAccountNumber: Yup.string().required("Required"),
              serialNumber: Yup.string().required("Required"),
              clientCode: Yup.string().required("Required"),
            },
            [["vintageFrom", "vintageTo"]],
          )}
          {...formikProps}
        >
          {({ handleSubmit, values, errors, touched, isValid, setFieldTouched, dirty, setFieldValue }) => (
            <Form
              onSubmit={handleSubmit}
              sx={{
                mt: 2,
                fontSize: 1,
              }}
            >
              <Grid gap={3} columns={3} sx={{ alignItems: "end", mb: 3 }}>
                <Form.Item key="import-cc-registry-name">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="registryName"
                    label={t("Registry *")}
                    sx={{
                      ...(errors.registryName && touched.registryName ? FieldSelectors["select"] : {}),
                    }}
                  >
                    <LegacySelect
                      onBlur={() => setFieldTouched("registryName", true)}
                      translation={getSelectTranslation(t)}
                      value={ccOptions?.registry.find((reg) => reg.value === values.registryName)}
                      options={ccOptions?.registry || []}
                      onChange={async (option) => {
                        await setFieldValue("registryName", option?.value);
                        await setFieldValue(
                          option?.value === RegistryType.VERRA ? "projectTypes" : "sectoralScopes",
                          null,
                        );
                      }}
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-project-id">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="projectId"
                    label={t("Project ID *")}
                    info={t("Project ID must be at most 10 characters long")}
                  >
                    <FormInput debounce hasErrorIndicator={false} name="projectId" maxLength={10} />
                  </FormFieldLabel>
                </Form.Item>
              </Grid>
              <Grid gap={3} columns={[2, null, 3]} sx={{ alignItems: "end", mb: 3 }}>
                <Form.Item key="import-cc-project-name">
                  <FormFieldLabel hasErrorMessage={false} small name="projectName" label={t("Project Name *")}>
                    <FormInput debounce hasErrorIndicator={false} name="projectName" />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-project-type">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="projectTypes"
                    label={t(`Project Type ${values.registryName === RegistryType.GOLD_STANDARD ? "*" : ""}`)}
                    sx={{
                      ...(errors.projectTypes && touched.projectTypes ? FieldSelectors["select"] : {}),
                    }}
                  >
                    <LocalMultiSelect
                      isMulti
                      chipsLimit={2}
                      disabled={values.registryName === RegistryType.VERRA}
                      onBlur={() => setFieldTouched("projectTypes", true)}
                      value={ccOptions?.projectTypes.filter((scope) => values.projectTypes?.includes(scope.value))}
                      options={ccOptions?.projectTypes || []}
                      closeMenuOnSelect={false}
                      translation={getSelectTranslation(t)}
                      onChange={(option) =>
                        setFieldValue(
                          "projectTypes",
                          option.map((opt) => opt.value),
                        )
                      }
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-volume">
                  <FormFieldLabel hasErrorMessage={false} small name="volume" label={t("Volume *")}>
                    <Field
                      debounce
                      hasErrorIndicator={false}
                      name="volume"
                      min={1}
                      type={AppCleaveTypes.Quantity}
                      component={CleaveInput}
                    />
                  </FormFieldLabel>
                </Form.Item>
              </Grid>
              <Grid gap={3} columns={[1, 2, 3]} sx={{ alignItems: "end", mb: 3 }}>
                <Form.Item key="import-cc-vintage-year">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name={"vintage"}
                    label={t("Vintage Year *")}
                    sx={{
                      ...(errors.vintage && touched.vintage ? FieldSelectors["datepicker"] : {}),
                    }}
                  >
                    <LocalDatePicker
                      value={values.vintage?.toString()}
                      onChange={(date) => {
                        setFieldValue("vintage", date);
                      }}
                      dateFormat={AppDateFormats.YearFormat}
                      showYearPicker
                      placeholder={t("Select a Year")}
                      maxDate={new Date()}
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-vintage-from">
                  <FormFieldLabel hasErrorMessage={false} small name="vintageFrom" label={t(`Vintage From`)}>
                    <LocalDatePicker
                      isClearable
                      value={values.vintageFrom || ""}
                      onChange={(date) => {
                        setFieldValue("vintageFrom", date);
                        if (!date) {
                          setFieldValue("vintageTo", null);
                        }
                      }}
                      placeholder={t("Select a Date")}
                      disabled={!values.vintage}
                      maxDate={values.vintage ? getMaxVintageFromDate(values.vintage) : undefined}
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-vintage-to">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name={"vintageTo"}
                    label={t(`Vintage To ${values.vintageFrom ? "*" : ""}`)}
                  >
                    <LocalDatePicker
                      isClearable
                      value={values.vintageTo ?? ""}
                      onChange={(date) => {
                        setFieldValue("vintageTo", date);
                      }}
                      placeholder={t("Select a Date")}
                      disabled={!values.vintageFrom}
                      minDate={
                        values.vintage && values.vintageFrom
                          ? getMinVintageToDate(values.vintage, values.vintageFrom)
                          : undefined
                      }
                    />
                  </FormFieldLabel>
                </Form.Item>
              </Grid>
              <Grid gap={3} columns={3} sx={{ alignItems: "end", mb: 3 }}>
                <Form.Item key="import-cc-country">
                  <FormFieldLabel hasErrorMessage={false} small name="country" label={t("Country *")}>
                    <CountrySelect
                      translation={getSelectTranslation(t)}
                      inputValue={values.country}
                      onChange={(option) => {
                        setFieldValue("country", option.value);
                      }}
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-region">
                  <FormFieldLabel hasErrorMessage={false} small name="region" label={t("Region")}>
                    <Input
                      disabled
                      value={t(
                        ccOptions?.country.find((region) => region.options?.find((reg) => reg.value === values.country))
                          ?.label || "",
                      )}
                    />
                  </FormFieldLabel>
                </Form.Item>
              </Grid>
              <Grid gap={3} columns={3} sx={{ alignItems: "end", mb: 3 }}>
                <Form.Item key="import-cc-sectoral-scope">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="sectoralScopes"
                    label={t(`Sectoral Scope ${values.registryName === RegistryType.VERRA ? "*" : ""}`)}
                    sx={{
                      ...(errors.sectoralScopes && touched.sectoralScopes ? FieldSelectors["select"] : {}),
                    }}
                  >
                    <LocalMultiSelect
                      isMulti
                      chipsLimit={1}
                      disabled={values.registryName === RegistryType.GOLD_STANDARD}
                      onBlur={() => setFieldTouched("sectoralScopes", true)}
                      value={ccOptions?.sectoralScopes.filter((scope) => values.sectoralScopes?.includes(scope.value))}
                      options={ccOptions?.sectoralScopes || []}
                      closeMenuOnSelect={false}
                      translation={getSelectTranslation(t)}
                      onChange={(option) => {
                        setFieldValue(
                          "sectoralScopes",
                          option.map((opt) => opt.value),
                        );
                      }}
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-co-benefits">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="coBenefits"
                    label={t("Additional Co-Benefits")}
                    info={t("You can select CORSIA Labelled and one more Co-Benefits")}
                  >
                    <LocalMultiSelect
                      isMulti
                      chipsLimit={2}
                      disableOptions={(type) => allowCorsiaLabelledPlusOneMore(type, values.coBenefits)}
                      onBlur={() => setFieldTouched("coBenefits", true)}
                      value={ccOptions?.additionalCoBenefits.filter((scope) =>
                        values.coBenefits?.includes(scope.value),
                      )}
                      options={ccOptions?.additionalCoBenefits || []}
                      // We only want to allow max 2 values, so if the value already has 1 value, close the menu on selecting another
                      closeMenuOnSelect={values?.coBenefits?.length === 1}
                      translation={getSelectTranslation(t)}
                      onChange={(option) => {
                        setFieldValue(
                          "coBenefits",
                          option.map((opt) => opt.value),
                        );
                      }}
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-sdg-goal">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="sdgGoals"
                    label={t("Sustainable Development Goals (SDGs)")}
                    sx={{
                      ...(errors.sdgGoals && touched.sdgGoals ? FieldSelectors["select"] : {}),
                    }}
                  >
                    <LocalMultiSelect
                      isMulti
                      chipsLimit={1}
                      onBlur={() => setFieldTouched("sdgGoals", true)}
                      value={ccOptions?.sdgGoals.filter((scope) => values.sdgGoals?.includes(scope.value))}
                      options={ccOptions?.sdgGoals || []}
                      closeMenuOnSelect={false}
                      translation={getSelectTranslation(t)}
                      onChange={(option) => {
                        setFieldValue(
                          "sdgGoals",
                          option.map((opt) => opt.value),
                        );
                      }}
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-project-info-link">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="projectLink"
                    label={t("Project Information Link (Registry Web Page) *")}
                  >
                    <FormInput
                      debounce
                      hasErrorIndicator={false}
                      name="projectLink"
                      sx={{
                        "::placeholder": {
                          color: "textDarker",
                        },
                      }}
                      placeholder="https://xyz.org"
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-seller-reg-acc-name">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="registryAccountName"
                    label={t("Seller's Registry Account Name *")}
                  >
                    <FormInput debounce hasErrorIndicator={false} name="registryAccountName" />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-seller-reg-acc-no">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="registryAccountNumber"
                    label={t("Seller's Registry Account No. *")}
                  >
                    <FormInput debounce hasErrorIndicator={false} name="registryAccountNumber" />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-client-id">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="clientCode"
                    label={t("Client Code *")}
                    sx={{
                      ...(errors.clientCode && touched.clientCode ? FieldSelectors["select"] : {}),
                    }}
                  >
                    <LegacySelect
                      onBlur={() => setFieldTouched("clientCode", true)}
                      translation={getSelectTranslation(t)}
                      options={clientCodeOptions}
                      isLoading={membersLoading}
                      value={clientCodeOptions?.find((client) => client.value === values.clientCode)}
                      onChange={(option) => setFieldValue("clientCode", option?.value)}
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="import-cc-serial-no">
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name="serialNumber"
                    label={t("Carbon Credits Serial Number Range *")}
                  >
                    <FormInput debounce hasErrorIndicator={false} name="serialNumber" />
                  </FormFieldLabel>
                </Form.Item>
              </Grid>
              <Flex
                sx={{
                  justifyContent: "flex-end",
                }}
              >
                <Button type="submit" variant="pill" disabled={!isValid || !dirty}>
                  {t("Submit")}
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
    </>
  );
};
