import { Option } from "@powerledger/ui-component-lib";

import { CcOrderAttributesOptionsQuery } from "../types/generated/graphql";

type Value = { displayName?: string | null; code: string };

type GroupByKeyArg = {
  values: Array<Value & { key: string }>;
};

export type FormattedOption = Array<
  Option & {
    options?: Option[];
  }
>;

/**
 * Extract number from the string
 * @param text
 * @returns
 */
const extractNumber = (text: string) => {
  const numberExtracted = text.match(/(\d+)/)?.[0] || "0";
  return +numberExtracted;
};

/**
 *
 * @param values
 * @param sort
 * @param number = if sorting needs to be on the basis of number Eg: SDG 1 , SDG 2, etc
 * @returns
 */
const sortOptions = (values: FormattedOption, sort: 1 | -1, number = false) => {
  return values.sort((valA, valB) => {
    const labelA = number ? extractNumber(valA.label) : valA.label.toLowerCase()?.trim();
    const labelB = number ? extractNumber(valB.label) : valB.label.toLowerCase()?.trim();
    return labelA > labelB ? sort : -sort;
  });
};

const groupByKey = (values: GroupByKeyArg["values"], sort: 1 | -1, number = false): FormattedOption => {
  const keyObj: Record<string, Option[]> = {};
  for (const val of values) {
    keyObj[val.key] = [...(keyObj[val.key] || []), { label: val.displayName || "", value: val.code }];
  }
  const groupedOptions = [];
  for (const val in keyObj) {
    groupedOptions.push({
      label: val,
      value: null,
      options: sortOptions(keyObj[val], sort, number),
    });
  }
  return groupedOptions;
};

const extractAttributeOptions = (
  attributeName: string,
  attributes?: { name: string; values?: (Value & { key?: string | null })[] | null }[],
  /** 1 for ascending, -1 for descending */
  sort: 1 | -1 = 1,
  number = false,
): FormattedOption => {
  const attribute = attributes?.find((attr) => attr.name === attributeName);
  let options: Array<
    Option & {
      options?: Option[];
    }
  >;
  if (attribute?.values?.[0]?.key) {
    options = groupByKey((attribute?.values as GroupByKeyArg["values"]) || [], sort, number);
  } else {
    options =
      attribute?.values?.map((val) => ({
        label: val.displayName || "",
        value: val.code,
        key: val.code,
      })) || [];
  }

  return sortOptions(options, sort, number);
};

export type AssetAttributeOptionKeys =
  | "vintageOptions"
  | "eligibilityOptions"
  | "vintageHalfOptions"
  | "locationOptions"
  | "fuelSourceOptions"
  | "projectOptions"
  | "certificationOptions"
  | "vintage"
  | "registry"
  | "projectTypes"
  | "sectoralScopes"
  | "sdgGoals"
  | "country"
  | "additionalCoBenefits"
  | "clientCodes";

export type AssetAttributeOptions = Record<AssetAttributeOptionKeys, FormattedOption>;

export const formatAssetOptions = (data: CcOrderAttributesOptionsQuery["assets"]): AssetAttributeOptions => {
  const attributes = data?.[0]?.attributes;

  if (!attributes) {
    throw new Error("No attributes found in assets options");
  }

  return {
    vintage: extractAttributeOptions("vintage", attributes, -1),
    registry: extractAttributeOptions("registry", attributes, -1),
    projectTypes: extractAttributeOptions("projectType", attributes),
    sectoralScopes: extractAttributeOptions("sectoralScope", attributes),
    sdgGoals: extractAttributeOptions("sdgGoals", attributes, 1, true),
    additionalCoBenefits: extractAttributeOptions("additionalCoBenefits", attributes),
    country: extractAttributeOptions("country", attributes),
    vintageOptions: extractAttributeOptions("vintage", attributes),
    eligibilityOptions: extractAttributeOptions("eligibility", attributes),
    locationOptions: extractAttributeOptions("location", attributes),
    fuelSourceOptions: extractAttributeOptions("fuelSource", attributes),
    projectOptions: extractAttributeOptions("project", attributes),
    certificationOptions: extractAttributeOptions("certification", attributes),
    vintageHalfOptions: extractAttributeOptions("vintageHalf", attributes),
    clientCodes: [],
  };
};

export enum RegistryType {
  VERRA = "Verra",
  GOLD_STANDARD = "Gold Standard",
}
