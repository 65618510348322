import { validate } from "email-validator";
import { FormikProps } from "formik";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { fallBackErrorMessage, notifications } from "@/app/container/notifications-handler";
import { getSupportEmail } from "@/app/lib/get-support-email";
import { useSendActivationEmailMutation } from "@/app/types/generated/graphql";

import { HomePages } from "../home.types";
import { SignupFormValues, UseSignupHook } from "./signup.types";

export const checkIsValidEmail = (email: string) => {
  return validate(email);
};

export const defaultSignupValues: SignupFormValues = {
  email: "",
};

const supportEmail = getSupportEmail();
export const useSignup: UseSignupHook = ({ page }) => {
  const { t } = useTranslation();
  const [sent, setSent] = useState(false);
  const [sendActivationEmail, { error }] = useSendActivationEmailMutation({
    notifyOnNetworkStatusChange: true,
  });
  const formRef = useRef<FormikProps<SignupFormValues> | null>(null);

  useEffect(() => {
    if (page !== HomePages.signup) {
      formRef.current?.resetForm();
    }
  }, [page]);

  const sendActivationEmailCallBack = useCallback(
    async ({ email }: { email: string }) => {
      try {
        await sendActivationEmail({
          variables: {
            email,
          },
        });
        setSent(true);
        sent &&
          notifications.success({
            description: t(
              "Email has been resent. Please check your spam folder if it is not present in your primary inbox",
            ),
          });
      } catch (error: unknown) {
        if (error instanceof Error) {
          const message = error?.message || t(fallBackErrorMessage, { supportEmail });

          notifications.error({
            description: t(message),
          });
        }
      }
    },
    [sendActivationEmail, sent, t],
  );
  return {
    sendActivationEmailCallBack,
    error,
    sent,
    setSent,
    formRef,
  };
};
