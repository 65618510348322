import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notifications-handler";
import { useCurrencySymbol } from "@/app/hooks/use-currency-symbol";
import { useUserIcxAccountBalanceQuery } from "@/app/types/generated/graphql";

import { UseAccountBalance } from "./account-balance.types";

export const useAccountBalance: UseAccountBalance = () => {
  const { t } = useTranslation();
  const { currencySymbol, loading: currencySymbolLoading } = useCurrencySymbol();
  const {
    data,
    loading: userAccountBalanceLoading,
    refetch: refetchQuery,
  } = useUserIcxAccountBalanceQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    onError: () => {
      notifications.error({
        description: t("Failed to fetch available account balance"),
      });
    },
  });

  const refetch = async () => {
    await refetchQuery();
  };

  return {
    availableAccountBalance: data?.userIcxAccountBalance.availableBalance,
    currentAccountBalance: data?.userIcxAccountBalance.bankBalance,
    currencySymbol,
    loading: currencySymbolLoading || userAccountBalanceLoading,
    refetch,
  };
};
