import { IconProps, IconWrapper } from "@powerledger/ui-component-lib";

import { ImportBoxSVG } from "../vectors";

export const IconImportBox: React.FC<IconProps> = (props) => (
  <IconWrapper
    icon={ImportBoxSVG}
    sx={{
      "svg path": {
        fill: "transparent !important",
      },
      svg: {
        width: "35px",
        height: "35px",
        g: {
          transform: "translate(-5px, 9px)",
        },
      },
    }}
    {...props}
  />
);
