import { i18n as I18n } from "i18next";
import { find } from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { WrappedDynamicFile } from "../../assets/terms";
import { fallbackLng } from "../lib/i18n";
import { useAccountTenantIdQuery, useTenantsQuery } from "../types/generated/graphql";

const findLanguage = (i18n: I18n, ns: string, key: string) =>
  i18n.languages.find((lng) => i18n.getResource(lng, ns, key) !== undefined);

// TODO: Would like to review this at a later date.
export const useTranslatedFile = (ns: string, key: string, lngOverride?: string | null | undefined) => {
  const { t, i18n } = useTranslation();
  const [File, setFile] = useState<any>(false);

  const { data: accountData } = useAccountTenantIdQuery();
  const { data: tenantsData } = useTenantsQuery();
  const tenant = find(tenantsData?.tenants, { id: accountData?.account?.tenantId });
  const tenantName = tenant?.name || undefined;

  useEffect(() => {
    const fileKey = `${tenantName}.${key}.${lngOverride}`;
    const lngInUse = findLanguage(i18n, ns, fileKey) || lngOverride || i18n.language || fallbackLng;
    i18n.addResource(lngInUse, ns, `${tenantName}.${key}.${lngOverride}`, (() => (
      <WrappedDynamicFile lng={lngInUse} translationKey={key} tenantName={tenantName} />
    )) as any);
    setFile(t(fileKey));
  }, [i18n, key, ns, t, tenantName, lngOverride]);

  return { File, loading: File === false };
};
