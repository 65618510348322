import "../fonts.css";
import "react-toastify/dist/ReactToastify.css";

import { ApolloProvider } from "@apollo/client";
import { PLCLProvider } from "@powerledger/ui-component-lib";
import { ConfigCatProvider, createFlagOverridesFromMap, OverrideBehaviour, PollingMode } from "configcat-react";
import { ReactElement } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { AttributesTooltip, GenericTooltip, MobileRestrictedModal } from "./components";
import container from "./container";
import { tracexTheme } from "./container/theme-override";
import { CONFIGCAT_CACHE_TTL_DEFAULT } from "./hooks";
import { CONFIGCAT_CACHE_TTL, CONFIGCAT_OVERRIDES, CONFIGCAT_SDK_KEY } from "./lib/env-helpers";
import { ModalProvider } from "./modal-provider";
import { AppRouter } from "./navigation/app-router";

const App = (): ReactElement => {
  return (
    <ConfigCatProvider
      sdkKey={CONFIGCAT_SDK_KEY}
      pollingMode={PollingMode.LazyLoad}
      options={{
        flagOverrides: createFlagOverridesFromMap(CONFIGCAT_OVERRIDES, OverrideBehaviour.LocalOverRemote),
        cacheTimeToLiveSeconds: Number(CONFIGCAT_CACHE_TTL) || CONFIGCAT_CACHE_TTL_DEFAULT,
      }}
    >
      <PLCLProvider themeOverwrites={tracexTheme}>
        <ApolloProvider client={container.apolloClient}>
          <ToastContainer />
          <BrowserRouter>
            <ModalProvider>
              <AppRouter oktaAuth={container.oktaAuth} />
            </ModalProvider>
          </BrowserRouter>
          <GenericTooltip />
          <AttributesTooltip />
          <MobileRestrictedModal />
        </ApolloProvider>
      </PLCLProvider>
    </ConfigCatProvider>
  );
};

export default App;
