import { Box, Flex, IconChevronDown, LegacySelect, SelectType } from "@powerledger/ui-component-lib";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GroupProps } from "react-select";

import { useGetLocationTexts } from "@/app/hooks/use-get-locations-text/use-get-location-texts";
import { FormattedOption } from "@/app/lib/format-asset-options";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";

import { OptionComponent, ValueContainerComponent } from "./common-components";
import { MultiSelectActions } from "./helpers";

const GroupComponent = <T extends object, K extends boolean>({
  children,
  state,
  counts,
  ...props
}: {
  children: React.ReactNode;
  state: {
    state?: ExpandState;
    setState: Dispatch<SetStateAction<ExpandState | undefined>>;
  };
  counts: Record<string, number>;
} & GroupProps<T, K>) => {
  return (
    <Box>
      <Flex
        sx={{
          fontSize: 2,
          justifyContent: "space-between",
          px: 2,
          alignItems: "center",
          cursor: "pointer",
          svg: {
            transform: state.state?.[props.data.label || ""] ? "rotate(180deg)" : "",
          },
        }}
        role="button"
        onClick={() => {
          state.setState((prev) => ({
            ...prev,
            [props.data.label || ""]: prev?.[props.data.label || ""] ? !prev[props.data.label || ""] : true,
          }));
        }}
      >
        {props.data.label} {Boolean(counts[props.data.label || ""]) && `(${counts[props.data.label || ""]})`}{" "}
        <IconChevronDown color="text" size={6} />
      </Flex>
      {children}
    </Box>
  );
};

type ExpandState = Record<string, boolean>;
export const LocationSelect = <T extends FormattedOption["0"] = FormattedOption["0"], K extends boolean = true>({
  /**
   * maxStateChipsLimit is different from chipsLimit,
   * maxStateChipsLimit mean how many chips you want to show before you show the countries
   * eg: if maxStateChipsLimit is 3 i.e. if there are more than 3 states, show countries instead
   * Al, BC, IN, ME ===> Canada (2), USA (2)
   * Chips Limit means how many chips you want to show,
   * if chips Limit is 2
   * so could be Canada (2), USA (2) + 1 more or Al, BC, + 2 more
   */
  maxStateChipsLimit = 3,
  ...props
}: { maxStateChipsLimit?: number } & Omit<SelectType<T, K>, "translation">) => {
  const { t } = useTranslation();
  const [state, setState] = useState<ExpandState>();

  const { getLocationTextsWithRegion } = useGetLocationTexts();

  const options = useMemo(() => [...(props.options || [])] as FormattedOption, [props.options]);
  const values = useMemo(() => [...((props.value as T[]) || [])] as FormattedOption, [props.value]);

  const locationsSelected = useMemo(
    () =>
      options?.map((o) => {
        const selected = (o.options || []).filter((so) => values?.find((p) => p.value === so.value)) || [];
        return {
          key: o.label,
          count: selected.length,
          allSelected: o.options?.length === selected.length,
        };
      }) || [],
    [options, values],
  );
  const counts = useMemo(() => {
    const count: Record<string, number> = {};
    for (const s of locationsSelected) {
      count[s.key || ""] = s.count;
    }
    return count;
  }, [locationsSelected]);

  const allSelected = useMemo(() => {
    const allSelected: Record<string, boolean> = {};
    for (const s of locationsSelected) {
      allSelected[s.key || ""] = s.allSelected;
    }
    return allSelected;
  }, [locationsSelected]);

  const displayOptions = useMemo(
    () =>
      options?.map((opt) => ({
        ...opt,
        options: state?.[opt.label || ""]
          ? [
              {
                label: t("All"),
                key: opt.label,
                value: allSelected[opt.label] ? MultiSelectActions.AllDeselected : MultiSelectActions.AllSelected,
              },
              ...(opt.options || []),
            ]
          : [{ label: "", value: "" }],
      })) || [],
    [allSelected, options, t, state],
  );

  const displayValueText: {
    texts: string[];
    showTexts: boolean;
  } = useMemo(() => {
    const texts: string[] = [];
    let showTexts = Boolean(props.disabled);
    const displayValues = getLocationTextsWithRegion(values.map((val) => val.label));

    for (const s of locationsSelected) {
      if (values.length > maxStateChipsLimit && !showTexts) {
        showTexts = true;
      }
      if (s.count)
        texts.push(
          t("{{country}} ({{count}})", {
            country: s.key,
            count: s.count,
          }),
        );
    }
    /**
     * TODO
     * **Correct the logic**
     * In Future we might remove disabled prop change in this select,
     * So the displayValues will be helpful when user propagate from Market Filter to Buy Form to indicate which country what states belongs to
     * But the decision will come from PO first then we will change this properly
     */
    const textToShow = showTexts ? texts : displayValues;
    return { texts: textToShow, showTexts };
  }, [locationsSelected, t, getLocationTextsWithRegion, values, maxStateChipsLimit, props.disabled]);

  return (
    <LegacySelect
      translation={getSelectTranslation(t)}
      components={{
        Option: OptionComponent,
        Group: (props) => <GroupComponent {...props} state={{ state, setState }} counts={counts} />,
        ValueContainer: (props) => <ValueContainerComponent {...props} displayValueText={displayValueText} />,
      }}
      {...props}
      options={displayOptions as any}
    />
  );
};
