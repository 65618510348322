import { Grid } from "@powerledger/ui-component-lib";

import { ResourceType } from "../../user-resources.types";
import { ResourceTile } from "./resource-tile";

export const ResourceGrid = ({ data }: { data: ResourceType[] }) => {
  return (
    <Grid gap={3} columns={[2, 2, 3]}>
      {data.map((resource: ResourceType) => (
        <ResourceTile
          key={resource.name}
          url={resource.url}
          name={resource.name}
          fileName={resource.fileName}
          image={resource.image}
        />
      ))}
    </Grid>
  );
};
