import { Modal } from "@powerledger/ui-component-lib";

import { BuyForm } from "./buy-form";
import { BuyOrderLifeCycle, BuyOrderModalProps } from "./buy-order-modal.types";
import { useBuyOrderModal } from "./use-buy-order-modal";

export const BuyOrderModal: React.FC<BuyOrderModalProps> = ({ defaultAsset, visible, closeModal }) => {
  const logic = useBuyOrderModal({ defaultAsset, closeModal });
  const isCurrentBuyStepSuccess = logic.currentStep === BuyOrderLifeCycle.Success;
  return (
    <Modal
      visible={visible}
      hideCard={isCurrentBuyStepSuccess}
      onCancel={closeModal}
      modalMaxWidth={isCurrentBuyStepSuccess ? "600px" : ""}
      sx={{ maxHeight: "inherit", mt: 1 }}
    >
      <BuyForm {...logic} />
    </Modal>
  );
};
